<template>
    <van-popup position="right" @open="handleOpen" @close="handleClose" v-model:show="store.forumDetailsShow"
        :style="{ width: '100%', height: '100%' }">
        <div class="post_details_wrapper" :style="{
            paddingTop: store.areaInsetTopValue + 'px',
        }">
            <div class="my_nav_bar_wrapper">
                <van-nav-bar title="详情" left-text="返回" left-arrow @click-left="store.forumDetailsShow = false" />
            </div>

            <div class="main">
                <div class="post_container">
                    <div class="author_wrapper">
                        <HeadPortrait width="30px" height="30px" :level="Number(store.post_details.recharge_level)"
                            :src="store.post_details.head_img_url" ae fe />
                        <div class="author_name">
                            <span :class="[store.post_details.is_admin && 'admin_name']">{{ store.post_details.user_name
                                }}</span>
                        </div>
                        <div class="author_level">
                            <Level :border="false" level_type="recharge_level"
                                :level_value="Number(store.post_details.recharge_level)" />
                            <Level :border="false" :level_value="Number(store.post_details.level)" />
                        </div>

                    </div>

                    <div class="post_content">
                        <span class='con_topPost' v-if="store.post_details.article_status == 1">置顶</span>
                        <span :class="[store.post_details.article_status == 1 ? 'con_selected_top' : 'con_selected']"
                            v-if="store.post_details.is_admin">官方</span>
                        <p
                            :class="['con_text', store.post_details.article_status == 1 ? (store.post_details.is_admin ? 'ti_top' : 'ti') : (store.post_details.is_admin ? 'ti' : '')]">
                            {{ store.post_details.content }}
                            <span class="post_tag"> {{ handleTag(store.post_details.article_type) }}</span>
                        </p>
                    </div>

                    <div class="post_images">
                        <div class="post_image_item" v-for="(imgItem, imgIndex) in store.post_details.images"
                            @click="handleImagePreview(store.post_details.images, imgIndex)">
                            <van-image width="100%" height="100%" fit="contain" :show-loading="false" :src="imgItem" />
                        </div>
                    </div>

                    <div class="tool_tab">

                        <!-- <div class="like">
                            <div class="like_icon_wrapper">
                                <van-image class="like_icon" width="20px" height="20px" :show-loading="false"
                                    :src="trophy_icon" />
                            </div>
    
                            <div class="count" style="margin-left: 4px">
                                大神999
                            </div>
                        </div> -->

                        <!-- <div class="like">
                            <div class="like_icon_wrapper">
                                <van-image class="like_icon" width="20px" height="20px" :show-loading="false"
                                    :src="smile_icon" />
                            </div>
    
                            <div class="count" style="margin-left: 4px">
                                看戏 999
                            </div>
                        </div> -->

                        <!-- <div class="like">
                            <div class="like_icon_wrapper">
                                <van-image class="like_icon" width="20px" height="20px" :show-loading="false"
                                    :src="trophy_icon_active" />
                            </div>
    
                            <div class="count" style="margin-left: 4px">
                                有点亮 999
                            </div>
                        </div> -->

                        <div class="like"
                            @click="whenTheUserLike(store.post_details.id, store.post_details.liked_by_me)">
                            <div class="like_icon_wrapper">
                                <van-image class="like_icon" width="20px" height="20px" :show-loading="false"
                                    :src="store.post_details.liked_by_me ? like_icon_active : like_icon" />
                            </div>

                            <div class="count" style="margin-left: 4px">
                                {{ store.post_details.likes_count }}
                            </div>
                        </div>

                    </div>

                    <div class="time_item">
                        <div>共{{ store.post_details.total_num || 0 }}条评论</div>
                        <div>{{ store.post_details.create_time }}</div>
                    </div>

                </div>

                <!-- childComment的处理格式 -->
                <div class="post_comment" v-if="store.post_details.total_num > 0">
                    <div class="post_com_item"
                        v-for="(comment, commentIndex) in handleSort(store.post_details.reply_content)">

                        <!-- 直接评论 -->
                        <div class="user_box">
                            <div @click="handleReply(comment)">

                                <div class="user_head">
                                    <HeadPortrait width="20px" height="20px" :level="Number(comment.recharge_level)"
                                        :src="comment.head_img_url" ae fe />
                                    <div class="author_name">
                                        <span :style="`color: ${comment.is_admin ? '#FEE22A' : '#93F6FC'}`">{{
                                            comment.user_name
                                        }}</span>
                                        <span class="admin_tag" v-if="comment.is_admin">管理</span>
                                    </div>
                                    <div class="author_level">
                                        <Level :border="false" level_type="recharge_level"
                                            :level_value="Number(comment.recharge_level)" />
                                        <Level :border="false" :level_value="Number(comment.level)" />
                                    </div>

                                    <!-- <div class="admin_tag" v-if="comment.is_admin">管理员回复</div> -->

                                    <div class="like">
                                        <!-- <div class="like_icon_wrapper">
                                            <van-image class="like_icon" width="20px" height="20px" :show-loading="false"
                                                :src="like_icon" />
                                        </div>
    
                                        <div class="count" style="margin-left: 4px">
                                            999
                                        </div> -->
                                    </div>

                                </div>

                                <div class="item_comment">
                                    <span :class="comment.is_admin ? 'admin_content' : ''">{{ comment.content }}
                                    </span>
                                    <span class="item_comment_time">{{ showTime(comment.reply_time
                                        *
                                        1000) }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- 回复评论 -->
                        <div class="reply_comment" ref="_reply_comment" v-if="comment.reply_content">
                            <div class="user_box_re" v-for="reply in displayedItems(comment.childComment, commentIndex)"
                                @click="handleReply(reply)">
                                <div class="user_head">
                                    <HeadPortrait width="15px" height="15px" :level="Number(reply.recharge_level)"
                                        :src="reply.head_img_url" ae fe />
                                    <div class="author_name">
                                        <span :style="`color: ${reply.is_admin ? '#FEE22A' : '#93F6FC'}`"> {{
                                            reply.user_name }}</span>
                                        <span class="admin_tag" v-if="reply.is_admin">管理</span>
                                    </div>

                                    <div class="reply_user" v-if="reply.reply_user_name">
                                        <span>回复</span>
                                        <span>{{ reply.reply_user_name }}</span>
                                    </div>

                                    <div class="like">
                                        <!-- <div class="like_icon_wrapper">
                                            <van-image class="like_icon" width="20px" height="20px"
                                                :show-loading="false" :src="like_icon" />
                                        </div>

                                        <div class="count" style="margin-left: 4px">
                                            999
                                        </div> -->
                                    </div>
                                </div>

                                <div class="item_comment">
                                    <span :class="reply.is_admin ? 'admin_content' : ''">{{ reply.content }} </span>
                                    <span class="item_comment_time">{{ showTime(reply.reply_time
                                        *
                                        1000) }}</span>
                                </div>
                            </div>

                            <div class="moreComment" v-if="hasMoreComments(comment, commentIndex)"
                                @click="showMore(comment, commentIndex)">
                                展示更多评论</div>
                        </div>

                        <div ref="_reply_comment" v-else></div>
                    </div>
                </div>

                <div class="no_post_comment" v-else>
                    暂无评论
                </div>

            </div>

            <div class="comment_input_box" @click="handleShowInput">
                <div class="text_box">{{ replyItem.content || '评论' }}</div>
            </div>
        </div>

    </van-popup>

    <div class="replyTool">
        <van-popup v-model:show="input_show" position="bottom" :style="{ height: handleBoardHeight }" duration="0"
            @close="handleBlur">
            <div class="comment_main">
                <div class="input">
                    <van-field v-model.trim="replyItem.content" ref="_input" rows="1" :autosize="{ maxHeight: 40 }"
                        type="textarea" :placeholder="replyItem.username ? `回复 ${replyItem.username}` : placeholder"
                        @blur="handleBlur" />

                </div>
                <div class="tool_bar">
                    <div :class="[replyItem.content ? 'sent_btn_active' : 'sent_btn']" @click="handleSend">发送
                    </div>
                </div>
            </div>
        </van-popup>
    </div>

</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store/store';
import { onMounted, ref, nextTick, reactive, watch, computed } from 'vue';
import { useToggle } from '@vant/use';
import { addForumReplies, likePost } from '@/api';
import { showToast, closeToast } from "vant";
import { showTime, getCurrentDevice } from '@/utils/utils';
import { showImagePreview } from "vant";

import HeadPortrait from "@/components/HeadPortrait.vue";
import Level from "@/components/Level.vue";

import like_icon from "@/assets/images/show/like.png";
import like_icon_active from "@/assets/images/show/is_like.png";
import smile_icon from "@/assets/images/show/smile.png";
import smile_icon_active from "@/assets/images/show/is_smile.png";
import tabler_icon from "@/assets/images/show/tabler.png";
import tabler_icon_active from "@/assets/images/show/is_tabler.png";
import trophy_icon from "@/assets/images/show/trophy.png";
import trophy_icon_active from "@/assets/images/show/is_trophy.png";

const store = useStore();

const tagList = [
    {
        article_type: '3',
        name: '#高光时刻',
    },
    {
        article_type: '4',
        name: '#日常分享',
    },
    {
        article_type: '5',
        name: '#经验攻略',
    },
    {
        article_type: '6',
        name: '#建议反馈',
    },
];

const handleTag = (tagType) => {
    const res = tagList.filter(tag => tag.article_type == tagType);
    if (res.length > 0) return res[0].name;
    return "";
};

//自动聚焦
const _input = ref();
const [input_show, input_show_toggle] = useToggle(false);
const handleShowInput = () => {
    input_show_toggle(true);
    nextTick(() => {
        _input.value.focus();
    });
};

const handleBlur = () => {
    if (!replyItem.content) {
        replyItem.reply_id = 0;
        replyItem.username = "";
    }
    setTimeout(() => {
        input_show.value = false;
    }, 100);
};

//回复信息
const replyItem = reactive({
    reply_id: 0,//（被回复人uid） 文章直接评论则为0
    content: "",//内容
    username: "",
});
const placeholder = '文明上网，理性发言，共建和谐文明交谈环境！';
const handleReply = (comment) => {
    handleShowInput();
    replyItem.reply_id = comment.id;
    replyItem.username = comment.user_name;
};

const handleSend = async () => {
    if (!replyItem.content) {
        showToast('评论不能为空');
        nextTick(() => {
            _input.value.focus();
        });
        return;
    }
    try {
        const params = {
            post_id: store.post_details.id,
            user_id: store.userInfo.uid,
            reply_id: replyItem.reply_id,
            content: replyItem.content
        };
        const res = await addForumReplies(params);
        if (res.code == 200) {
            showToast('评论成功,等待审核');
            input_show_toggle(false);
            replyItem.content = "";
            replyItem.reply_id = 0;
            replyItem.username = "";
        }

    } catch (error) {

    }

};

const handleSort = (itemArr) => {
    // const res = itemArr.filter((item) => item.status == 1);
    // console.log('看看数据', res);

    itemArr.sort((a, b) => b.reply_time - a.reply_time);
    return itemArr;
};

// 预览
const handleImagePreview = (images, index) => {
    showImagePreview({
        images,
        startPosition: index,
    });
};

// 用户点赞文章
const whenTheUserLike = async () => {
    if (store.post_details.liked_by_me) return;

    const params = {
        post_id: Number(store.post_details.id),
        uid: parseInt(store.userInfo.uid),
    };
    // 请求
    try {
        const res = await likePost(params);

        if (res.code == 200) {
            showToast('点赞成功');

            store.post_details.liked_by_me = true;

            let likers = store.post_details.likers;

            likers == ""
                ? (likers = store.userInfo.player_name)
                : (likers += `、${store.userInfo.player_name}`);

            store.post_details.likers = likers;
            store.post_details.likes_count += 1;
        }
    } catch (error) {
        console.log('err', error);

    }
};

const handleClose = () => {
    replyItem.reply_id = 0;
    replyItem.content = "";
    replyItem.username = "";
};

//设置键盘高度
const handleBoardHeight = computed(() => {
    return getCurrentDevice() == "IOS" ? "12%" : "48%";
});

const displayedCounts = ref(store.post_details.reply_content.map(() => 3));
// 计算当前展示的数据条目  
const displayedItems = computed(() => (items, index) => {
    const sortData = handleSort(items);
    const start = 0;
    const end = start + displayedCounts.value[index];
    return sortData.slice(start, end);
});

// 判断是否还有更多评论可以显示  
const _reply_comment = ref();
const hasMoreComments = (item, index) => {
    nextTick(() => {
        if (_reply_comment.value && _reply_comment.value[index] && _reply_comment.value[index].scrollHeight) {
            const commentElement = _reply_comment.value[index];
            commentElement.style.height = `${commentElement.scrollHeight}px`;
        }
    });
    return displayedCounts.value[index] < item.childComment.length;
};

// 点击按钮时展示更多数据  
const showMore = (item, index) => {

    setTimeout(() => {
        const height = _reply_comment.value[index].scrollHeight;
        _reply_comment.value[index].style.height = height + 'px';
    }, 30);


    if (hasMoreComments(item, index)) {
        displayedCounts.value[index] += 3;
    }
};

const handleOpen = () => {
};

onMounted(() => {
})

</script>

<style lang='scss' scoped>
:deep(.van-nav-bar__text) {
    color: rgba(255, 255, 255, 0.9);
}

:deep(.van-nav-bar__title) {
    color: rgba(255, 255, 255, 0.9);
}

:deep(.van-nav-bar) {
    &::after {
        border: none;
    }
}

:deep(.van-cell) {
    padding: 5px 10px;

    &::after {
        border: none;
    }
}

:deep(.van-field__control) {
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #000;
    font-size: 12px;
    padding: 0 5px;
    height: 48px !important;
}

.post_details_wrapper {
    background: #181627;
    min-height: 100vh;

    .main {
        padding: 0 15px;
        height: calc(100vh - 130px);
        overflow: auto;

        .post_container {
            background: linear-gradient(180deg, #20192b 0%, #212040 100%);
            border-radius: 10px;
            padding: 20px 15px;
            margin-top: 10px;
            overflow: hidden;

            .author_wrapper {
                display: flex;
                justify-content: start;
                align-items: center;

                .author_name {
                    margin: 0 5px 0 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;

                    .admin_name {
                        color: #FEE22A;
                    }
                }

                .author_level {
                    display: flex;
                    // flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .time {
                    flex: 1;
                    text-align: right;
                    color: rgba(255, 255, 255, 0.5);
                }
            }

            .post_content {
                display: flex;
                padding-top: 10px;
                position: relative;
                // align-items: center;

                .con_selected {
                    color: #FEE22A;
                    padding: 0 5px;
                    border: 1px solid #FEE22A;
                    font-size: 10px;
                    border-radius: 2px;
                    position: absolute;
                    margin-top: 1px;
                }

                .con_selected_top {
                    @extend .con_selected;
                    left: 40px;
                }

                .con_text {
                    color: rgba(255, 255, 255, 0.9);
                    line-height: 20px;
                    font-size: 13px;

                    .post_tag {
                        color: #fff;
                        font-size: 12px;
                        color: #0070c0;
                    }
                }

                .ti {
                    text-indent: 40px;
                }

                .ti_top {
                    text-indent: 80px;
                }

                .con_topPost {
                    color: #FF44DD;
                    padding: 0 5px;
                    border: 1px solid #FF44DD;
                    font-size: 10px;
                    border-radius: 2px;
                    position: absolute;
                    margin-top: 1px;
                }
            }

            .post_images {
                margin-top: 15px;
                display: flex;
                justify-content: start;

                .post_image_item {
                    width: 31%;
                    margin-right: 8px;
                    height: 100px;
                    background-color: #181627;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .tool_tab {
                padding-top: 15px;
                display: flex;
                // justify-content: space-evenly;
                justify-content: end;

                .like {
                    font-size: 12px;
                    display: flex;


                    .like_icon_wrapper {
                        width: 22px;
                        position: relative;

                        .like_icon {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    // .count {}
                    // vertical-align: middle;
                }
            }

            .time_item {
                padding-top: 20px;
                display: flex;
                justify-content: space-between;
                color: rgba(255, 255, 255, 0.9);
            }
        }

        .post_comment {
            margin-top: 10px;
            background: #212040;
            border-radius: 10px;
            padding: 20px 15px;

            .post_com_item {
                .user_box {
                    padding: 20px 0 10px;

                    .user_head {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .author_name {
                            margin: 0 5px 0 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                            color: rgba(255, 255, 255, 0.9);

                            .admin_tag {
                                color: #fff;
                                font-size: 10px;
                                padding: 0 5px;
                                background-color: #8c6cf6;
                                border-radius: 5px;
                                margin-left: 5px;
                            }
                        }

                        .author_level {
                            display: flex;
                            // flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }

                        .like {
                            font-size: 12px;
                            display: flex;
                            justify-content: end;
                            flex: 1;

                            .like_icon_wrapper {
                                width: 22px;
                                position: relative;

                                .like_icon {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                    }

                    .item_comment {
                        padding-left: 35px;

                        .admin_content {
                            color: #FEE22A;
                        }

                        .item_comment_time {
                            font-size: 10px;
                            color: rgba(255, 255, 255, 0.5);
                            margin-left: 10px;

                        }
                    }
                }

                .reply_comment {
                    overflow: hidden;
                    transition: height .2s linear;
                    transition-timing-function: ease-out;

                    .user_box_re {
                        @extend .user_box;
                        padding: 10px 0 10px 35px;

                        .user_head {

                            .author_name {
                                margin: 0 5px;
                            }
                        }

                        .item_comment {
                            padding: 5px 0;
                        }

                        .reply_user {
                            span {
                                font-size: 10px;
                                color: rgba(255, 255, 255, 0.5);

                                &:first-child {
                                    margin: 0 5px;
                                }
                            }
                        }
                    }

                    .moreComment {
                        padding: 5px 0 5px 35px;
                        color: #93F6FC;
                        font-size: 10px;
                    }
                }

            }
        }

        .no_post_comment {
            margin-top: 10px;
            background: #212040;
            border-radius: 10px;
            padding: 20px 15px;
            text-align: center;
        }

    }

    .comment_input_box {
        width: 100%;
        height: 60px;
        background-color: #212040;
        padding: 15px;
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 6;

        .text_box {
            flex: 1;
            height: 30px;
            border-radius: 5px;
            border: none;
            background-color: #181627;
            padding: 0 10px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.5);

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .comment_btn {
            margin: 0 10px;
        }
    }

}

.replyTool {
    height: 200px;

    :deep(.van-overlay) {
        background: none !important;
    }

    .comment_main {
        // position: fixed;
        // width: 100%;
        display: flex;
        align-items: center;
        // bottom: 0;
        z-index: 10;

        padding: 10px 0;

        .input {
            flex: 1;
            // height: 30px;
            font-size: 20px;
        }

        .tool_bar {
            display: flex;
            background-color: #fff;
            height: 30px;
            justify-content: end;
            align-items: center;
            padding: 0 15px;

            .sent_btn {
                padding: 5px 10px;
                background-color: #f2f2f2;
                color: #ccc;
                font-size: 12px;
                border-radius: 5px;
            }

            .sent_btn_active {
                @extend .sent_btn;
                background-color: #ee4444;
                color: #fff;
            }
        }
    }
}
</style>