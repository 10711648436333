<template>
  <LandscapePopup
    :show="store.sprog_task_show"
    @open="whenThePopupOpen"
    @close="whenThePopupClose"
    :mask="true"
  >
    <div class="sprog_task_wrapper scale_in_center_class">
      <div class="count_down_timer">还剩 {{ SprogCountDownTimeShow }}</div>
      <div
        class="close_icon2"
        @click="store.storeShowToggle('sprog_task_show', false)"
      ></div>

      <div class="scbox">
        <div class="_cctv_" ref="_RollBox">
          <div class="_content">
            <div
              class="step_box"
              v-for="boxItem in store.sprogTaskData.datas"
              :id="`step_box_${boxItem.step}`"
              :style="{
                left: boxItem.p_x,
                top: boxItem.p_y,
              }"
              @click="handleTaskBubbleClick(boxItem.task_info)"
            >
              <div
                class="box_cover"
                :class="{
                  'shake-bottom-class':
                    (store.sprogTaskData.current_step == 4 ||
                      store.sprogTaskData.current_step == 7) &&
                    (boxItem.step == 4 || boxItem.step == 7),
                }"
              >
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :show-loading="false"
                  :src="box_1"
                  v-show="
                    boxItem.step >= store.sprogTaskData.current_step &&
                    boxItem.step != 4 &&
                    boxItem.step != 7 &&
                    boxItem.step != 8
                  "
                />
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :show-loading="false"
                  :src="box_3"
                  v-show="
                    boxItem.step >= store.sprogTaskData.current_step &&
                    (boxItem.step == 4 || boxItem.step == 7)
                  "
                />
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :show-loading="false"
                  v-show="
                    boxItem.step < store.sprogTaskData.current_step &&
                    boxItem.step != 4 &&
                    boxItem.step != 7 &&
                    boxItem.step != 8
                  "
                  :src="box_2"
                />
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :show-loading="false"
                  v-show="
                    boxItem.step < store.sprogTaskData.current_step &&
                    (boxItem.step == 4 || boxItem.step == 7)
                  "
                  :src="box_4"
                />
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :show-loading="false"
                  v-show="
                    boxItem.step == 8 && store.sprogTaskData.current_step <= 8
                  "
                  :src="box_5"
                />
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :show-loading="false"
                  v-show="store.sprogTaskData.current_step > 8"
                  :src="box_6"
                />
              </div>

              <div
                class="task_bubble"
                v-if="boxItem.step != 4 && boxItem.step != 7"
                :style="{
                  backgroundPosition: `${boxItem.bubble_x} ${
                    boxItem.step > store.sprogTaskData.current_step
                      ? '-2.7467rem'
                      : 0
                  }`,
                }"
              ></div>
              <div
                class="task_btn"
                v-if="
                  store.sprogTaskData.current_step >= boxItem.step &&
                  boxItem.step != 4 &&
                  boxItem.step != 7 &&
                  boxItem.step != 8
                "
                @click="handleTaskBubbleClick(boxItem.task_info)"
              >
                <!-- 当前去完成按钮 -->
                <van-image
                  width="100%"
                  height="100%"
                  block
                  v-show="
                    boxItem.step == store.sprogTaskData.current_step &&
                    boxItem.task_info.isNoDone
                  "
                  :show-loading="false"
                  :src="start_btn"
                />
                <!-- 领取按钮 -->
                <van-image
                  width="100%"
                  height="100%"
                  block
                  v-show="
                    boxItem.step == store.sprogTaskData.current_step &&
                    !boxItem.task_info.isNoDone
                  "
                  :show-loading="false"
                  :src="get_btn"
                />

                <!-- 完成按钮 -->
                <van-image
                  width="100%"
                  height="100%"
                  block
                  v-show="boxItem.step < store.sprogTaskData.current_step"
                  :show-loading="false"
                  :src="done_btn"
                />
              </div>

              <div class="task_8_btn" v-if="boxItem.step == 8">
                <van-image
                  width="100%"
                  height="100%"
                  block
                  v-show="boxItem.task_info.isNoDone"
                  :show-loading="false"
                  :src="start_8_btn"
                />
                <van-image
                  width="100%"
                  height="100%"
                  block
                  v-show="!boxItem.task_info.isNoDone"
                  :show-loading="false"
                  :src="get_8_btn"
                />
              </div>

              <div
                class="task_4_7_btn"
                v-if="
                  (store.sprogTaskData.current_step == 4 ||
                    store.sprogTaskData.current_step == 7) &&
                  (boxItem.step == 4 || boxItem.step == 7)
                "
                @click="handleTaskBubbleClick(boxItem.task_info)"
              ></div>
            </div>

            <div class="line_wrapp">
              <div class="line_1"></div>
              <!-- <div class="line_2"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </LandscapePopup>

  <LandscapePopup :show="task_info_show" z-index="2002">
    <div class="task_popup_wrapper scale_in_center_class">
      <div class="task_content">
        <div class="left_b">任务内容:</div>
        <div class="task_list">
          <div class="task_item" v-for="tItem in cur_task_info_data.task">
            {{ `${tItem.task_t} ${tItem.num}/${tItem.complete_num}` }}
          </div>
        </div>
      </div>

      <div class="task_award_wrapper">
        <div class="left_b">任务奖励:</div>
        <div class="award_list">
          <div
            class="award_item"
            v-for="aItem in cur_task_info_data.task_award"
          >
            <div
              class="award_icon"
              :style="{
                transform:
                  aItem.type == 'xiaobawang'
                    ? `translate(-50%, -50%) scale(1.5)`
                    : 'translate(-50%, -50%)',
              }"
            >
              <van-image
                width="100%"
                height="auto"
                block
                :show-loading="false"
                :src="aItem.award_icon"
              />
            </div>
            <div class="award_num">{{ aItem.num }}</div>
          </div>
        </div>
      </div>

      <!-- <div class="task_award_wrapper">
        <div class="title">任务奖励</div>

        </div>
      </div> -->

      <div
        class="task_popup_close_icon"
        @click="task_info_show_toggle(false)"
      ></div>
      <div
        class="task_start_btn"
        v-show="cur_task_info_data.step == store.sprogTaskData.current_step"
        @click="handleGetTaskReward"
      >
        {{ cur_task_info_data.isNoDone ? "开始任务" : "领取" }}
      </div>
    </div>
  </LandscapePopup>

  <LandscapePopup :show="task_reward_show" z-index="2003">
    <div class="task_reward_content">
      <div class="task_reward_list">
        <div class="reward_item" v-for="rItem in cur_task_info_data.task_award">
          <div class="award_icon">
            <van-image
              width="100%"
              height="100%"
              block
              :show-loading="false"
              :src="rItem.award_icon"
            />
          </div>
          <div class="award_num">{{ rItem.num }}</div>
        </div>
      </div>

      <div class="get_task_reward" @click="handleClose">确定</div>
    </div>
  </LandscapePopup>

  <LandscapePopup :show="next_task_pop_show" z-index="2003">
    <div class="next_task_pop_wrapper">
      <div class="text">
        您当前正在游戏中， 是否结束当前游戏进行下一个任务？
      </div>

      <div class="cancel_btn" @click="next_task_pop_show_toggle(false)">
        继续游戏
      </div>
      <div class="confirm_btn" @click="handleGameCurrentEve">下一任务</div>
    </div>
  </LandscapePopup>
</template>

<script setup>
import LandscapePopup from "@/components/LandscapePopup.vue";

import {
  sendInstructions,
  asyncSendInstructions,
  pollingGameinfo,
} from "@/api/sendData";

import { nextTick, ref, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { showToast, closeToast, showConfirmDialog } from "vant";
import { useCountDown, useToggle } from "@vant/use";

const start_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/start_btn.png";
const get_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/get_btn.png";
const done_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/done_btn.png";

const start_8_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/start_8_btn.png";
const get_8_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/get_8_btn.png";

const box_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/box_1.png";
const box_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/box_2.png";
const box_3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/box_3.png";
const box_4 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/box_4.png";
const box_5 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/box_5.png";
const box_6 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/box_6.png";

import Decimal from "decimal.js";

const store = useStore();
const router = useRouter();

// const fenestrule

// const slll = ["mgc", "bbl", "mxt", "msqy_line", "msqy", "hw3"];

watch(
  () => router.currentRoute.value,
  (newV) => {
    if (
      store.sprog_task_show ||
      task_info_show.value ||
      task_reward_show.value ||
      next_task_pop_show.value
    ) {
      store.storeShowToggle("sprog_task_show", false);
      task_info_show_toggle(false);
      task_reward_show_toggle(false);
      next_task_pop_show_toggle(false);
    }
  }
);

const SprogCountDownTimer = useCountDown({
  time: 604800000,
  onFinish() {
    store.storeShowToggle("sprog_task_show", false);
  },
});
function smoothScrollTo(element, scrollLeft, duration) {
  // let start = element.scrollLeft;
  let startTime = null;
  // 缓动函数
  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
  // 滚动函数
  const animateScroll = () => {
    if (startTime === null) startTime = Date.now();

    const time = Date.now() - startTime;
    const progress = time / duration;
    if (progress < 1) {
      let cur_left = easeInOutQuad(progress) * scrollLeft;
      element.scrollLeft = cur_left;
      try {
        requestAnimationFrame(animateScroll);
      } catch (error) {
        setTimeout(animateScroll, 0);
      }
    } else {
      element.scrollLeft = scrollLeft;
    }
  };

  // 开始滚动
  animateScroll();
}

const SprogCountDownTimeShow = computed(() => {
  let _d = SprogCountDownTimer.current.value.days;
  let _h = SprogCountDownTimer.current.value.hours;
  let _m = SprogCountDownTimer.current.value.minutes;
  let _s = SprogCountDownTimer.current.value.seconds;

  _h < 10 && (_h = "0" + _h);
  _m < 10 && (_m = "0" + _m);
  _s < 10 && (_s = "0" + _s);

  return `${_d}天 ${_h}:${_m}:${_s}`;
});

const handleRollBox = (index) => {
  if (!_RollBox.value) return;
  if (!store.sprog_task_show) return;
  if (
    store.sprogTaskData.current_step < 1 &&
    store.sprogTaskData.current_step > 8
  )
    return;

  const _rem = parseInt(document.documentElement.style.fontSize);
  const _px = parseInt(store.sprogTaskData.datas[index - 1].p_x);
  const _target = parseInt(_px * _rem - 200);

  smoothScrollTo(_RollBox.value, _target, 800);
};

//#region 任务数据

const award_icon_maps = {
  lottery:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png",
  gold: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
  tz_card:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/icon_card.png",
  jiacheng_card:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/jiacheng_card_icon.png",
  xiaobawang:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_16.png",
};

const _RollBox = ref();

const initSprogTaskData = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_player_sprog_task",
  });

  if (!res.task_def || !res.player_sprog_task) return;

  const { task_def, player_sprog_task } = res;
  // console.log("player_sprog_task.step?????", player_sprog_task.step);
  if (Number(player_sprog_task.step) > 8) {
    store.$patch((state) => {
      state.userInfo.register_time = false;
    });
    store.storeShowToggle("sprog_task_show", false);
    return;
  }

  const _task_award_all = [];
  task_def.forEach((task_item) => {
    // 奖励列表
    const _task_award = [];

    for (const _k in task_item.reward) {
      const task_reward_item = task_item.reward[_k];
      let _type = task_reward_item.reward_type;
      let _num = task_reward_item.num ?? task_reward_item.complete_num ?? 0;
      if (_type == "item") {
        _type = task_reward_item.item_type;
        const vv = new Decimal(_num).sub(1).mul(100).toNumber();
        _num = +vv + "% 加成";
      }
      if (_type == "chenghao") {
        _type = task_reward_item.title_name;
        _num = "小霸王";
      }

      _task_award.push({
        type: _type,
        award_icon: award_icon_maps[_type],
        num: _num,
      });
    }

    _task_award_all.push(_task_award);
  });

  store.$patch((state) => {
    state.sprogTaskData.current_step = player_sprog_task.step;
    state.sprogTaskData.end_time = player_sprog_task.end_time * 1000;

    state.sprogTaskData.datas[3].task_info.task[0].num =
      player_sprog_task.step - 1;

    state.sprogTaskData.datas[6].task_info.task[0].num =
      player_sprog_task.step - 1;

    player_sprog_task.task.forEach((iitt, iind) => {
      let isNoDone = false;
      for (const _i in iitt) {
        const _item = iitt[_i];
        const _index = parseInt(_i) - 1;

        state.sprogTaskData.datas[iind].task_info.task[_index].complete_num =
          _item.complete_num;
        state.sprogTaskData.datas[iind].task_info.task[_index].num = _item.num;

        if (_item.complete_num > _item.num && !isNoDone) {
          isNoDone = true;
        }
      }
      state.sprogTaskData.datas[iind].task_info.isNoDone = isNoDone;
    });

    _task_award_all.forEach((item, sIndex) => {
      state.sprogTaskData.datas[sIndex].task_info.task_award = item;
    });
  });

  handleRollBox(player_sprog_task.step);

  const endtime = player_sprog_task.end_time * 1000 - Date.now() - 2592e5;
  console.log("endtimeendtimeendtime", endtime);
  if (endtime > 0) {
    SprogCountDownTimer.reset(endtime);
    SprogCountDownTimer.start();
  }

  //
};

//#endregion

//#region 任务弹窗
const [task_info_show, task_info_show_toggle] = useToggle(false);
const cur_task_info_data = ref({
  step: 1,
  game_name: "bbl",
  task: [
    {
      task_t: "解锁前六个宝箱",
      complete_num: 6,
      num: 1,
    },
  ],
  task_award: [
    {
      type: "gold",
      num: 998,
      award_icon:
        "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
    },
  ],
  isNoDone: false,
});

const handleTaskBubbleClick = (task_info) => {
  const c_ = {
    ...task_info,
    isNoDone: false,
  };

  c_.isNoDone = task_info.task.some((itt) => {
    return itt.complete_num > itt.num;
  });

  cur_task_info_data.value = c_;
  task_info_show_toggle(true);
};

//#endregion

//#region 领取任务

// 退分/退出观战 协议
const exitGameRequest = (type = "visit") => {
  const backType = type;
  // 退出观战
  if (backType == "visit") {
    const leave_params = {
      msg_type: "leave_machine",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
    };
    sendInstructions(leave_params, 0);
    if (store.channelType == "ios") {
      sendInstructions(leave_params, 50);
      sendInstructions(leave_params, 100);
    }
  }
  // 退分
  if (backType == "exit") {
    // 退分 params
    const params = {
      msg_type: "return_lottery",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
      device_name: store.current_device.device_name,
    };

    // 第一次
    sendInstructions(params, 0);
    // 第二次
    sendInstructions(params, 1000);
    // 第三次
    sendInstructions(params, 1800);
  }
};

const [task_reward_show, task_reward_show_toggle] = useToggle(false);

const handleGetTaskReward = async () => {
  showToast({
    type: "loading",
    forbidClick: true,
    duration: 0,
    teleport: store.sprog_task_show
      ? ".task_popup_wrapper"
      : store.isLandscape
      ? "#rotate_box"
      : "body",
  });

  if (cur_task_info_data.value.isNoDone) {
    closeToast();

    if (
      (cur_task_info_data.value.step == 1 ||
        cur_task_info_data.value.step == 3) &&
      (router.currentRoute.value.name == "mxt" ||
        router.currentRoute.value.name == "msqy_line")
    ) {
      store.storeShowToggle("sprog_task_show", false);
      task_info_show_toggle(false);
      task_reward_show_toggle(false);
      return;
    }

    if (
      cur_task_info_data.value.step == 5 &&
      router.currentRoute.value.name == "bbl"
    ) {
      store.storeShowToggle("sprog_task_show", false);
      task_info_show_toggle(false);
      task_reward_show_toggle(false);
      return;
    }

    if (
      cur_task_info_data.value.step == 2 &&
      router.currentRoute.value.name == "mgc"
    ) {
      store.storeShowToggle("sprog_task_show", false);
      task_info_show_toggle(false);
      task_reward_show_toggle(false);
      return;
    }
    if (
      cur_task_info_data.value.step == 6 &&
      router.currentRoute.value.name == "hw3"
    ) {
      store.storeShowToggle("sprog_task_show", false);
      task_info_show_toggle(false);
      task_reward_show_toggle(false);
      return;
    }

    !store.gameIsWatch ? next_task_pop_show_toggle(true) : handleGoNextTask();
    return;
  }

  const res = await asyncSendInstructions({
    msg_type: "get_player_sprog_task_reward",
    step: store.sprogTaskData.current_step,
  });
  closeToast();
  if (res.error == "ok") {
    task_info_show_toggle(false);
    task_reward_show_toggle(true);
    let _gold = 0;
    let _lottery = 0;
    let _tz_card = 0;
    cur_task_info_data.value.task_award.forEach((ii) => {
      if (ii.type == "gold") _gold += ii.num;
      if (ii.type == "lottery") _lottery += ii.num;
      if (ii.type == "tz_card") _tz_card += ii.num;
    });

    store.$patch((state) => {
      state.sprogTaskData.current_step = Number(res.step) + 1;
      state.userInfo.gold = state.userInfo.gold + _gold;
      state.userInfo.lottery = state.userInfo.lottery + _lottery;
      state.userInfo.tiaozhan_ticket =
        state.userInfo.tiaozhan_ticket + _tz_card;
    });
  } else {
    const msgss = {
      error: "请稍后重试",
    };

    showToast({
      type: "fail",
      message: res.error,
      teleport: store.sprog_task_show
        ? ".task_popup_wrapper"
        : store.isLandscape
        ? "#rotate_box"
        : "body",
      forbidClick: true,
      onClose() {
        initSprogTaskData();
      },
    });
  }
};

const handleClose = () => {
  if (Number(store.sprogTaskData.current_step) > 8) {
    store.$patch((state) => {
      state.userInfo.register_time = false;
      state.sprog_task_show = false;
    });
  } else task_reward_show_toggle(false);
};

//#endregion

//#region 寻找房间
// 分配房间
const findGameRoom = () => {
  console.log("cur_task_info_data", cur_task_info_data.value);

  pollingGameinfo({
    game_name: cur_task_info_data.value.game_name,
    statu: "start",
    instant: true,
  });

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let challengeRoomList = JSON.parse(
        JSON.stringify(store.gameDataList[cur_task_info_data.value.game_name])
      );

      challengeRoomList = challengeRoomList.filter((game) => {
        const verifyName =
          game.product_name == cur_task_info_data.value.game_name;

        let verifySeat = game.occupy.length <= 3;

        const verifyAssert = game.status != 0;

        const verifyLevel = game.level <= store.userInfo.recharge_level;

        if (game.product_name == "bbl") verifySeat = game.occupy.length <= 1;

        // const verifyRatio = game.exchange == cur_task_info_data.value.game_name.exchange;
        // verifyRatio &&
        return verifySeat && verifyName && verifyLevel && verifyAssert;
      });

      if (challengeRoomList.length > 0) {
        // const randomIndex = Math.floor(
        //   Math.random() * challengeRoomList.length
        // );
        const randomObject = challengeRoomList[0];

        const devList = randomObject.device_info.filter((dev) => {
          return (
            dev.occupy.player_name == null ||
            dev.occupy.player_name == undefined ||
            dev.occupy.player_id == null ||
            dev.occupy.player_id == undefined
          );
        });

        let cDev = null;
        if (devList.length > 0) {
          const randomIndex2 = Math.floor(Math.random() * devList.length);
          cDev = devList[randomIndex2];
        }

        resolve({
          current_device: cDev,
          current_machine: randomObject,
        });
      } else {
        resolve({
          current_device: null,
          current_machine: null,
        });
      }
    }, 1000);
  });
};
//#endregion

const whenThePopupOpen = () => {
  if (store.sprogTaskData.end_time == 0) {
    initSprogTaskData();
  } else {
    handleRollBox(store.sprogTaskData.current_step);
    const end_time = store.sprogTaskData.end_time - Date.now();

    if (end_time > 1000) {
      SprogCountDownTimer.reset(end_time);
      SprogCountDownTimer.start();
    } else {
      store.sprog_task_show = false;
      store.userInfo.register_time = false;
    }
  }
};
const whenThePopupClose = () => {
  task_info_show_toggle(false);
  task_reward_show_toggle(false);
  next_task_pop_show_toggle(false);

  SprogCountDownTimer.pause();
};

//#region 下一任务
const [next_task_pop_show, next_task_pop_show_toggle] = useToggle(false);

// 结算当前任务
const handleGameCurrentEve = () => {
  if (
    (cur_task_info_data.value.step == 1 ||
      cur_task_info_data.value.step == 3) &&
    (router.currentRoute.value.name == "mxt" ||
      router.currentRoute.value.name == "msqy_line")
  ) {
    store.storeShowToggle("sprog_task_show", false);
    task_info_show_toggle(false);
    task_reward_show_toggle(false);
    return;
  }

  if (
    cur_task_info_data.value.step == 5 &&
    router.currentRoute.value.name == "bbl"
  ) {
    store.storeShowToggle("sprog_task_show", false);
    task_info_show_toggle(false);
    task_reward_show_toggle(false);
    return;
  }

  if (
    cur_task_info_data.value.step == 2 &&
    router.currentRoute.value.name == "mgc"
  ) {
    store.storeShowToggle("sprog_task_show", false);
    task_info_show_toggle(false);
    task_reward_show_toggle(false);
    return;
  }
  if (
    cur_task_info_data.value.step == 6 &&
    router.currentRoute.value.name == "hw3"
  ) {
    store.storeShowToggle("sprog_task_show", false);
    task_info_show_toggle(false);
    task_reward_show_toggle(false);
    return;
  }

  exitGameRequest("exit");

  store.initStatu();
  next_task_pop_show_toggle(false);

  handleGoNextTask();
};

const handleGoNextTask = async () => {
  if (cur_task_info_data.value.step == 8) {
    router.replace({
      name: "challenge",
      query: {
        type: 1,
      },
    });
    return;
  }

  pollingGameinfo({
    game_name: cur_task_info_data.value.game_name,
    statu: "close",
    instant: true,
  });

  console.log(
    "cur_task_info_data.value.game_name",
    cur_task_info_data.value.game_name
  );

  store.$patch((state) => {
    state.curTabActive.parent = cur_task_info_data.value.game_name;
    state.curTabActive.children = cur_task_info_data.value.game_name;
  });

  const res = await asyncSendInstructions({
    msg_type: "get_machine_info",
    game_name: cur_task_info_data.value.game_name,
  });

  const keys = Object.keys(res);

  store.$patch((state) => {
    state._cctv_ = res;
    state._cctv22_ = cur_task_info_data.value;
  });

  if (
    keys.length == 0 ||
    res[keys[0]].product_name != cur_task_info_data.value.game_name
  ) {
    closeToast();
    showToast({
      message: "暂无符合房间\n请稍后重试",
      teleport: store.sprog_task_show
        ? ".task_popup_wrapper"
        : store.isLandscape
        ? "#rotate_box"
        : "body",
    });
    return;
  }

  // console.log("resssss", res);

  let room = null;

  for (const key in res) {
    const item = res[key];

    const verifySeat = item.device_info.some((dev) => {
      return !dev.occupy.uid;
    });

    const verifyAssert = item.status != 0;

    const verifyLevel = Number(item.level) <= store.userInfo.recharge_level;

    if (verifySeat && verifyAssert && verifyLevel) {
      room = item;
      break;
    }
  }

  if (!room) {
    closeToast();
    showToast({
      message: "暂无符合房间\n请稍后重试",
      teleport: store.sprog_task_show
        ? ".task_popup_wrapper"
        : store.isLandscape
        ? "#rotate_box"
        : "body",
    });

    return;
  }

  store.$patch((state) => {
    state.current_machine.sp_url = room.sp_url;
    state.current_machine.product_name = room.product_name;
    state.current_machine.child_name = room.child_name;
    state.current_machine.machine_index = room.machine_index;
    state.current_machine.product_id = room.product_id;
    state.current_machine.device_info = room.device_info;
  });

  let _router_name = cur_task_info_data.value.game_name;

  if (_router_name == "msqy") _router_name = "mxt";
  closeToast();
  // console.log("_router_name_router_name", _router_name);
  router.replace({
    name: _router_name == "msqy" ? "mxt" : _router_name,
  });
};

//#endregion

onMounted(() => {
  if (store.sprogTaskData.end_time == 0) initSprogTaskData();
});
</script>

<style lang="scss" scoped>
.task_popup_wrapper {
  position: absolute;
  z-index: 200;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 310px;
  height: 215px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/task_pop_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .title {
    font-size: 12px;
    text-align: center;
  }
  .task_content {
    position: absolute;
    width: 280px;

    top: 60px;
    left: 15px;
    // padding: 14px;
    height: 60px;

    display: flex;
    .left_b {
      width: 80px;
      line-height: 60px;
      text-align: center;
    }

    .task_list {
      flex-direction: column;
      display: flex;
      justify-content: center;
      .task_item {
        white-space: nowrap;
        font-size: 12px;
        // margin: 0 4px;
        height: 20px;
        line-height: 20px;
      }
    }
  }

  .task_award_wrapper {
    position: absolute;

    width: 280px;
    height: 80px;
    top: 120px;
    left: 15px;

    display: flex;
    .left_b {
      width: 80px;
      line-height: 80px;
      text-align: center;
    }

    .award_list {
      flex: 1;
      display: flex;

      justify-content: start;
      // align-items: center;
      padding-top: 8px;
      .award_item {
        position: relative;
        width: 44px;
        height: 44px;
        background: #ca3edd;
        box-shadow: inset 0px 0px 5px 4px #bc1ecb;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #ff50f4;
        margin: 0 5px;

        .award_icon {
          position: absolute;
          width: 100%;
          height: auto;
          top: 50%;
          left: 50%;

          // transform:;
        }

        .award_num {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          font-size: 14px;
          color: #fff;
          transform: translateY(110%);
          text-align: center;
          white-space: nowrap;
        }
      }
    }
  }

  .task_popup_close_icon {
    position: absolute;
    right: 0;
    top: 26px;
    width: 26px;
    height: 26px;
    transform: translateX(36px);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .task_start_btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 42px);
    width: 120px;
    height: 32px;
    background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
    color: #693015;
    font-size: 16px;
    line-height: 32px;
    border-radius: 16px;
    text-align: center;
  }
}

.sprog_task_wrapper {
  position: absolute;
  // z-index: 200;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .count_down_timer {
    position: absolute;
    top: 12vw;
    left: 50%;
    transform: translateX(-50%);
    width: 118px;
    height: 24px;
    color: red;
    line-height: 28px;
    text-align: center;
    font-size: 10px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/time_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    z-index: 3;
  }

  .close_icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 40px;
    background-size: 100% 100%;
    background-image: url("@/assets/images/navbar_back_icon.png");
    background-repeat: no-repeat;
    z-index: 3;
  }

  .close_icon2 {
    position: absolute;
    right: 16vw;
    top: 6vw;
    width: 40px;
    height: 40px;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 12;
  }

  .scbox {
    position: absolute;
    left: 50%;
    top: 50%;

    width: 80vh;
    height: 80vw;
    border-radius: 20px;
    border: 4px solid #fcd15a;
    overflow: hidden;
    transform: translate(-50%, -50%);
  }
  ._cctv_ {
    // overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 100%;
    // border-radius: 20px;
    overflow-x: auto;
  }

  ._content {
    position: relative;
    width: 2446px;
    height: 100%;
    // border-radius: 20px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/bg_hr.png"),
      url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/bg_fuzzy.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .step_box {
      position: absolute;

      width: 180px;
      height: 180px;

      transform-origin: left top;
      transform: scale(0.8);
      z-index: 2;
      .box_cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }

      .task_bubble {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -60px);
        width: 100px;
        height: 103px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/task_bubbles.png");
        background-size: 500px auto;
        background-repeat: no-repeat;
        // background-position: 0 0px;
        z-index: 9;
      }

      .task_btn {
        position: absolute;
        // bottom: -10px;
        left: 50%;
        bottom: 15px;
        transform: translateX(-50%);
        width: 130px;
        height: 66px;
        z-index: 3;
      }

      .task_4_7_btn {
        position: absolute;
        left: 50%;
        top: 0px;
        transform: translate(-50%, -40px);
        width: 110px;
        height: 48px;
        z-index: 3;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/open_btn.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &:nth-child(8) {
        width: 240px !important;
        height: 240px !important;

        .task_8_btn {
          position: absolute;
          // bottom: -10px;
          left: 50%;
          bottom: 20px;
          transform: translateX(-50%);
          width: 170px;
          height: 60px;
          z-index: 3;
        }
      }
    }

    .line_wrapp {
      position: absolute;
      z-index: 1;
      width: 1926px;
      height: 73px;
      top: 180px;
      left: 240px;
      .line_1 {
        position: absolute;
        width: 1926px;
        height: 73px;
        right: 0;
        top: 0;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/line_1.png");
        background-size: 1926px 73px;
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 1;
      }
      // .line_2 {
      //   position: absolute;
      //   width: 1326px;
      //   height: 73px;
      //   right: 0;
      //   top: 0;
      //   z-index: 2;
      //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/line_2.png");
      //   background-size: 1926px 73px;
      //   background-repeat: no-repeat;
      //   background-position: -600px 0;
      // }
    }
  }
}

.task_reward_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 148px;
  width: 350px;
  height: 346px;

  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/win_pup_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 -54px;

  .task_reward_list {
    width: 100%;
    display: flex;

    justify-content: center;

    .reward_item {
      position: relative;
      width: 58px;
      height: 58px;
      background: #ca3edd;
      box-shadow: inset 0px 0px 5px 4px #bc1ecb;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #ff50f4;
      margin: 0 5px;
      // overflow: hidden;
      .award_icon {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }

      .award_num {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        font-size: 14px;
        color: #724026;
        transform: translateY(110%);
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  .get_task_reward {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%, 20px);
    width: 120px;
    height: 32px;
    background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
    color: #693015;
    font-size: 16px;
    line-height: 32px;
    border-radius: 16px;
    text-align: center;
  }
}

.next_task_pop_wrapper {
  position: absolute;
  width: 300px;
  height: 220px;
  left: 50%;
  top: 50%;
  background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
  border-radius: 30px 30px 30px 30px;
  transform: translate(-50%, -50%);

  .text {
    padding: 10px 30px;
    font-size: 16px;
    color: #3e4056;
    text-align: center;
    margin-top: 48px;
  }

  .cancel_btn {
    position: absolute;
    bottom: 20px;
    left: 12px;
    width: 130px;
    height: 42px;
    border-radius: 22px;
    line-height: 42px;
    background: linear-gradient(180deg, #81c3ff 0%, #5034ff 100%);
    opacity: 0.6;
    text-align: center;
    font-size: 16px;
  }

  .confirm_btn {
    position: absolute;
    bottom: 20px;
    right: 12px;
    width: 130px;
    height: 42px;
    border-radius: 22px;
    line-height: 42px;
    background: linear-gradient(180deg, #81c3ff 0%, #5034ff 100%);
    opacity: 1;
    font-size: 16px;
    text-align: center;
  }
}

.shake-bottom-class {
  -webkit-animation: shake-bottom 3.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both infinite;
  animation: shake-bottom 3.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both
    infinite;
  animation-delay: 1s;
}

@-webkit-keyframes shake-bottom {
  0%,
  25% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  2.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  5%,
  10%,
  15% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  7.5%,
  12.5%,
  17.5% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  20% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  22.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  25% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  2.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  5%,
  10%,
  15% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  7.5%,
  12.5%,
  17.5% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  20% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  22.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
</style>
