const level_icon_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_1.png";
const level_icon_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_2.png";
const level_icon_3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_3.png";
const level_icon_4 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_4.png";
const level_icon_5 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_5.png";
const level_icon_6 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_6.png";
const level_icon_7 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_7.png";
const level_icon_8 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_8.png";
const level_icon_9 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_9.png";
const level_icon_10 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_10.png";
const level_icon_11 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_11.png";
const level_icon_12 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_12.png";
const level_icon_13 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_13.png";
const level_icon_14 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_14.png";
const level_icon_15 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_15.png";
const level_icon_16 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_16.png";
const level_icon_17 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_17.png";
const level_icon_18 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_18.png";
const level_icon_19 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_19.png";
const level_icon_20 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_20.png";

// 青铜
const grade_icon_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_bronze1.png";
const grade_icon_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_bronze2.png";
const grade_icon_3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_bronze3.png";
// 白银
const grade_icon_4 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_silver1.png";
const grade_icon_5 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_silver2.png";
const grade_icon_6 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_silver3.png";
// 黄金
const grade_icon_7 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_gold1.png";
const grade_icon_8 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_gold2.png";
const grade_icon_9 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_gold3.png";
const grade_icon_10 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_gold4.png";
// 铂金
const grade_icon_11 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_platinum1.png";
const grade_icon_12 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_platinum2.png";
const grade_icon_13 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_platinum3.png";
const grade_icon_14 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_platinum4.png";
// 钻石
const grade_icon_15 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_diamond1.png";
const grade_icon_16 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_diamond2.png";
const grade_icon_17 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_diamond3.png";
const grade_icon_18 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_diamond4.png";
// 王者 无双 最强 荣耀
const grade_icon_19 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_unparalleled.png";
const grade_icon_20 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_max.png";
const grade_icon_21 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/grad_glory.png";

//#region 天梯等级图标
const rankLevelIcon = {
  1: { level_text: "Lv-1", iconUrl: level_icon_1 },
  2: { level_text: "Lv-2", iconUrl: level_icon_2 },
  3: { level_text: "Lv-3", iconUrl: level_icon_3 },
  4: { level_text: "Lv-4", iconUrl: level_icon_4 },
  5: { level_text: "Lv-5", iconUrl: level_icon_5 },
  6: { level_text: "Lv-6", iconUrl: level_icon_6 },
  7: { level_text: "Lv-7", iconUrl: level_icon_7 },
  8: { level_text: "Lv-8", iconUrl: level_icon_8 },
  9: { level_text: "Lv-9", iconUrl: level_icon_9 },
  10: { level_text: "1星大师", iconUrl: level_icon_10 },
  11: { level_text: "2星大师", iconUrl: level_icon_11 },
  12: { level_text: "3星大师", iconUrl: level_icon_12 },
  13: { level_text: "4星大师", iconUrl: level_icon_13 },
  14: { level_text: "5星大师", iconUrl: level_icon_14 },
  15: { level_text: "1星宗师", iconUrl: level_icon_15 },
  16: { level_text: "2星宗师", iconUrl: level_icon_16 },
  17: { level_text: "3星宗师", iconUrl: level_icon_17 },
  18: { level_text: "4星宗师", iconUrl: level_icon_18 },
  19: { level_text: "5星宗师", iconUrl: level_icon_19 },
  20: { level_text: "至尊", iconUrl: level_icon_20 },
};
//#endregion
//#region 充值等级图标
const gradLevelIcon = {
  1: {
    level_text: "青铜 III",
    iconUrl: grade_icon_3,
    total_recharge: 0,
    complete_gold: 0,
  },
  2: {
    level_text: "青铜 II",
    iconUrl: grade_icon_2,
    total_recharge: 300,
    complete_gold: 0,
  },
  3: {
    level_text: "青铜 I",
    iconUrl: grade_icon_1,
    total_recharge: 500,
    complete_gold: 0,
  },
  4: {
    level_text: "白银 III",
    iconUrl: grade_icon_6,
    total_recharge: 1000,
    complete_gold: 0,
  },
  5: {
    level_text: "白银 II",
    iconUrl: grade_icon_5,
    total_recharge: 3000,
    complete_gold: 0,
  },
  6: {
    level_text: "白银 I",
    iconUrl: grade_icon_4,
    total_recharge: 5000,
    complete_gold: 500,
  },
  7: {
    level_text: "黄金 IV",
    iconUrl: grade_icon_10,
    total_recharge: 10000,
    complete_gold: 1000,
  },
  8: {
    level_text: "黄金 III",
    iconUrl: grade_icon_9,
    total_recharge: 20000,
    complete_gold: 2000,
  },
  9: {
    level_text: "黄金 II",
    iconUrl: grade_icon_8,
    total_recharge: 30000,
    complete_gold: 3000,
  },
  10: {
    level_text: "黄金 I",
    iconUrl: grade_icon_7,
    total_recharge: 50000,
    complete_gold: 5000,
  },
  11: {
    level_text: "铂金 IV",
    iconUrl: grade_icon_14,
    total_recharge: 100000,
    complete_gold: 10000,
  },
  12: {
    level_text: "铂金 III",
    iconUrl: grade_icon_13,
    total_recharge: 200000,
    complete_gold: 20000,
  },
  13: {
    level_text: "铂金 II",
    iconUrl: grade_icon_12,
    total_recharge: 300000,
    complete_gold: 30000,
  },
  14: {
    level_text: "铂金 I",
    iconUrl: grade_icon_11,
    total_recharge: 500000,
    complete_gold: 50000,
  },
  15: {
    level_text: "钻石 IV",
    iconUrl: grade_icon_18,
    total_recharge: 800000,
    complete_gold: 80000,
  },
  16: {
    level_text: "钻石 III",
    iconUrl: grade_icon_17,
    total_recharge: 1000000,
    complete_gold: 100000,
  },
  17: {
    level_text: "钻石 II",
    iconUrl: grade_icon_16,
    total_recharge: 1800000,
    complete_gold: 180000,
  },
  18: {
    level_text: "钻石 I",
    iconUrl: grade_icon_15,
    total_recharge: 2500000,
    complete_gold: 250000,
  },
  19: {
    level_text: "最强王者",
    iconUrl: grade_icon_20,
    total_recharge: 3000000,
    complete_gold: 300000,
  },
  20: {
    level_text: "无双王者",
    iconUrl: grade_icon_19,
    total_recharge: 5000000,
    complete_gold: 500000,
  },
  21: {
    level_text: "荣耀王者",
    iconUrl: grade_icon_21,
    total_recharge: 10000000,
    complete_gold: 1000000,
  },
};
//#endregion

export { rankLevelIcon, gradLevelIcon };
