<template>
  <div
    class="sprites_wrapper"
    :style="{
      left: _left,
      top: _top,
      right: _right,
      bottom: _bottom,
      backgroundPosition: b_p,
      transform: `scale(${scaleValue})`,
    }"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <div class="badge_wrapper" v-if="props.badge > 0">
      {{ props.badge }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },

  bg_index: {
    type: Number,
    default: 0,
  },
  badge: {
    type: [String, Number],
    default: 0,
  },
});

const emits = defineEmits(["_click"]);

const rem = parseFloat(document.documentElement.style.fontSize);
const b_p = computed(() => {
  const toN = Number(props.bg_index);

  return `-${1.3867 * toN}rem 0`;
});

const _top = computed(() => {
  if (props.top == "auto") return "auto";
  return (props.top / rem).toFixed(5) + "rem";
});

const _right = computed(() => {
  if (props.right == "auto") return "auto";
  return (props.right / rem).toFixed(5) + "rem";
});
const _bottom = computed(() => {
  if (props.bottom == "auto") return "auto";
  return (props.bottom / rem).toFixed(5) + "rem";
});

const _left = computed(() => {
  if (props.left == "auto") return "auto";
  return (props.left / rem).toFixed(5) + "rem";
});

const scaleValue = ref(1);
const handleTouchStart = () => {
  scaleValue.value = 1.2;
};
const handleTouchEnd = () => {
  scaleValue.value = 1;
  emits("_click");
};
</script>

<style lang="scss" scoped>
.sprites_wrapper {
  position: absolute;
  width: 52px;
  height: 52px;
  // background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/sprites.png");
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/sprites.png");
  background-size: auto 52px;
  background-repeat: no-repeat;
  z-index: 5;
  .badge_wrapper {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 14px;
    height: 14px;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    background-color: #fc0000;
    border-radius: 50%;
  }
}
</style>
