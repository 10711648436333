<template>
  <div
    class="vipCenter"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <NavBar />

    <div class="width h-140"></div>

    <div class="container">
      <div class="top_area">
        <div class="level_wrapper">
          <div class="level_progress_outer_wrapper">
            <div class="cur_level">
              <div class="level_info">
                <div class="level_icon">
                  <van-image
                    :show-loading="false"
                    width="100%"
                    height="auto"
                    :src="level_info_data.cur_level_info.iconUrl"
                  />
                </div>

                <span>{{ level_info_data.cur_level_info.level_text }}</span>
              </div>
            </div>
            <div class="progress_wrapper">
              <div class="vip_exp_max">
                {{ level_info_data.cur_exp }}/{{
                  level_info_data.next_level_info.total_recharge
                }}
              </div>
              <div class="progress">
                <van-progress
                  :percentage="level_info_data.percentage"
                  stroke-width="10"
                  :show-pivot="false"
                  color="#F2BC25"
                  track-color="#8C56F4"
                />
              </div>
              <div class="vip_exp_max2">
                还需要{{
                  level_info_data.next_level_info.total_recharge -
                  level_info_data.cur_exp
                }}经验达到{{ level_info_data.next_level_info.level_text }}
              </div>
            </div>

            <div class="next_level">
              <div class="level_info">
                <div class="level_icon">
                  <van-image
                    :show-loading="false"
                    width="100%"
                    height="auto"
                    :src="level_info_data.next_level_info.iconUrl"
                  />
                </div>

                <span>{{ level_info_data.next_level_info.level_text }}</span>
              </div>
            </div>
          </div>

          <div class="r_btn_wrapper">
            <div class="r_btn" @click="payShowChange">立即充值</div>
          </div>
        </div>

        <div class="info_wrapper">
          <div
            class="active_block"
            :style="{
              left: 1.76 + level_info_data.dan * 1.0933 + 'rem',
            }"
          ></div>
          <div class="info_msg"></div>
        </div>

        <div class="vip_grade_list">
          <div class="vip_grade_list_title">
            <div class="vip_grade_list_title_text">
              <span>会员奖励 </span>
              <p>会员等级奖励金币在达成会员等级后</p>
              <p>在"我的-奖励"中领取</p>
            </div>
            <div class="vip_grade_list_icon"></div>
          </div>
          <!-- #492476 -->

          <div class="vip_grade_tab_title">
            <span class="grade_icon_title">晋级等级</span>
            <span class="grade_name">VIP头像框</span>
            <span class="grade_reward">达成奖励</span>
          </div>

          <div
            class="vip_grade_list_item"
            v-for="(gradeItem, gradeInex) in gradLevelIcon"
            v-show="gradeItem.complete_gold"
          >
            <div class="grade_item_icon">
              <van-image
                width="100%"
                height="auto"
                class="absolute-center"
                :src="gradeItem.iconUrl"
              />
            </div>
            <div class="grade_item_content">
              {{ gradeItem.level_text }}
            </div>
            <div class="grade_frame_wrapper">
              <van-image
                width="100%"
                height="100%"
                :src="getLevelFrameUrl(gradeInex)"
                block
              />
            </div>

            <div class="garde_reward">
              <div class="garde_reward_btn">
                <div class="w-20 h-20">
                  <van-image width="100%" height="auto" :src="gold_icon" />
                </div>
                <span>+{{ gradeItem.complete_gold }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import HeadPortrait from "@/components/HeadPortrait.vue";
import { useStore } from "@/store/store";
import NavBar from "@/components/NavBar.vue";
import { sendInstructions } from "@/api/sendData";
import { onBeforeMount, computed } from "vue";
import { gradLevelIcon } from "@/utils/levelData";

const store = useStore();
const router = useRouter();
const gold_icon = store.imgBaseUrl + "/gold_icon.png";
const level_1_frame = store.imgBaseUrl + "/grade/frame_silver.png";
const level_2_frame = store.imgBaseUrl + "/grade/frame_gold.png";
const level_3_frame = store.imgBaseUrl + "/grade/frame_platinum.png";
const level_4_frame = store.imgBaseUrl + "/grade/frame_diamond.png";
const level_5_frame = store.imgBaseUrl + "/grade/frame_max.png";
const level_6_frame = store.imgBaseUrl + "/grade/frame_unparalleled.png";
const level_7_frame = store.imgBaseUrl + "/grade/frame_glory.png";

const frameArr = [
  level_1_frame,
  level_2_frame,
  level_3_frame,
  level_4_frame,
  level_5_frame,
  level_6_frame,
  level_7_frame,
];

const getLevelFrameUrl = (index) => {
  let _index = Number(index);

  if (_index > 0 && _index <= 6) _index = 0;
  if (_index > 6 && _index <= 10) _index = 1;
  if (_index > 10 && _index <= 14) _index = 2;
  if (_index > 14 && _index <= 18) _index = 3;

  if (_index == 19) _index = 4;
  if (_index == 20) _index = 5;
  if (_index == 21) _index = 6;

  return frameArr[_index];
};

// const grad_tabble = store.imgBaseUrl + "/grade/grad_tabble.png";

const payShowChange = () => {
  store.payPupToggle(true);
};

const level_info_data = computed(() => {
  const _index = store.userInfo.recharge_level;
  const cur_level_info = gradLevelIcon[_index];
  const next_level_info = gradLevelIcon[_index + 1];

  const cur_exp =
    store.initPayPupData.userPayInfo.total_recharge * 10 +
    store.initPayPupData.userPayInfo.vip_exp;

  const percentage = parseInt((cur_exp / next_level_info.total_recharge) * 100);

  const dan_list = [
    [1, 3],
    [4, 6],
    [7, 10],
    [11, 14],
    [15, 18],
    [19, 21],
  ];

  const user_l = store.userInfo.recharge_level;
  const dan = dan_list.findIndex((item) => {
    return user_l >= item[0] && user_l <= item[1];
  });

  return {
    cur_level_info,
    next_level_info,
    cur_exp,
    percentage,
    dan: dan < 0 ? 0 : dan,
  };
});

onBeforeMount(() => {
  if (store.initPayPupData.base.length == 0) {
    sendInstructions({
      msg_type: "get_player_recharge_status",
    });
  }
});
</script>

<style lang="scss" scoped>
.vipCenter {
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/vip_top_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

  .top_area {
    padding-bottom: 20px;

    .level_wrapper {
      height: 150px;
      background-color: #492476;
      border-radius: 10px;
      padding: 24px 18px 0 18px;
      margin-bottom: 20px;
      .level_progress_outer_wrapper {
        width: 100%;
        height: 72px;

        display: flex;

        .progress_wrapper {
          flex: 1;

          text-align: center;

          .progress {
            padding: 0 10px;
          }

          .vip_exp_max {
            margin-top: 4px;
            font-size: 10px;
            margin-bottom: 8px;
          }
          .vip_exp_max2 {
            margin-top: 10px;
            font-size: 8px;
          }
        }

        .cur_level,
        .next_level {
          position: relative;
          width: 62px;
          height: 72px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/level_wrap.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .level_info {
            position: absolute;
            left: 50%;
            top: 4px;
            width: 36px;
            top: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            .level_icon {
              width: 36px;
              height: auto;
            }
          }
        }
      }

      .r_btn_wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        .r_btn {
          color: #693015;
          font-size: 14px;
          line-height: 28px;
          width: 100px;
          height: 28px;
          background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
          border-radius: 14px;
          text-align: center;
        }
      }
    }

    .info_wrapper {
      position: relative;
      height: 155px;
      background-color: #492476;
      border-radius: 10px;
      margin-bottom: 20px;
      .active_block {
        position: absolute;
        top: 0;
        height: 100%;
        width: 36px;
        left: 0px;

        background: linear-gradient(180deg, #e8209a 60%, #a31073 100%);
        opacity: 0.6;
      }

      .info_msg {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 265px;
        height: 130px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/vip_info.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: translate(-50%, -50%);
      }
    }

    .user_info_wrapper {
      padding: 0 11px;

      .grade_wrapper {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/vip_grade_bg.png");
        background-size: 100% 100%;
        // height: 127px;
        width: 100%;
        padding: 10px 16px 40px 16px;
        margin-top: 12px;
        position: relative;

        .user_wrapper {
          display: flex;
        }

        .grade_icon_wrapper {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade_icon_bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 96px;
          height: 96px;
          position: absolute;
          right: 6px;
          top: 0;
          transform: translateY(-40px);

          .grade_icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .grade_name {
          margin-top: 12px;
          .grade_name_text {
            background: linear-gradient(180deg, #ffe7c2 20%, #d68930 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
            font-size: 22px;
            font-style: italic;
          }
        }

        .grade_tips {
          display: flex;
          margin-top: 20px;
          .grade_tips_text {
            // flex: 1;
            .text {
              font-weight: 500;
              color: #ffe7c2;
            }
          }
        }
      }
    }

    .grade_info_wrapper {
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade_info_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 163px;
      transform: translateY(-20px);
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      .info_title_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 22px;
        .title_icon {
          width: 12px;
          height: 12px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/title_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .title {
          background: linear-gradient(180deg, #ffe7c2 20%, #d68930 100%);
          padding: 0 4px;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 14px;
          line-height: 22px;
          font-weight: bold;
        }
      }

      .grade_items {
        display: flex;
        margin-top: 20px;
        .grade_item {
          flex: 1;
          text-align: center;
          .grade_item_value {
            width: 58px;
            height: 58px;
            margin: 0 auto;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/vip_item_bg.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
            .grade_item_number {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: linear-gradient(180deg, #ffe7c2 20%, #d68930 100%);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              font-weight: bold;
              font-size: 16px;
              overflow: hidden;
              border: none;
            }
          }
          .grade_item_text {
            margin-top: 10px;
          }
        }
      }
    }

    .vip_grade_list {
      // margin-top: 15px;
      border-radius: 20px;
      background-color: #492476;
      padding-bottom: 20px;
      .vip_grade_list_title {
        height: 90px;
        display: flex;
        padding-left: 14px;
        .vip_grade_list_title_text {
          flex: 1;
          justify-content: center;
          // align-items: center;
          font-size: 10px;
          flex-direction: column;
          display: flex;
          color: rgba($color: #fff, $alpha: 0.7);
          span {
            font-size: 22px;
            font-weight: bold;
            font-style: italic;
            background: linear-gradient(180deg, #ffe7c2 20%, #d68930 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-right: 12px;
          }
        }
        .vip_grade_list_icon {
          width: 128px;
          height: 96px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade_xingxing.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          transform: translate(8px, -18px);
        }
      }

      .vip_grade_tab_title {
        display: flex;
        justify-content: space-between;
        text-align: center;
        .grade_icon_title {
          width: 60px;
        }
        .grade_name {
          min-width: 80px;
        }

        .grade_reward {
          min-width: 67px;
        }
      }

      .vip_grade_list_item {
        display: flex;
        padding-left: 14px;
        padding-right: 14px;
        margin-top: 15px;
        .grade_item_icon {
          width: 30px;
          height: 30px;
          background: linear-gradient(180deg, #fef4db 0%, #fdf1cd 100%);
          border-radius: 50%;
          position: relative;
        }
        .grade_item_content {
          // flex: 1;
          line-height: 30px;
          padding-left: 10px;
          width: 110px;
        }

        .grade_frame_wrapper {
          height: 30px;
          width: 30px;
          margin-right: auto;
        }

        .garde_reward {
          // width: 66px;
          min-width: 80px;
          // position: relative;
          .garde_reward_btn {
            // position: absolute;
            // left: 50%;
            // top: 50%;
            // transform: translate(-50%, -50%);
            // width: 62px;
            padding: 0 7px;
            height: 24px;
            border-radius: 12px;
            background: linear-gradient(180deg, #fef4db 0%, #fdf1cd 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ae6d09;
          }
        }
      }
    }
  }
}
</style>
