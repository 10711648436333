import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/common.css";
import "amfe-flexible/index.js";
import "vant/lib/index.css";
import "./assets/css/font.css";

import { createPinia } from "pinia";
const pinia = createPinia();
const app = createApp(App);

import {
  ConfigProvider,
  Button,
  Tabbar,
  TabbarItem,
  Image as VanImage,
  Icon,
  Swipe,
  SwipeItem,
  NoticeBar,
  Grid,
  GridItem,
  Tab,
  Tabs,
  NavBar,
  Progress,
  Popup,
  Cell,
  CellGroup,
  Loading,
  Notify,
  Dialog,
  Toast,
  Field,
  Collapse,
  CollapseItem,
  Overlay,
  Col,
  Row,
  Space,
  Circle,
  Uploader,
  TextEllipsis,
  ShareSheet,
  Step,
  Steps,
  RadioGroup,
  Radio,
  ImagePreview,
  Barrage,
  PasswordInput,
  NumberKeyboard,
  Switch,
  List,
  Slider,
  RollingText,
  Checkbox,
  CheckboxGroup,
  Form,
  PullRefresh,
} from "vant";

app
  .use(router)
  .use(pinia)
  .use(Toast)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Button)
  .use(ConfigProvider)
  .use(Icon)
  .use(Swipe)
  .use(SwipeItem)
  .use(NoticeBar)
  .use(Grid)
  .use(GridItem)
  .use(VanImage)
  .use(NavBar)
  .use(Progress)
  .use(Tab)
  .use(Tabs)
  .use(Popup)
  .use(Cell)
  .use(CellGroup)
  .use(Notify)
  .use(Loading)
  .use(Field)
  .use(Overlay)
  .use(Dialog)
  .use(Collapse)
  .use(Col)
  .use(Row)
  .use(Space)
  .use(Circle)
  .use(Uploader)
  .use(TextEllipsis)
  .use(ShareSheet)
  .use(CollapseItem)
  .use(Step)
  .use(Steps)
  .use(RadioGroup)
  .use(ImagePreview)
  .use(Barrage)
  .use(Radio)
  .use(Switch)
  .use(List)
  .use(Slider)
  .use(RollingText)
  .use(PasswordInput)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Form)
  .use(NumberKeyboard)
  .use(PullRefresh);

window.getGameTimer = null;
window.setDataPromiseResolveMap = new Map();
import { handleCallBackEvent } from "@/api/sendData";
// 设定数据
window.set_data = function (data) {
  // 初始化的数据
  let iniData = null;
  // 消息类型属性
  let msg_type = "";

  // 如果是JSON 字符串
  if (typeof data === "string") {
    try {
      iniData = JSON.parse(data);
      msg_type = iniData.msg_type;
    } catch (error) {
      iniData = data;
      msg_type = iniData.msg_type;
    }
  }

  // 如果是对象类型
  if (typeof data === "object") {
    iniData = data;
    msg_type = iniData.msg_type;
  }

  if (window.setDataPromiseResolveMap.get(msg_type)) {
    window.setDataPromiseResolveMap.get(msg_type)(iniData.info);
    window.setDataPromiseResolveMap.delete(msg_type);
  } else handleCallBackEvent(msg_type, iniData.info);
};

app.mount("#app");
