<template>
  <!-- 收集提示 -->
  <div class="collect_hint_wrapper">
    <div
      class="collect_hint_item"
      v-for="hintItem in store.collect_gem_event_result"
      :key="hintItem.key"
      :style="{
        left: (hintItem.device_index - 1) * 25 + '%',
      }"
    >
      <div class="collect_cover">
        <van-image
          width="100%"
          height="100%"
          fit="fill"
          position="center"
          :show-loading="false"
          :src="gem_info_maps[hintItem.gem_type]"
        />
      </div>
      <div class="collect_num">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          :show-loading="false"
          :src="number_img_maps[hintItem.num]"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { watch, ref, computed, onBeforeUnmount } from "vue";
// import collectClassIDs from "@/utils/collectClassIDs";

import feng_big from "@/assets/images/gemShop/feng_2.png";
import huo_big from "@/assets/images/gemShop/huo_2.png";
import lei_big from "@/assets/images/gemShop/lei_2.png";
import dian_big from "@/assets/images/gemShop/dian_2.png";
import long_big from "@/assets/images/gemShop/long_2.png";

import { Howl, Howler } from "howler";
import collect_hint from "@/assets/audios/collect_hint.mp3";

const store = useStore();

const gem_info_maps = {
  feng: feng_big,
  huo: huo_big,
  lei: lei_big,
  dian: dian_big,
  long: long_big,
};

const number_img_maps = {
  1: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/add_num_1.png",
  2: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/mul_num_2.png",
  3: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/mul_num_3.png",
  4: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/mul_num_4.png",
  5: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/mul_num_5.png",
};

const emits = defineEmits(["callback"]);

const collectHintHowl = new Howl({
  src: [collect_hint],
  autoplay: false,
  loop: false,
});

const hintSoundPlay = () => {
  collectHintHowl.play();

  // 收集到数据后的后续反馈
  if (store.current_machine.product_name == "bbl") {
    emits("callback");
  }
};

// const findHintCover = (hintItem) => {
//   return collectClassIDs[hintItem.game_name].find((item) => {
//     return item.classID == hintItem.class_id;
//   }).url;
// };

watch(
  () => store.collect_gem_event_result.length,
  (newV, oldV) => {
    newV > oldV && hintSoundPlay();
  }
);

onBeforeUnmount(() => {
  collectHintHowl.unload();
});
</script>

<style lang="scss" scoped>
.collect_hint_wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 100%;
  height: 25vh;

  .collect_hint_item {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 25vh;
    opacity: 1;

    animation: barrage_hint 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;

    .collect_cover {
      width: 48px;
      height: 48px;
    }

    .collect_num {
      width: 30px;
      height: 30px;
    }
  }
}

.animationClass1,
.animationClass2,
.animationClass3,
.animationClass4,
.animationClass5 {
  animation: barrage_hint 3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  // animation-iteration-count: infinite;
}

@keyframes barrage_hint {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(-600%);
    opacity: 0;
  }
}

@-webkit-keyframes barrage_hint

/* Safari 与 Chrome */ {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(-600%);
    opacity: 0;
  }
}
</style>
