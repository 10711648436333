<template>
  <div class="forum_wrapper" :style="{
    paddingTop: store.areaInsetTopValue + 'px',
  }" @click.capture="handleCapClick">
    <div class="my_nav_bar_wrapper">
      <div class="nav_bar">
        <div class="nav_left_wrapper" @click="router.replace('/home')">
          <!-- <van-icon name="arrow-left" /> -->
        </div>
        <div class="nav_text_wrapper"><span>极客秀</span></div>
        <div class="nav_right_wrapper">
          <div class="nav_right_icon" @click="goToUrl('create')">
            <van-image width="100%" height="100%" :show-loading="false" :src="create_forum_icon" />
          </div>
          <div class="nav_right_icon" @click="goToUrl('my')">
            <van-image width="100%" height="100%" :show-loading="false" :src="my_forums_icon" />
          </div>
        </div>
      </div>
    </div>

    <div class="forum_list_wrapper">
      <van-tabs v-model:active="active">
        <van-tab v-for="(tabItem, tabIndex) in tabData" :title="tabItem.name" :key="tabIndex">
          <!-- banner -->
          <div class="show_swipe">
            <van-swipe :show-indicators="false" :autoplay="3000">
              <van-swipe-item v-for="(item, index) in showBannerList" :key="index">
                <img class="show_swipe_img" :src="item.imageUrl" />
              </van-swipe-item>
            </van-swipe>
          </div>

          <!-- 置顶帖 -->
          <!-- <div class="tips_wrapper">
            <div class="activity_tips" v-for="topItem in topPostList"
              @click="handleToPostDetails(topItem)">
              <div class="tip_tab">置顶</div>
              <div class="tip_content">{{ topItem.title }}</div>
            </div>
          </div> -->

          <!-- 帖子 -->
          <div class="forum_list">
            <van-pull-refresh v-model="tabItem.refreshLoading" @refresh="onRefresh(tabIndex)">
              <van-list v-model:loading="tabItem.loading" :finished="tabItem.finished" ref="_vanListRef"
                finished-text="-------没有更多啦-------" @load="onLoadList(tabIndex)">
                <!-- 置顶帖 -->
                <div class="post_item" v-for="(formItem, formIndex) in topPostList" :key="formItem.id">
                  <!-- <div @click="handleToPostDetails(formItem)"> -->
                  <div>
                    <div class="author_wrapper">
                      <HeadPortrait width="30px" height="30px" :level="Number(formItem.recharge_level)"
                        :src="formItem.head_img_url" :ae_id="formItem.select_title" ae fe />
                      <div class="author_name">
                        <span :class="[formItem.is_admin && 'admin_name']">{{
                          formItem.user_name
                        }}</span>
                      </div>
                      <div class="author_level">
                        <Level :border="false" level_type="recharge_level"
                          :level_value="Number(formItem.recharge_level)" />
                        <Level :border="false" :level_value="Number(formItem.level)" />
                      </div>

                      <div class="time">{{ formItem.create_time }}</div>
                    </div>

                    <div class="post_content">
                      <span class="con_topPost">置顶</span>
                      <span class="con_selected_topPost" v-if="formItem.is_admin">官方</span>
                      <p :class="[
                        'con_text',
                        formItem.is_admin ? 'admin_ti' : 'ti',
                      ]">
                        {{ formItem.content }}
                        <span class="post_tag">
                          {{ handleTag(formItem.article_type) }}</span>
                      </p>
                    </div>

                    <div class="post_images">
                      <div class="post_image_item" v-for="(imgItem, imgIndex) in formItem.images" @click.stop="
                        handleImagePreview(formItem.images, imgIndex)
                        ">
                        <van-image width="100%" height="100%" fit="contain" :show-loading="false" :src="imgItem" />
                      </div>
                    </div>
                  </div>

                  <div class="post_comment" ref="_post_top_comment" v-if="formItem.total_num > 0">
                    <div class="post_com_item" v-for="(comment, commentIndex) in displayedItems(formItem)"
                      @click="handleReply(comment)">

                      <div class="com_box">
                        <span class="user_name" :style="`color: ${comment.is_admin ? '#FEE22A' : '#93F6FC'}`">{{
                          comment.user_name }}</span>
                        <span class="reply_tag" v-if="comment.reply_user_name">回复</span>
                        <span class="reply_name" v-if="comment.reply_user_name">{{ comment.reply_user_name }}</span>
                        <span class="box_text">:<span :class="comment.is_admin ? 'admin_content' : ''"> {{
                          comment.content }}</span> </span>
                      </div>
                    </div>

                    <div class="com_total" v-if="hasMoreTopComments(formItem, formIndex)"
                      @click="showMoreTop(formItem, formIndex)">
                      展示更多</div>

                  </div>

                  <div ref="_post_top_comment" v-else></div>


                  <div class="tool_tab">
                    <div class="com_total">共{{ formItem.total_num }}条评论</div>

                    <div class="more_btn">
                      <van-icon name="ellipsis" size="20" @click="handleMoreTool(formItem)" />

                      <Transition name="more-tool">
                        <div class="more_tool_btn" v-show="formItem.show_tool">
                          <div class="like" @click.stop="whenTheUserLike(formItem)">
                            <div class="like_icon_wrapper">
                              <van-image class="like_icon" width="20px" height="20px" :show-loading="false"
                                :src="formItem.liked_by_me ? like_icon_active : like_icon" />
                            </div>

                            <div class="count" style="margin-left: 4px">
                              {{ formItem.likes_count }}
                            </div>
                          </div>

                          <div class="pl_btn" @click="handleReplyPost(formItem)">
                            <van-icon name="chat-o" color="#ccc" size="18" />
                            <div class="pl_text">评论</div>
                          </div>
                        </div>
                      </Transition>

                    </div>

                  </div>
                </div>

                <!-- 其他贴 -->
                <div class="post_item" v-for="(formItem, formIndex) in tabItem.items" :key="formItem.id">

                  <div>
                    <div class="author_wrapper">
                      <HeadPortrait width="30px" height="30px" :level="Number(formItem.recharge_level)"
                        :src="formItem.head_img_url" :ae_id="formItem.select_title" ae fe />
                      <div class="author_name">
                        <span :class="[formItem.is_admin && 'admin_name']">{{
                          formItem.user_name
                        }}</span>
                      </div>
                      <div class="author_level">
                        <Level :border="false" level_type="recharge_level"
                          :level_value="Number(formItem.recharge_level)" />
                        <Level :border="false" :level_value="Number(formItem.level)" />
                      </div>

                      <div class="time">{{ formItem.create_time }}</div>
                    </div>

                    <div class="post_content">
                      <span class='con_selected' v-if="formItem.is_admin">官方</span>
                      <p :class="['con_text', formItem.is_admin ? 'ti' : '']">
                        {{ formItem.content }}
                        <span class="post_tag">
                          {{ handleTag(formItem.article_type) }}</span>
                      </p>
                    </div>

                    <div class="post_images">
                      <div class="post_image_item" v-for="(imgItem, imgIndex) in formItem.images" @click.stop="
                        handleImagePreview(formItem.images, imgIndex)
                        ">
                        <van-image width="100%" height="100%" fit="contain" :show-loading="false" :src="imgItem" />
                      </div>
                    </div>
                  </div>

                  <div class="post_comment" ref="_post_comment" v-if="formItem.total_num > 0">

                    <div class="post_com_item" v-for="(comment, commentIndex) in displayedItems(formItem)"
                      :key="commentIndex" @click="handleReply(comment)">
                      <div class="com_box">
                        <span class="user_name" :style="`color: ${comment.is_admin ? '#FEE22A' : '#93F6FC'}`">{{
                          comment.user_name }}</span>
                        <span class="reply_tag" v-if="comment.reply_user_name">回复</span>
                        <span class="reply_name" v-if="comment.reply_user_name">{{ comment.reply_user_name }}</span>
                        <span class="box_text">: <span :class="comment.is_admin ? 'admin_content' : ''"> {{
                          comment.content }}</span> </span>
                      </div>
                    </div>

                    <div class=" com_total" v-if="hasMoreComments(formItem, formIndex)"
                      @click="showMore(formItem, formIndex)">
                      展示更多
                    </div>
                  </div>
                  <div ref="_post_comment" v-else></div>

                  <div class="tool_tab">
                    <div class="com_total">共{{ formItem.total_num }}条评论</div>

                    <div class="more_btn">
                      <van-icon name="ellipsis" size="20" @click="handleMoreTool(formItem)" />
                      <Transition name="more-tool">
                        <div class="more_tool_btn" v-show="formItem.show_tool">
                          <div class="like" @click.stop="whenTheUserLike(formItem)">
                            <div class="like_icon_wrapper">
                              <van-image class="like_icon" width="20px" height="20px" :show-loading="false"
                                :src="formItem.liked_by_me ? like_icon_active : like_icon" />
                            </div>

                            <div class="count" style="margin-left: 4px">
                              {{ formItem.likes_count }}
                            </div>
                          </div>

                          <div class="pl_btn" @click="handleReplyPost(formItem)">
                            <van-icon name="chat-o" color="#ccc" size="18" />
                            <div class="pl_text">评论</div>
                          </div>
                        </div>
                      </Transition>
                    </div>

                  </div>
                </div>


                <!-- 帖子组件 -->
                <!-- <Post :postList="tabItem.items" @handle-reply="handleReply" @handle-reply-post="handleReplyPost" /> -->

                <!-- <div class="toTop" @click="handleToTop(tabIndex)">回到顶部</div> -->
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>

  <ForumDetails v-if="store.forumDetailsShow" />

  <ReplyTool :input_tool_show="input_tool_show" :reply-item="replyItem" @handle-close-input="handleBlur"
    @handle-send-success="handleSendSuccess" />
</template>

<script setup>
import { onBeforeMount, ref, onMounted, onActivated, onDeactivated, reactive, nextTick, computed } from "vue";
import { useRouter } from "vue-router";
import { showImagePreview, showToast } from "vant";

import { showTime, parseDateStringWithMoment, getCurrentDevice } from '@/utils/utils';
import { getForumList, likePost, getShowBanner, addForumReplies } from "@/api/index";
import { gradLevelIcon } from "@/utils/levelData.js";
import { useStore } from "@/store/store";
import { useToggle } from '@vant/use';

import HeadPortrait from "@/components/HeadPortrait.vue";
import ForumDetails from "@/views/ForumDetails.vue";
import ReplyTool from "@/components/ReplyTool.vue";
import Level from "@/components/Level.vue";
import Post from "@/components/Post.vue";


import like_icon from "@/assets/images/show/like.png";
import like_icon_active from "@/assets/images/show/is_like.png";
import smile_icon from "@/assets/images/show/smile.png";
import smile_icon_active from "@/assets/images/show/is_smile.png";
import tabler_icon from "@/assets/images/show/tabler.png";
import tabler_icon_active from "@/assets/images/show/is_tabler.png";
import trophy_icon from "@/assets/images/show/trophy.png";
import trophy_icon_active from "@/assets/images/show/is_trophy.png";

import create_forum_icon from "@/assets/images/create_forum_icon.png";
import my_forums_icon from "@/assets/images/my_forums_icon.png";

const store = useStore();
const router = useRouter();
const tabData = reactive([
  {
    type: 1, //分类
    name: "极客热帖",
    items: [],
    page: 1,
    loading: false, //list
    finished: false, // list
    refreshLoading: false,
  },
  // {
  //   type: 2,
  //   name: '最新发帖',
  //   items: [],
  //   page: 1,
  //   loading: false,
  //   finished: false,
  //   refreshLoading: false,
  // },
  // {
  //   type: 3,
  //   name: '最新回复',
  //   items: [],
  //   page: 1,
  //   loading: false,
  //   finished: false,
  //   refreshLoading: false,
  // },
  // {
  //   type: 4,
  //   name: '精华官方',
  //   items: [],
  //   page: 1,
  //   loading: false,
  //   finished: false,
  //   refreshLoading: false,
  // },
  // {
  //   type: 5,
  //   name: '高光时刻',
  //   items: [],
  //   page: 1,
  //   loading: false,
  //   finished: false,
  //   refreshLoading: false,
  // },
  // {
  //   type: 6,
  //   name: '经验功略',
  //   items: [],
  //   page: 1,
  //   loading: false,
  //   finished: false,
  //   refreshLoading: false,
  // },
  // {
  //   type: 7,
  //   name: '建议反馈',
  //   items: [],
  //   page: 1,
  //   loading: false,
  //   finished: false,
  //   refreshLoading: false,
  // },
]);

const tagList = [
  {
    article_type: "3",
    name: "#高光时刻",
  },
  {
    article_type: "4",
    name: "#日常分享",
  },
  {
    article_type: "5",
    name: "#经验攻略",
  },
  {
    article_type: "6",
    name: "#建议反馈",
  },
];

const handleTag = (tagType) => {
  const res = tagList.filter((tag) => tag.article_type == tagType);
  if (res.length > 0) return res[0].name;
  return "";
};

const initListData = (dataArr) => {
  const dataClone = JSON.parse(JSON.stringify(dataArr));
  const initData = [];
  dataClone.forEach((item) => {
    const initDataObj = item;
    // initDataObj.headimgurl = `https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/${initDataObj.uid}.jpg`;

    initDataObj.images = initDataObj.images
      ? initDataObj.images.split(";")
      : "";

    initDataObj.likes_count = initDataObj.likers
      ? initDataObj.likers.length
      : 0;

    initDataObj.total_num =
      Number(initDataObj.comment_num) + Number(initDataObj.comment_replies_num); //评论总数

    initDataObj.post_timestamp = parseDateStringWithMoment(
      initDataObj.create_time
    ); //发帖时间戳

    initDataObj.create_time = showTime(
      parseDateStringWithMoment(initDataObj.create_time)
    ); //转换时间格式

    initDataObj.likers = initDataObj.likers
      ? initDataObj.likers.join("、")
      : "";

    // initData.recharge_level =

    // const _index = gradLevelIcon.findIndex((level) => {
    //   return level.total_recharge >= item.total_recharge;
    // });
    initDataObj.recharge_level = item.recharge_level;

    initDataObj.reply_content.forEach((commentItem) => {
      commentItem.childComment = handleComment(commentItem.reply_content || []); //子评论
    });

    initDataObj.allComment = handleComment(initDataObj.reply_content || []); //总评论

    initDataObj.show_tool = false;

    initDataObj.displayedCounts = 3;

    initData.push(initDataObj);
  });

  console.log('initData', initData);

  return initData;
};

// 预览
const handleImagePreview = (images, index) => {
  showImagePreview({
    images,
    startPosition: index,
  });
};

const active = ref(0);
const pageSize = ref(10);
const onLoadList = async (subTabIndex) => {
  const subTab = tabData[subTabIndex];
  subTab.loading = true;
  try {
    const res = await getForumList({
      uid: parseInt(store.userInfo.uid),
      form: "all",
      page: subTab.page,
      pageSize: pageSize.value,
      article_type: subTab.type,
    });

    subTab.loading = false;

    if (res.code == 200) {
      subTab.refreshLoading = false;
      subTab.items = subTab.items.concat(initListData(res.data).filter(item => item.article_status != 1));
      subTab.page = subTab.page + 1;

      // subTab.items.forEach((val, valIndex) => {
      //   subTab.items[valIndex].displayedCounts = 3;
      // });

      res.data.length < 10 && (subTab.finished = true);
    }
  } catch (error) {
    subTab.loading = false;
    subTab.finished = true;
  }
};

const goToUrl = (type) => {
  if (type == "create") router.push("/createForum");
  else router.push("/myForums");
};

// 用户点赞文章
const whenTheUserLike = async (formItem) => {
  if (formItem.liked_by_me) return;
  const params = {
    post_id: Number(formItem.id),
    uid: parseInt(store.userInfo.uid),
  };

  // 请求
  try {
    const res = await likePost(params);

    if (res.code == 200) {
      showToast("点赞成功");

      formItem.liked_by_me = true;

      let likers = formItem.likers;

      likers == ""
        ? (likers = store.userInfo.player_name)
        : (likers += `、${store.userInfo.player_name}`);

      formItem.likers = likers;
      formItem.likes_count += 1;
    }
  } catch (error) { }
};

//获取极客秀轮播banner
let showBannerList = ref();
const handleGetShowBanner = async () => {
  const parmas = {
    type: "jk",
  };

  const res = await getShowBanner(parmas);
  showBannerList.value = res && res.banner;
};

//跳转至帖子详情页
const handleToPostDetails = (formItem) => {
  store.$patch((state) => {
    state.post_details = formItem;
  });

  store.forumDetailsShow = true;
  // router.push({ path: '/forumDetails', query: { id: formItem.id } });
};

const onRefresh = (subTabIndex) => {
  topPostList.value = [];
  const subTab = tabData[subTabIndex];
  subTab.items = [];
  subTab.page = 1;
  subTab.finished = false;
  subTab.loading = true;

  setTimeout(() => {
    getTopTopList();
  }, 100);
  onLoadList(subTabIndex);
};

//评论回复处理
const handleComment = (treeData) => {
  let data = [];
  treeData.forEach((item) => {
    const { reply_content, ...ele } = item;
    data.push(ele);
    reply_content &&
      reply_content.length &&
      data.push(...handleComment(reply_content));
  });

  return data;
};

const handleSort = (itemArr) => {
  itemArr.sort((a, b) => b.reply_time - a.reply_time);
  return itemArr;
};

//获取置顶帖
const topPostList = ref();
const getTopTopList = async () => {
  try {
    const res = await getForumList({
      uid: parseInt(store.userInfo.uid),
      form: "all",
      page: 1,
      pageSize: 10,
      article_type: 8,
    });
    if (res.code == 200) {
      topPostList.value = initListData(res.data);
    }
  } catch (error) { }
};

// const displayedCounts = ref(store.post_details.reply_content.map(() => 3));

// 计算当前展示的数据条目  
const displayedItems = computed(() => (formItem) => {
  const sortData = handleSort(formItem.allComment);
  const start = 0;
  return sortData.slice(start, formItem.displayedCounts);
});

// 判断是否还有更多评论可以显示  
const _post_comment = ref();
const hasMoreComments = (formItem, formIndex) => {
  nextTick(() => {
    if (_post_comment.value && _post_comment.value[formIndex] && _post_comment.value[formIndex].scrollHeight) {
      const commentElement = _post_comment.value[formIndex];
      commentElement.style.height = `${commentElement.scrollHeight}px`;
    }
  });
  return formItem.displayedCounts < formItem.allComment.length;
};

// 点击按钮时展示更多数据  
const showMore = (formItem, formIndex) => {
  setTimeout(() => {
    const height = _post_comment.value[formIndex].scrollHeight;
    _post_comment.value[formIndex].style.height = height + 'px';
  }, 30);

  if (formItem.displayedCounts > 13) return handleToPostDetails(formItem);

  if (hasMoreComments(formItem)) {
    formItem.displayedCounts += 5;
  }
};

//置顶帖
const _post_top_comment = ref();
const hasMoreTopComments = (formItem, formIndex) => {
  if (_post_top_comment.value && _post_top_comment.value[formIndex] && _post_top_comment.value[formIndex].scrollHeight) {
    const commentPostElement = _post_top_comment.value[formIndex];
    commentPostElement.style.height = `${commentPostElement.scrollHeight}px`;
  }
  return formItem.displayedCounts < formItem.allComment.length;
};
// 点击按钮时展示更多数据  
const showMoreTop = (formItem, formIndex) => {
  setTimeout(() => {
    const topHeight = _post_top_comment.value[formIndex].scrollHeight;
    _post_top_comment.value[formIndex].style.height = topHeight + 'px';
  }, 30);

  if (formItem.displayedCounts > 13) return handleToPostDetails(formItem);

  if (hasMoreTopComments(formItem)) {
    formItem.displayedCounts += 5;
  }
};

const input_tool_show = ref(false);
//回复信息
const replyItem = reactive({
  reply_id: 0, //（被回复人uid） 文章直接评论则为0
  content: "", //内容
  username: "",
  post_id: "",
});

const handleBlur = (val) => {
  input_tool_show.value = val;
  if (!replyItem.content) {
    replyItem.reply_id = 0;
    replyItem.username = "";
    replyItem.post_id = "";
  }
};

const handleSendSuccess = (val) => {
  input_tool_show.value = val;
  replyItem.content = "";
  replyItem.reply_id = 0;
  replyItem.username = "";
  replyItem.post_id = "";
};

const handleReply = (comment) => {
  replyItem.reply_id = comment.id;
  replyItem.username = comment.user_name;
  replyItem.post_id = comment.post_id;
  input_tool_show.value = true;
};

const handleReplyPost = (formItem) => {
  replyItem.reply_id = 0;
  replyItem.username = "";
  replyItem.post_id = formItem.id;
  input_tool_show.value = true;
};

//回到顶部
const _vanListRef = ref();
const handleToTop = (index) => {
  _vanListRef.value[index].$el.scrollIntoView({ behavior: "smooth" });
};


const handleCapClick = () => {
  tabData[active.value].items.forEach((item) => {
    item.show_tool = false;
  });

  topPostList.value.forEach((item) => {
    item.show_tool = false;
  });
};

const handleMoreTool = (item) => {
  item.show_tool = !item.show_tool;
};

onActivated(() => {
  handleGetShowBanner();
});

onMounted(() => {
  getTopTopList();
});

onDeactivated(() => { });
</script>

<style lang="scss" scoped>
//暂时使用
:deep(.van-tabs__wrap) {
  width: 0;
  height: 0 !important;
}

:deep(.van-tabs__nav) {
  background: none;
}

:deep(.van-tabs__nav--line) {
  padding-bottom: 8px;
}

:deep(.van-tab__text) {
  color: #fff;
  font-size: 12px;
}

:deep(.van-tabs__line) {
  background: linear-gradient(45deg, #391284, #871fba);
  width: 60px;
}

:deep(.van-swipe-item) {
  width: 345px;
  height: 70px;
}

:deep(.van-cell) {
  padding: 5px 10px;

  &::after {
    border: none;
  }
}

:deep(.van-field__control) {
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #000;
  font-size: 12px;
  padding: 0 5px;
}

.forum_wrapper {
  background-color: #181627;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/forum_top_1bg.png");

  min-height: 100vh;
}

.my_nav_bar_wrapper {
  padding: 0 30px 0 15px;

  .nav_bar {
    display: flex;
    height: 34px;
    line-height: 34px;

    .nav_left_wrapper {
      width: 58px;
      font-size: 37px;
      background-image: url("@/assets/images/show/Group.png");
      background-size: 100% 100%;
    }

    .nav_text_wrapper {
      position: relative;
      flex: 1;
      font-size: 20px;

      span {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 66px;
        height: 12px;
        border-radius: 6px;
        z-index: 1;
        background: linear-gradient(270deg, #391284 0%, #871fba 100%);
      }

      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 74px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        z-index: 1;
        background: linear-gradient(270deg, #391284 0%, #871fba 100%);
      }
    }

    .nav_right_wrapper {
      width: 72px;
      display: flex;
      justify-content: space-between;
      align-content: center;

      .nav_right_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.forum_list_wrapper {
  padding: 0 15px;

  .show_swipe {
    width: 345px;
    height: 70px;
    margin: 10px 0; //暂时使用
  }

  .show_swipe_img {
    width: 345px;
    height: 70px;
    border-radius: 10px;
  }

  .tips_wrapper {
    height: 60px;

    .activity_tips {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;

      .tip_tab {
        padding: 0 5px;
        color: #8c6cf6;
        border: 1px solid #8c6cf6;
        font-size: 10px;
        border-radius: 5px;
      }

      .tip_content {
        padding-left: 10px;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 11px;
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }

  .forum_list {
    margin-top: 10px;
    height: calc(90vh - 95px); //暂时使用
    // height: calc(80vh - 121px);
    overflow: auto;

    .post_item {
      background: linear-gradient(180deg, #20192b 0%, #212040 100%);
      border-radius: 10px;
      padding: 20px 15px;
      margin-top: 10px;
      overflow: hidden;

      .author_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .author_name {
          margin: 0 5px 0 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;

          .admin_name {
            color: #fee22a;
          }
        }

        .author_level {
          display: flex;
          // flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .time {
          flex: 1;
          text-align: right;
          color: rgba(255, 255, 255, 0.5);
        }
      }

      .post_content {
        display: flex;
        padding-top: 10px;
        position: relative;
        // align-items: center;

        .con_selected {
          color: #fee22a;
          padding: 0 5px;
          border: 1px solid #fee22a;
          font-size: 10px;
          border-radius: 2px;
          position: absolute;
          margin-top: 1px;
        }

        .con_text {
          color: rgba(255, 255, 255, 0.9);
          line-height: 20px;
          font-size: 13px;

          .post_tag {
            color: #fff;
            font-size: 13px;
            color: #0070c0;
          }
        }

        .con_topPost {
          color: #ff44dd;
          padding: 0 5px;
          border: 1px solid #ff44dd;
          font-size: 10px;
          border-radius: 2px;
          position: absolute;
          margin-top: 1px;
        }

        .ti {
          text-indent: 40px;
        }

        .con_selected_topPost {
          @extend .con_selected;
          left: 40px;
        }

        .admin_ti {
          text-indent: 80px;
        }
      }

      .post_images {
        margin-top: 15px;
        display: flex;
        justify-content: start;

        .post_image_item {
          width: 31%;
          margin-right: 8px;
          height: 100px;
          background-color: #181627;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .post_comment {
        padding: 15px 20px;
        border-radius: 10px;
        background: #181627;
        margin-top: 10px;
        position: relative;

        overflow: hidden;
        transition: height .2s linear;
        transition-timing-function: ease-out;

        .post_com_item {
          .user_box {
            color: #ccc;
            padding-bottom: 5px;

            .box_text {
              color: #fff;
            }
          }

          .com_box {
            padding-bottom: 5px;

            .user_name,
            .reply_name {
              color: #93f6fc;
            }

            .reply_tag {
              color: #ccc;
              padding: 0 5px;
            }

            .box_text {
              color: #fff;
              padding-left: 5px;

              .admin_content {
                color: #FEE22A;
              }
            }
          }

          .moreComment {
            padding: 5px 0 5px 35px;
            color: #93f6fc;
            font-size: 10px;
          }
        }

        .com_total {
          bottom: 15px;
          font-size: 10px;
          color: rgba(255, 255, 255, 0.8);
        }
      }

      .tool_tab {
        // padding: 10px 10px 0;
        padding-top: 10px;
        display: flex;
        // justify-content: space-evenly;
        justify-content: space-between;
        align-items: center;

        .tool_tab_item {
          display: flex;
          align-items: center;

          .like {
            font-size: 12px;
            display: flex;
            padding: 0 5px;

            .like_icon_wrapper {
              width: 22px;
              position: relative;

              .like_icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          .post_review {
            padding: 0 5px;
          }
        }

        .com_total {
          color: rgba(255, 255, 255, 0.8);
        }

        .time {
          color: rgba(255, 255, 255, 0.5);
        }

        .more_btn {
          display: flex;
          align-items: center;
          position: relative;

          .more_tool_btn {
            width: 130px;
            height: 30px;
            position: absolute;
            right: 25px;
            top: -5px;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 5px;

            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .like {
              font-size: 12px;
              display: flex;
              padding: 0 5px;

              .like_icon_wrapper {
                width: 22px;
                position: relative;

                .like_icon {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .pl_btn {
              display: flex;
              align-items: center;

              .pl_text {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.toTop {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-size: 10px;
  position: fixed;
  bottom: 100px;
  right: 0;
}

//展开点赞评论
.more-tool-enter-active {
  transition: all 0.3s ease-out;
}

.more-tool-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.more-tool-enter-from,
.more-tool-leave-to {
  transform: translateX(5px);
  opacity: 0;
}
</style>
