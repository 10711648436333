<template>
  <div class="seats_wrapper">
    <div
      class="seat_item"
      v-for="playItem in store.current_machine.device_info"
    >
      <HeadPortrait
        v-if="
          playItem.occupy &&
          playItem.occupy.player_id &&
          playItem.occupy.player_id != ''
        "
        width="1rem"
        height="1rem"
        style="margin: 0 auto"
        :ae_id="playItem.occupy.select_title"
        ae
        fe
        :level="playItem.occupy.recharge_level"
        :src="
          playItem.occupy.head
            ? playItem.occupy.head
            : playItem.occupy.head_frame
        "
      />
      <div
        v-else
        class="w-100 h-38 m-auto van-haptics-feedback"
        @click="handleSitDown(playItem)"
      >
        <van-image
          :src="sitDownPlayBtn"
          width="100%"
          :show-loading="false"
          height="100%"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted, onBeforeUnmount, ref } from "vue";
import { useRouter } from "vue-router";

import { useStore } from "@/store/store";
import { showLoadingToast, showToast, closeToast } from "vant";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import HeadPortrait from "@/components/HeadPortrait.vue";

const emits = defineEmits(["callback"]);

const store = useStore();
const sitDownPlayBtn = store.imgBaseUrl + "/game/sitDownPlayBtn.png";

let sit_flag = false;

const handleSitDown = async (device) => {
  if (sit_flag) return;
  sit_flag = true;
  setTimeout(() => {
    sit_flag = false;
  }, 1500);

  // 币量不足够坐下
  if (Number(store.userInfo.gold) < Number(store.current_machine.site_limit)) {
    showToast({
      type: "fail",
      message: "币量不足",
      teleport: "#rotate_box",
      onClose() {
        store.payPupToggle(true);
      },
    });
    return;
  }

  // 位置有人
  if (device.occupy && device.occupy.player_id) {
    showToast({
      type: "fail",
      message: "位置有人",
      teleport: "#rotate_box",
    });
    return;
  }

  // 设备维护
  if (device.status == 0) {
    showToast({
      type: "fail",
      message: "机位维护中",
      teleport: "#rotate_box",
    });
    return;
  }
  showLoadingToast({
    forbidClick: true,
    duration: 0,
  });

  // 先清除基本状态
  store.$patch((state) => {
    state.current_device = device;
  });

  const leave_machineparams = {
    msg_type: "leave_machine",
    game_name: store.current_machine.product_name,
    machine_index: store.current_machine.machine_index,
  };
  sendInstructions(leave_machineparams, 0);
  // 发送坐下协议
  const params = {
    msg_type: "enter_game",
    game_name: store.current_machine.product_name,
    machine_index: store.current_machine.machine_index,
    device_name: device.device_name,
    is_tiaozhan: false,
  };

  const msg = {
    "device occupy": "座位被占用",
    "site limit": "入座金币不足",
    "web device up coin error": "设备扣费失败",
    "no find device": "没有找到此设备",
    "tiaozhan limit": "挑战配置错误",
    "tiaozhan not exists": "未找到挑战信息",
    "tiaozhan type error": "挑战配置不一致",
    "tiaozhan start true": "当前已有挑战",
    "tiaozhan info not exists": "挑战信息不存在",
  };

  try {
    const res = await asyncSendInstructions(params);

    if (res.error == "ok") {
      store.$patch((state) => {
        state.gameIsWatch = false;
        state.userInfo.gold =
          Number(state.userInfo.gold) - Number(res.dec_gold);
      });

      emits("callback");
    } else {
      showToast({
        type: "fail",
        message: msg[res.error] || "未知错误" + res.error,
        teleport: "#rotate_box",
      });
    }
  } catch (error) {
    // 处理错误
    console.log(error);
  }
  closeToast();
  sendInstructions(leave_machineparams, 50);
};
</script>

<style lang="scss" scoped>
.seats_wrapper {
  position: absolute;
  background-color: transparent;
  z-index: 4;
  height: 40px;
  display: flex;
  width: 100%;
  left: 0;
  bottom: 80px;
  .seat_item {
    flex: 1;
  }
}
</style>
