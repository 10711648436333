const getTitleBaseUrl = (type, index, imgtype = "png") => {
  return `https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/${type}_${index}.${imgtype}`;
};

const AeList = {
  1: {
    t_name: "疯魔爆机王",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "魔鬼城赛季内爆机",
    gold: 0,
  },
  2: {
    t_name: "海王爆机王",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "海王赛季内爆机",
    gold: 0,
  },
  3: {
    t_name: "我是传奇",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "爆爆乐赛季内获得传奇",
    gold: 0,
  },
  4: {
    t_name: "全盘王",
    complete: 10,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "推币机赛季内获得全盘",
    gold: 0,
  },
  5: {
    t_name: "全能大神",
    complete: 4,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "获得四种游戏的战士成就",
    gold: 0,
  },
  6: {
    t_name: "挑战达人",
    complete: 2,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "赛季内获得两次挑战周冠军",
    gold: 0,
  },
  7: {
    t_name: "联赛冠军",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "赛季内获得联赛冠军",
    gold: 0,
  },
  8: {
    t_name: "联赛亚军",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "赛季内获得联赛亚军",
    gold: 0,
  },
  9: {
    t_name: "联赛季军",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "赛季内获得联赛季军",
    gold: 0,
  },
  10: {
    t_name: "龙鱼情缘-鱼",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "七夕活动获得",
    gold: 0,
  },
  11: {
    t_name: "龙鱼情缘-龙",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "七夕活动获得",
    gold: 0,
  },
  12: {
    t_name: "中国心",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "开宝箱获得",
    gold: 0,
  },
  13: {
    t_name: "战士",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "开宝箱获得",
    gold: 0,
  },
  14: {
    t_name: "高手",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "开宝箱获得",
    gold: 0,
  },
  15: {
    t_name: "王者",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "开宝箱获得",
    gold: 0,
  },
  16: {
    t_name: "小霸王",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "新人任务获得",
    gold: 0,
  },
  17: {
    t_name: "疯魔爆机战士",
    complete: 99,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "魔鬼城赛季内获得爆机",
    gold: 0,
  },
  18: {
    t_name: "疯魔爆机高手",
    complete: 99,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "魔鬼城赛季内获得爆机",
    gold: 0,
  },
  19: {
    t_name: "疯魔爆机达人",
    complete: 99,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "魔鬼城赛季内获得爆机",
    gold: 0,
  },
  20: {
    t_name: "海王爆机战士",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "海王赛季内爆机",
    gold: 0,
  },
  21: {
    t_name: "海王爆机高手",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "海王赛季内爆机",
    gold: 0,
  },
  22: {
    t_name: "海王爆机达人",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "海王赛季内爆机",
    gold: 0,
  },
  23: {
    t_name: "传奇战士",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "爆爆乐赛季内获得传奇",
    gold: 0,
  },
  24: {
    t_name: "传奇高手",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "爆爆乐赛季内获得传奇",
    gold: 0,
  },
  25: {
    t_name: "传奇达人",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "爆爆乐赛季内获得传奇",
    gold: 0,
  },
  26: {
    t_name: "全盘战士",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "推币机赛季内获得全盘",
    gold: 0,
  },
  27: {
    t_name: "全盘高手",
    complete: 99,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "推币机赛季内获得全盘",
    gold: 0,
  },
  28: {
    t_name: "全盘达人",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "推币机赛季内获得全盘",
    gold: 0,
  },
  29: {
    t_name: "龙族王者",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "龙珠商店获得",
    gold: 0,
  },
  30: {
    t_name: "龙族战士",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "龙珠商店获得",
    gold: 0,
  },
  31: {
    t_name: "幻影死神",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "万圣节活动获得",
    gold: 0,
  },
  32: {
    t_name: "南瓜猎手",
    complete: 99,
    num: 4,
    s_url: "",
    h_url: "",
    complete_text: "万圣节活动获得",
    gold: 0,
  },
  98: {
    t_name: "大师",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "达成大师级别",
    gold: 0,
  },
  99: {
    t_name: "宗师",
    complete: 1,
    num: 0,
    s_url: "",
    h_url: "",
    complete_text: "达成宗师级别",
    gold: 0,
  },
};
const gifTypeIds = [29];
for (const key in AeList) {
  const item = AeList[key];
  let imgtype = "png";
  if (gifTypeIds.includes(Number(key))) imgtype = "gif";
  item.s_url = getTitleBaseUrl("s", key);
  item.h_url = getTitleBaseUrl("h", key, imgtype);
}

export default AeList;
