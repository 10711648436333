<template>
  <div class="challengeview">
    <!-- 说明 -->
    <div class="explain_icon" @click="lookRuleShowToggle(true)"></div>
    <div
      class="rank_icon"
      @click="lookRankIconClick"
      v-show="cur_type == 1"
    ></div>
    <div class="my_tiaozhan_ticket" @click="payCardPupShowToggle(true)">
      <div class="value">
        {{ store.userInfo.tiaozhan_ticket }}
      </div>
    </div>

    <div class="type_tab_wrapper">
      <div class="type_tab">
        <div class="type_tab_item" @click="typeTabToggle(0)">
          <van-image
            v-show="cur_type == 0"
            width="100%"
            height="auto"
            block
            :src="jifen_type_active"
            :show-loading="false"
          />
          <van-image
            v-show="cur_type != 0"
            width="100%"
            height="auto"
            block
            :src="jifen_type"
            :show-loading="false"
          />
        </div>

        <div class="type_tab_item" @click="typeTabToggle(1)">
          <van-image
            v-show="cur_type == 1"
            width="100%"
            height="auto"
            :src="normal_type_active"
            :show-loading="false"
            block
          />
          <van-image
            v-show="cur_type != 1"
            width="100%"
            height="auto"
            :src="normal_type"
            :show-loading="false"
            block
          />
        </div>
      </div>
    </div>

    <div class="challenge_list_wrapper">
      <swiper :auto-height="true" :no-swiping="true" @swiper="onSwiper">
        <!-- 积分挑战赛 -->
        <swiper-slide class="swiper-no-swiping">
          <div class="jifen_list_wrapper">
            <div class="active_item" v-for="activeItem in jifenListData">
              <div class="active_title">
                <h5 class="title">{{ activeItem.title }}</h5>
                <div class="active_status_wrapper">
                  <div class="active_end_time" v-if="activeItem.statu == 1">
                    结束时间&nbsp;
                    <span style="color: #fee22a">{{
                      activeItem.end_time
                    }}</span>
                  </div>
                  <div class="active_statu" v-if="activeItem.statu == 0">
                    {{ activeItem.start_time }} &nbsp;开始
                  </div>
                  <div
                    class="active_statu"
                    v-if="activeItem.statu == 2 || activeItem.statu == 3"
                  >
                    比赛已结束
                  </div>

                  <div class="match_time">
                    单次挑战&nbsp;<span style="color: #fff; font-weight: bold"
                      >{{ activeItem.match_time }}分钟</span
                    >
                  </div>
                  <div class="join_count">
                    可挑战次数：&nbsp;<span
                      style="color: #fff; font-weight: bold"
                      v-if="activeItem.enter_num_limit > 0"
                      >{{ activeItem.enter_num_limit }}次</span
                    >
                    <span
                      style="color: #fff; font-weight: bold"
                      v-if="activeItem.enter_num_limit <= 0"
                      >不限</span
                    >
                  </div>
                </div>

                <div
                  class="active_statu_1_icon"
                  v-if="activeItem.statu == 1"
                ></div>
                <div
                  class="active_statu_2_icon"
                  v-if="activeItem.statu == 2"
                ></div>
                <div
                  class="active_statu_3_icon"
                  v-if="activeItem.statu == 3"
                ></div>
                <div
                  class="active_statu_0_icon"
                  v-if="activeItem.statu == 0"
                ></div>
              </div>
              <div class="active_container">
                <div class="active_info">
                  <div class="active_index">
                    <div class="active_cover">
                      <van-image
                        width="100%"
                        height="auto"
                        fit="fill"
                        position="center"
                        :src="activeCover[activeItem.game_name]"
                        :show-loading="false"
                      />
                    </div>
                    <div class="active_index_name">
                      {{ activeItem.exchange }}币房
                    </div>
                  </div>
                  <div class="active_details">
                    <div class="details_row">
                      <div class="row_item">
                        <span>报名费&nbsp;{{ activeItem.sign_up_gold }}</span>
                        <div class="gold_icon"></div>
                      </div>
                      <div class="row_item" v-if="activeItem.ticket > 0">
                        <span>门票：{{ activeItem.ticket }}张</span>
                      </div>
                    </div>
                    <div class="details_row">
                      <div class="row_item">
                        <span>携带金币：</span>
                        <template v-for="_golds in activeItem.gold_select_list">
                          <span>{{ _golds.gold }}</span>
                          <div class="gold_icon"></div>
                        </template>
                      </div>
                    </div>
                    <div class="details_row">
                      <div class="row_item">
                        <span>参加人数&nbsp;</span>
                        <span v-if="activeItem.join_num_limit > 0">
                          {{
                            `${activeItem.join_num}/${activeItem.join_num_limit}`
                          }}</span
                        >
                        <span v-else>{{ activeItem.join_num }}</span>
                      </div>
                      <div class="row_item" v-if="activeItem.level_limit > 1">
                        <span>限制等级&nbsp;</span>
                        <span> {{ activeItem.level_name }}</span>
                      </div>
                    </div>
                    <div class="details_row">
                      <div
                        class="look_rank_btn"
                        @click="lookActiveRankPupToggle(activeItem)"
                      >
                        查看排名
                      </div>
                    </div>
                  </div>
                </div>
                <div class="apply_active_wrapper">
                  <div
                    class="apply_active_btn"
                    v-if="activeItem.statu == 1"
                    @click="applyBtnEvent(activeItem)"
                  ></div>
                  <div
                    class="apply_look_rank_btn"
                    v-else-if="activeItem.statu == 2"
                  ></div>
                  <div
                    class="apply_c_rank_btn"
                    v-else-if="activeItem.statu == 3"
                  ></div>
                  <div
                    class="apply_b_rank_btn"
                    v-else-if="activeItem.statu == 0"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!-- 常规挑战赛 -->
        <swiper-slide class="swiper-no-swiping">
          <div class="normal_list_wrapper">
            <!-- 我的战绩 -->
            <div class="my_standings_wrapper">
              <div class="my_cards_wrapper">
                <div class="my_card_item">
                  <van-image
                    width="100%"
                    height="100%"
                    :show-loading="false"
                    :src="dashi_icon"
                  />
                  <div class="no_card_mask" v-if="!store.userInfo.dashi_card">
                    未点亮
                  </div>
                </div>
                <div class="my_card_item">
                  <van-image
                    width="100%"
                    height="100%"
                    :show-loading="false"
                    :src="zongshi_icon"
                  />
                  <div class="no_card_mask" v-if="!store.userInfo.zongshi_card">
                    未点亮
                  </div>
                </div>
              </div>

              <div class="my_standings">
                <div class="top_title">
                  <div class="top_text">标题</div>

                  <div class="top_title_list">
                    <div class="top_title_item">我的大师分</div>
                    <div class="top_title_item">挑战次数</div>
                    <div class="top_title_item">成功次数</div>
                    <div class="top_title_item">奖励</div>
                  </div>
                </div>
                <div class="bottom_value">
                  <div class="bottom_item">
                    <div class="bottom_text">本周</div>
                    <div class="bottom_value_list">
                      <div class="bottom_value_item">
                        {{ myStandingsData.xunzhang }}
                      </div>
                      <div class="bottom_value_item">
                        {{ myStandingsData.tiaozhan_num }}次
                      </div>
                      <div class="bottom_value_item">
                        {{ myStandingsData.success_num }}次
                      </div>
                      <div class="bottom_value_item">
                        {{ myStandingsData.reward }}
                      </div>
                    </div>
                  </div>
                  <div class="bottom_item">
                    <div class="bottom_text">累计</div>
                    <div
                      class="bottom_value_list"
                      :style="{
                        backgroundImage: `url(${total_bg})`,
                      }"
                    >
                      <div class="bottom_value_item">
                        {{ myStandingsData.total_xunzhang }}
                      </div>
                      <div class="bottom_value_item">
                        {{
                          myStandingsData.total_tiaozhan_num ??
                          myStandingsData.totoal_tiaozhan_num
                        }}次
                      </div>
                      <div class="bottom_value_item">
                        {{ myStandingsData.total_success_num }}次
                      </div>
                      <div class="bottom_value_item">
                        {{ myStandingsData.total_reward }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 列表 -->
            <div class="normal_list">
              <div class="active_item" v-for="activeItem in normalListData">
                <div class="active_title">
                  <h5 class="title">{{ activeItem.title }}</h5>
                  <div class="active_status_wrapper">
                    <div class="match_time" style="text-align: left">
                      挑战目标 &nbsp;<span
                        style="color: #fff; font-weight: bold"
                        >{{ activeItem.match_time }}分钟内达成
                        {{ activeItem.complete }} 彩券</span
                      >
                    </div>
                    <div class="join_count" style="text-align: left">
                      可挑战次数：&nbsp;<span
                        style="color: #fff; font-weight: bold"
                        v-if="activeItem.enter_num_limit > 0"
                        >{{ activeItem.enter_num_limit }}次</span
                      >
                      <span
                        style="color: #fff; font-weight: bold"
                        v-if="activeItem.enter_num_limit <= 0"
                        >不限</span
                      >
                    </div>
                  </div>

                  <div
                    class="active_statu_4_icon"
                    v-if="activeItem.active_type == 'normal'"
                  ></div>
                  <div
                    class="active_statu_5_icon"
                    v-if="activeItem.active_type == 'dashi'"
                  ></div>
                  <div
                    class="active_statu_6_icon"
                    v-if="activeItem.active_type == 'zongshi'"
                  ></div>
                </div>
                <div class="active_container">
                  <div class="active_info">
                    <div class="active_index">
                      <div class="active_cover">
                        <van-image
                          width="100%"
                          height="auto"
                          fit="fill"
                          position="center"
                          :src="activeCover[activeItem.game_name]"
                        />
                      </div>
                      <div class="active_index_name">
                        {{ activeItem.exchange }}币房
                      </div>
                    </div>
                    <div class="active_details">
                      <div class="details_row">
                        <div class="row_item">
                          <span>门票：{{ activeItem.ticket }} 张挑战卡</span>
                        </div>

                        <div
                          class="row_item"
                          style="flex: 1; justify-content: center"
                          v-if="activeItem.level_limit > 1"
                        >
                          <span>等级:&nbsp;</span>
                          <span> {{ activeItem.level_name }}</span>
                        </div>
                      </div>
                      <div class="details_row">
                        <div class="row_item">
                          <span>携带金币：</span>
                          <template
                            v-for="_golds in activeItem.gold_select_list"
                          >
                            <span>{{ _golds.gold }}</span>
                            <div class="gold_icon"></div>
                          </template>
                        </div>
                      </div>
                      <div class="details_row">
                        <div class="row_item">
                          <span>挑战成功奖励:&nbsp;</span>
                        </div>
                      </div>
                      <div class="details_row">
                        <div class="row_reward_item">
                          <div
                            class="gold_icon"
                            style="width: 22px; height: 22px"
                          ></div>
                          <span>{{ activeItem.tiaozhan_reward }}</span>
                        </div>
                        <div
                          class="row_reward_item"
                          v-if="activeItem.active_type == 'dashi'"
                        >
                          <div
                            class="dashicard_active"
                            style="width: 22px; height: 22px"
                          ></div>
                          <span>大师证</span>
                        </div>
                        <div
                          class="row_reward_item"
                          v-if="activeItem.active_type == 'zongshi'"
                        >
                          <div
                            class="zongshi_active"
                            style="width: 22px; height: 22px"
                          ></div>
                          <span>宗师证</span>
                        </div>
                        <div class="row_reward_item">
                          <div
                            class="dashi_icon"
                            style="width: 22px; height: 22px"
                          ></div>
                          <span>{{ activeItem.ticket }}大师分</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="apply_active_wrapper">
                    <div
                      class="apply_active_btn"
                      v-if="activeItem.statu == 1"
                      @click="applyBtnEvent(activeItem)"
                    ></div>
                    <div
                      class="apply_look_rank_btn"
                      v-else-if="activeItem.statu == 2"
                    ></div>
                    <div
                      class="apply_c_rank_btn"
                      v-else-if="activeItem.statu == 3"
                    ></div>
                    <div
                      class="apply_b_rank_btn"
                      v-else-if="activeItem.statu == 0"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <!-- 参加活动比赛确认弹窗 -->
    <van-popup
      v-model:show="confirmApplyShow"
      :close-on-click-overlay="false"
      style="border-radius: 20px; overflow: hidden"
    >
      <div class="challenge-recognition">
        <p class="f-24 t-center">确认参赛</p>

        <p class="f-12 mt-8">携带入座费：</p>

        <div class="flex width mt-8">
          <div
            :class="[
              'flex-1',
              'mr-5',
              't-center',
              'gold-level-item',
              cur_active_sign_gold_index == btnIndex
                ? 'gold-level-item-active'
                : '',
            ]"
            v-for="(btnItem, btnIndex) in curApplyActiveData.gold_select_list"
            :key="btnItem.sign_num"
            @click="activeGoldSelectChange(btnIndex)"
          >
            <i class="icon_jinbi"></i>
            <span class="pl-4">{{ btnItem.gold }}</span>
          </div>
        </div>

        <div class="mt-20">
          <van-button
            style="color: #221c2b; border-radius: 6px; font-weight: bold"
            block
            color="linear-gradient(to bottom, #f0d551, #ff7f00)"
            @click="applyActiveStart"
          >
            开始比赛
          </van-button>
        </div>

        <i class="pup-close-icon" @click="confirmApplyShow = false"></i>
      </div>
    </van-popup>

    <!-- 普通挑战排名 -->
    <van-popup
      v-model:show="weekRankListShow"
      round
      position="bottom"
      :style="{
        paddingTop: '50px',
        background: 'none',
        minHeight: '40%',
      }"
      :close-icon="pup_close"
      closeable
      safe-area-inset-bottom
    >
      <div class="rank-pup-wrapper">
        <div class="rank_type_tab_wrapper">
          <div class="rank_type_tab_box">
            <div class="rank_tab_active_bg" ref="rank_tab_active_bg"></div>
            <div class="rank_type_tab">
              <div
                :class="[
                  'rank_type_tab_item',
                  curRankType == tabTypeItem.key && 'rank_type_tab_item_active',
                ]"
                v-for="tabTypeItem in rank_type_list"
                :key="tabTypeItem.id"
                @click="getChallengeRankListData(tabTypeItem.key)"
              >
                {{ tabTypeItem.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="pl-20 pr-20">
          <div class="rank_list_content">
            <div class="rank_list">
              <div
                :class="[
                  'rank_list_item',
                  rankIndex == 0 && 'first_item',
                  rankIndex == 1 && 'two_item',
                  rankIndex == 2 && 'three_item',
                  rankIndex > 2 && rankIndex % 2 == 0 ? 'odd_item' : 'eve_item',
                ]"
                v-for="(rankItem, rankIndex) in challengeRankListData"
                :key="'uid_' + rankItem.uid"
              >
                <div class="ranking">
                  <div class="ranking_text">
                    {{ rankIndex + 1 }}
                  </div>
                </div>
                <div class="rank_info">
                  <div class="w-40 h-40">
                    <HeadPortrait
                      :level="Number(rankItem.recharge_level)"
                      :width="'100%'"
                      :height="'100%'"
                      :src="
                        rankItem.headimgurl
                          ? rankItem.headimgurl
                          : rankItem.head
                          ? rankItem.head
                          : rankItem.head_frame
                      "
                      :ae_id="rankItem.select_title"
                      ae
                      fe
                    />
                  </div>
                  <div class="f-12 pl-10 flex-1">
                    <p class="player_name">
                      {{ decodeBase64String(rankItem.player_name) }}
                    </p>

                    <Level
                      :border="false"
                      background="rgba(255,255,255,0.2)"
                      :level_value="rankItem.level ? Number(rankItem.level) : 1"
                    />
                  </div>
                </div>
                <div class="rank_wrapper">
                  <div class="rank_number">大师分：{{ rankItem.xunzhang }}</div>
                  <div
                    class="rank_item_rewrad"
                    v-if="rankRewardList[rankIndex]"
                  >
                    奖励+{{ rankRewardList[rankIndex] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 规则说明 -->
    <van-popup v-model:show="ruleShow" style="background: none">
      <div class="rule_wrapper">
        <div class="close_icon" @click="lookRuleShowToggle(false)"></div>
        <div class="rule_title">比赛规则</div>
        <div class="rule_content" v-show="cur_type == 0">
          一、报名 <br />
          1、积分赛会根据不同的用户等级举办不同的赛事活动。不同的赛事活动，报名参赛的要求和比赛奖励有所不同。比赛包括但不限于免费报名和金币报名等报名方式，具体随具体比赛的规模而设定。<br />
          2、一旦报名参赛完成，即刻随机分配玩家到比赛房间的随机位置，开始进行比赛。<br /><br />
          二、比赛规则及结算标准<br />
          1、比赛会对两项数据进行统计，即天梯赛的积分制规则和招魂谷的特殊怪的击杀规则来决定比赛的结果。比赛积分加招魂谷的击杀积分的总和为积分赛的最终比赛积分，并且以此积分为最终依据进行排名。如果赛事积分和击杀积分完全相同，则以报名的先后顺序决定比赛的前后名次。<br />
          2、比赛的结算，参赛名额满员，并且所有参赛玩家比赛结束，则比赛结束，产生最终的比赛排名数据，玩家可根据对应的比赛实时榜单查看。如果在比赛开放的时间内，参赛人员未满，则在比赛的截止时间进行结算。<br />
          3、奖励的发放，比赛结算完成后，获得奖励的用户可以在我的-奖项领取中领取比赛的奖励。并且可在我的赛事中查看到我的奖项记录。<br />
          <br />比赛小窍门：携带进入比赛的金币越多，机会越多哦！<br />

          <br />
          招魂谷击杀积分说明 <br />
          千年南瓜怪 +20 特殊怪积分<br />
          系统爆机 +100 特殊怪积分<br />
          修罗巨魔 +10 特殊怪积分<br />
          巨石炎魔 +10 特殊怪积分<br />
          幻影死神 +10 特殊怪积分<br />
          狂暴飞龙 +10 特殊怪积分<br />
          驱魔烈焰 +10 特殊怪积分<br />
          骷髅暴君 +10 特殊怪积分<br />
          魔旋弹 +10 特殊怪积分<br />
          锁链封印 +5 特殊怪积分<br />
          连环爆裂 +5 特殊怪积分<br />
          电光破 +5 特殊怪积分<br />
          魔法召唤 +5 特殊怪积分<br />

          <br />
          <!-- <span style="font-size: 14px; color: #ffae00"
            >说明：该赛事由极客竞技举办，并拥有最终解释权，与苹果公司无关。</span
          > -->
          <br />
          <br />
        </div>
        <div class="rule_content" v-show="cur_type == 1">
          一、报名/结算规则 <br />
          1、报名对应挑战赛会消耗相应的挑战卡。<br />
          2、玩家可携带初始入座金币来参加挑战 (挑战中途不可投币)<br />
          3、玩家以最后结算的彩券数量来决定是否挑战成功，挑战时间截止以及提前结算，以结算时的彩券数量为准<br />
          4、挑战成功后的奖励将直接加入账户<br /><br />

          二、挑战卡获取方式 <br />
          1、购买对应礼包赠送获得<br />
          2、日常活动以及后续各种节日福利<br />
          3、直接在挑战页面购买<br /><br />

          三、大师分的获得<br />
          1、挑战成功就可以获得大师分，获得大师分的分数和挑战时消耗的挑战卡一样。<br />
          比如：消耗量3张挑战卡的挑战，成功了就获得3分大师分。<br />
          2、大师卡标志，天梯等级达到Lv-9后,赛季时间内拥有大师卡的玩家才能继续升级获得对应称号及头像框。<br />
          3、宗师卡标志，天梯等级达到大师后,赛季时间内拥有宗师卡的玩家才能继续升级获得对应称号及头像框。<br /><br />

          四、排行榜<br />
          统计时间为 周一 00:01 到 周日的 23:59分 为排行榜的时间范围。<br />
          周一 00:00 结算大师分排行榜前10名,奖励自动发放到我的奖励中领取。<br />

          <br />比赛小窍门：携带进入比赛的金币越多，机会越多哦！<br />
          <br />
          <!-- <span style="font-size: 14px; color: #ffae00"
            >说明：该赛事由极客竞技举办，并拥有最终解释权，与苹果公司无关。</span
          > -->
          <br />
          <br />
        </div>
      </div>
    </van-popup>
    <!-- 购买挑战卡 -->
    <van-popup v-model:show="payCardPupShow" style="background: none" round>
      <div class="pay_card_wrapper">
        <div
          class="close_pay_card_icon"
          @click="payCardPupShowToggle(false)"
        ></div>
        <div class="pay_card_title">购买挑战卡</div>
        <div class="pay_card_list">
          <div
            :class="[
              'pay_card_item',
              payCardIndex == payCardItem.payIndex && 'pay_card_item_active',
            ]"
            v-for="payCardItem in store.initPayPupData.tz_card"
            @click="payCardIndexChange(payCardItem.payIndex)"
          >
            <div class="card_number_wrapper">
              <div class="card_icon"></div>
              <div class="card_number">x{{ payCardItem.num }}</div>
            </div>
            <div class="card_price">￥{{ payCardItem.rmb }}</div>
          </div>
        </div>
        <div class="pay_card_btn" @click="handlePayCardClick">购买</div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store/store";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import { decodeBase64String, formatTimestamp } from "@/utils/utils";
import { showToast, closeToast } from "vant";
import { gradLevelIcon } from "@/utils/levelData";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import HeadPortrait from "@/components/HeadPortrait.vue";

import Level from "@/components/Level.vue";
// const dashicard =
//   "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/dashicard.png";
// const dashicard_active =
//   "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/dashicard_active.png";
// const zongshicard =
//   "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/zongshicard.png";
// const zongshicard_active =
//   "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/zongshicard_active.png";
const total_bg =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/total_bg.png";

const dashi_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/dashi_icon.png";
const zongshi_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/zongshi_icon.png";

const store = useStore();
const router = useRouter();
const route = useRoute();

const pup_close =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png";
const normal_type = store.imgBaseUrl + "/challenge/normal_type.png";
const normal_type_active =
  store.imgBaseUrl + "/challenge/normal_type_active.png";

const jifen_type = store.imgBaseUrl + "/challenge/jifen_type.png";
const jifen_type_active = store.imgBaseUrl + "/challenge/jifen_type_active.png";

import bbl_active_cover from "@/assets/images/bbl_active_cover.png";
import mgc_active_cover from "@/assets/images/mgc_active_cover.png";
import hw_active_cover from "@/assets/images/hw_active_cover.png";
import { getTianti, getTiaozhan } from "@/api";

const activeCover = {
  bbl: bbl_active_cover,
  mgc: mgc_active_cover,
  hw3: hw_active_cover,
  mxt: hw_active_cover,
  zww: hw_active_cover,
};

//#region tab

let _Swiper = null;
const onSwiper = (swiper) => {
  _Swiper = swiper;
};

const swiper_navigation_control_to = (index) => {
  if (_Swiper == null) return;
  _Swiper.slideTo(index, 500, false);
};

const cur_type = ref(0); //   0积分挑战赛 1 常规挑战赛

// console.log("route.queryroute.query", );

const typeTabToggle = (type = 0) => {
  cur_type.value = type;
  nextTick(() => {
    swiper_navigation_control_to(type);
  });

  if (normalListData.value.length == 0 || jifenListData.value.length == 0)
    getChallengeConfig();
};

//#endregion

//#region config
const normalListData = ref([]);
const jifenListData = ref([]);

const initConfigData = (res) => {
  if (!Array.isArray(res) || res.length == 0) return;

  const nrm = [];
  const jf = [];

  res.forEach((item) => {
    const initActiveConfigObj = {
      title: "魔鬼城3000金币积分挑战赛",
      statu: 1,
      gold_select_list: [
        // {
        //   gold: 100,
        //   sign_index: 1,
        //   sign_num: 1,
        // },
      ],
      exchange: item.config.exchange,
      join_num_limit: item.config.join_num_limit,
      join_num: item.join_num,
      enter_num_limit: item.config.enter_num_limit,
      match_time: Number(item.config.match_time) / 60,
      is_up_coin: item.config.is_up_coin,
      end_time: "03-05 17:00",
      start_time: "03-05 17:00",
      active_index: item.active_index,
      game_name: item.game_name,
      level_name:
        item.config.level_limit > 0
          ? gradLevelIcon[Number(item.config.level_limit)].level_text
          : "",
      level_limit: item.config.level_limit,
      sign_up_gold: 0,
      ticket: 0,
      reward_data_list: [],
      active_type: item.active_type,
      rank_num: item.config.rank_num,
      complete: 0, // 普通挑战的目标
      tiaozhan_reward: 500, // 普通挑战成功的奖励
    };

    // 默认是开启的
    initActiveConfigObj.statu = 1;

    if (item.status == "waitting") initActiveConfigObj.statu = 0;
    // 等待结算
    if (item.status == "wait_settle_accounts") initActiveConfigObj.statu = 3;

    // 已经结算完毕 完全结束了
    if (item.status == "ending") initActiveConfigObj.statu = 2;

    const sign_up_gold = item.config.sign_up_info?.sign_up_gold ?? 0;
    const ticket = item.config.sign_up_info?.ticket ?? 0;

    if (sign_up_gold > 0) initActiveConfigObj.sign_up_gold = sign_up_gold;
    if (ticket > 0) initActiveConfigObj.ticket = ticket;
    // 入座携带费
    if (item.config.sign_up_info) {
      const sign_index_datas = Object.keys(item.config.sign_up_info.site_gold);
      sign_index_datas.forEach((signIndex) => {
        initActiveConfigObj.gold_select_list.push({
          ...item.config.sign_up_info.site_gold[signIndex],
          sign_index: Number(signIndex),
        });
      });
    }

    // 结束时间
    initActiveConfigObj.end_time = formatTimestamp(
      item.config.end_time,
      "mm-dd hh:mi:ss"
    );
    // 开始时间
    initActiveConfigObj.start_time = formatTimestamp(
      item.config.start_time,
      "mm-dd hh:mi:ss"
    );

    // 奖励列表
    const reward_data_list = [];
    if (item.config.reward_data) {
      const reward_data_keys = Object.keys(item.config.reward_data);

      reward_data_keys.forEach((rewardItem) => {
        const obj = {
          ...item.config.reward_data[rewardItem],
          reward_index: rewardItem,
        };

        if (obj.reward_type == "gift")
          obj.reward.name = decodeBase64String(obj.reward.name);
        reward_data_list.push(obj);
      });
    }

    initActiveConfigObj.title = decodeBase64String(item.config.active_name);
    initActiveConfigObj.reward_data_list = reward_data_list;

    // 常规挑战赛
    if (item.active_type == "tiaozhan_tz") {
      initActiveConfigObj.active_type = item.config.tiaozhan_tz;

      initActiveConfigObj.complete = item.config.complete;
      initActiveConfigObj.tiaozhan_reward = item.config.tiaozhan_reward;

      nrm.push(initActiveConfigObj);
    } else if (item.active_type == "lottery_tz") {
      // 积分挑战赛
      jf.push(initActiveConfigObj);
    }
  });
  normalListData.value = nrm;
  jifenListData.value = jf;

  nextTick(() => {
    _Swiper && _Swiper.update();
  });
};
const getChallengeConfig = async () => {
  showToast({
    type: "loading",
    duration: 0,
    forbidClick: true,
  });

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_shipin_active_config",
    });

    closeToast();
    initConfigData(res);
  } catch (error) {
    console.log("error", error);

    closeToast();
    showToast({
      type: "fail",
      message: "获取失败",
    });
  }
};

// 获得战绩和排行榜奖励
const myStandingsData = ref({
  xunzhang: 0,
  tiaozhan_num: 0,
  success_num: 0,
  reward: 0,
  total_xunzhang: 0,
  total_tiaozhan_num: 0,
  total_success_num: 0,
  total_reward: 0,
});
const rankRewardList = ref([]);

const getMyStandingsAndRankReward = async () => {
  const params = {
    uid: parseInt(store.userInfo.uid),
  };

  if (!store.userInfo.zongshi_card || !store.userInfo.dashi_card) {
    const res = await getTianti(params);

    if (res.data && (res.data.zongshi_card > 0 || res.data.dashi_card > 0)) {
      store.$patch((state) => {
        state.userInfo.dashi_card = !!res.data.dashi_card;
        state.userInfo.zongshi_card = !!res.data.zongshi_card;
        state.userInfo.level = res.data.level;
      });
    }
  }

  const res2 = await getTiaozhan(params);

  console.log("res2222", res2);

  if (res2.data) {
    res2.data.my_info && (myStandingsData.value = res2.data.my_info);

    rankRewardList.value = res2.data.tiaozhan_def.reward ?? [
      588, 388, 288, 188, 88, 58, 28,
    ];
  }
};

onBeforeMount(() => {
  typeTabToggle(route.query.type ?? 0);
  getMyStandingsAndRankReward();
});

//#endregion

//#region 点击参加比赛

const curApplyActiveData = ref({});

const confirmApplyShow = ref(false);
const cur_active_sign_gold_index = ref(0);

const applyBtnEvent = (data) => {
  curApplyActiveData.value = data;
  confirmApplyShow.value = true;
};

// 选择入座携带费
const activeGoldSelectChange = (index) => {
  cur_active_sign_gold_index.value = index;
};

// 分配房间
const findGameRoom = () => {
  sendInstructions({
    msg_type: "get_machine_info",
    game_name: curApplyActiveData.value.game_name,
  });

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let challengeRoomList = JSON.parse(
        JSON.stringify(store.gameDataList[curApplyActiveData.value.game_name])
      );

      challengeRoomList = challengeRoomList.filter((game) => {
        const verifyName =
          game.product_name == curApplyActiveData.value.game_name;

        let verifySeat = game.occupy.length <= 3;

        const verifyAssert = game.status != 0;

        const verifyLevel = game.level <= store.userInfo.recharge_level;

        if (game.product_name == "bbl") verifySeat = game.occupy.length <= 1;

        const verifyRatio = game.exchange == curApplyActiveData.value.exchange;

        return (
          verifyRatio && verifySeat && verifyName && verifyLevel && verifyAssert
        );
      });

      if (challengeRoomList.length > 0) {
        const randomIndex = Math.floor(
          Math.random() * challengeRoomList.length
        );
        const randomObject = challengeRoomList[randomIndex];

        const devList = randomObject.device_info.filter((dev) => {
          return (
            (dev.occupy.player_name == null ||
              dev.occupy.player_name == undefined ||
              dev.occupy.player_id == null ||
              dev.occupy.player_id == undefined) &&
            dev.status == 1
          );
        });

        let cDev = null;
        if (devList.length > 0) {
          const randomIndex2 = Math.floor(Math.random() * devList.length);
          cDev = devList[randomIndex2];
        }

        resolve({
          current_device: cDev,
          current_machine: randomObject,
        });
      } else {
        resolve({
          current_device: null,
          current_machine: null,
        });
      }
    }, 1000);
  });
};

// 匹配房间 然后发送指令
const allocationRoom = async () => {
  const params = {
    msg_type: "enter_active_game",
    game_name: curApplyActiveData.value.game_name,
    machine_index: "1",
    device_name: "mgc11",
    active_index: curApplyActiveData.value.active_index,
    select_site_index: cur_active_sign_gold_index.value + 1,
  };

  const findRoomData = await findGameRoom();
  if (
    findRoomData.current_machine == undefined ||
    findRoomData.current_device == undefined ||
    findRoomData.current_machine == null ||
    findRoomData.current_device == null
  ) {
    closeToast();
    showToast({
      message: "暂无符合房间\n请稍后重试",
    });
    return;
  }

  params.machine_index = findRoomData.current_machine.machine_index;
  params.device_name = findRoomData.current_device.device_name;

  store.$patch((state) => {
    state.current_machine = findRoomData.current_machine;
    state.current_device = findRoomData.current_device;
    state.settlementResult.game_name =
      findRoomData.current_machine.product_name;
    state.settlementResult.device_name =
      findRoomData.current_device.device_name;
  });

  try {
    const res = await asyncSendInstructions(params);
    closeToast();
    if (res.error == "ok") {
      store.$patch((state) => {
        if (
          curApplyActiveData.value.active_type == "normal" ||
          curApplyActiveData.value.active_type == "dashi" ||
          curApplyActiveData.value.active_type == "zongshi"
        ) {
          state.settlementResult.type = "challenge";
        } else state.settlementResult.type = "active";

        state.userInfo.tiaozhan_ticket =
          state.userInfo.tiaozhan_ticket - curApplyActiveData.value.ticket;

        state.userInfo.gold =
          Number(state.userInfo.gold) - Number(res.dec_gold);

        state.curTabActive.parent = curApplyActiveData.value.game_name;

        state.gameIsWatch = false;
      });

      // router.push({
      //   name: curApplyActiveData.value.game_name,
      // });

      router.replace({
        name: curApplyActiveData.value.game_name,
      });
    } else {
      const msgs = {
        "enter num limit": "参加次数上限！",
        "device occupy": "暂无空位\n稍后重试！",
        "site limit": "参加次数上限！",
        "no find device": "暂无匹配房间",
        "gold limit": "币量不足",
      };

      showToast({
        type: "fail",
        message: msgs[res.error] ?? res.error ?? "系统错误",
      });
    }
  } catch (error) {
    closeToast();
    showToast({
      message: "暂无符合房间\n请稍后重试",
    });
  }
};

const applyActiveStart = () => {
  if (
    curApplyActiveData.value.gold_select_list[cur_active_sign_gold_index.value]
      .gold > store.userInfo.gold
  ) {
    showToast({
      type: "fail",
      message: "金币不足",
    });
    return;
  }

  if (curApplyActiveData.value.level_limit > store.userInfo.recharge_level) {
    showToast({
      type: "fail",
      message: "等级不足",
    });
    return;
  }

  if (curApplyActiveData.value.ticket > store.userInfo.tiaozhan_ticket) {
    showToast({
      type: "fail",
      message: "门票不足",
    });
    return;
  }

  // confirmApplyShow.value = false;
  showToast({
    type: "loading",
    message: "正在匹配房间\n请稍后....",
    duration: 15000,
    forbidClick: true,
  });

  // 分配房间
  allocationRoom();
};

//#endregion

//#region 排行榜
const challengeRankListData = ref([]);
const weekRankListShow = ref(false);
const curRankType = ref("realtime");
const rank_tab_active_bg = ref();
const rank_type_list = [
  {
    key: "realtime",
    text: "本周排名",
  },
  {
    key: "history",
    text: "上周排名",
  },
];
const lookRankIconClick = () => {
  weekRankListShow.value = true;
  getChallengeRankListData();
};
const getChallengeRankListData = async (type = "realtime") => {
  curRankType.value = type;

  if (type == "realtime") {
    rank_tab_active_bg.value &&
      (rank_tab_active_bg.value.style.transform = "translateX(0%)");
  } else
    rank_tab_active_bg.value &&
      (rank_tab_active_bg.value.style.transform = "translateX(100%)");

  const params = {
    msg_type: "get_tiaozhan_rank",
    type,
  };
  try {
    const res = await asyncSendInstructions(params);
    if (Array.isArray(res) && res.length > 30) res.length = 30;
    challengeRankListData.value = res;
  } catch (error) {}
};

// 积分排行榜
const lookActiveRankPupToggle = (data) => {
  if (data.join_num == 0) {
    showToast({
      message: "无人上榜！",
    });
    return;
  }

  store.$patch((state) => {
    state.activeData.active_index = data.active_index;
    state.activeData.game_name = data.game_name;
    state.activeData.active_index = data.active_index;
    state.activeData.reward_data_list = data.reward_data_list;
    state.activeData.active_type = data.active_type;
  });

  router.push({
    path: "/activeRank",
    query: {
      rank_num: data.rank_num,
    },
  });
};

// 积分规则说明
const ruleShow = ref(false);
const lookRuleShowToggle = (type) => {
  ruleShow.value = type;
};

// 购买挑战卡
const payCardPupShow = ref(false);
const payCardIndex = ref(1);
const payCardPupShowToggle = (statu) => {
  payCardPupShow.value = statu;
};

const payCardIndexChange = (index) => {
  payCardIndex.value = index;
};
const handlePayCardClick = () => {
  // 实名认证
  if (!store.userInfo.id_verification && !store.userInfo.is_gm) {
    store.storeShowToggle("auth_user_show", true);
    return;
  }
  showToast({
    type: "loading",
    duration: 3000,
    forbidClick: true,
  });

  const params = {
    msg_type: "player_recharge",
    type: "tz_card",
    index: payCardIndex.value,
    product_id: store.initPayPupData.tz_card[payCardIndex.value - 1].product_id,
    area_name: "jk",
    item_id: "",
  };
  // if (store.channelType != "ios") {
  //   params.recharge_platform = "wx";
  // }
  sendInstructions(params);
};

//#endregion
</script>

<style lang="scss" scoped>
.challengeview {
  position: relative;
  background: #240d27;
  padding-top: 260px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/top_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-bottom: 20px;

  .explain_icon {
    position: absolute;
    top: 170px;
    right: 0;
    width: 56px;
    height: 38px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/explain_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .rank_icon {
    @extend .explain_icon;
    top: 210px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/rank_icon.png");
  }

  .my_tiaozhan_ticket {
    position: absolute;
    top: 220px;
    left: 16px;
    width: 100px;
    height: 22px;
    font-size: 14px;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/my_tiaozhan_ticket.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .value {
      // padding-left: 30px;
      width: 100%;
      height: 100%;
      font-weight: 1000;
      text-align: center;
      line-height: 22px;
      color: #feedbf;
      // background: linear-gradient(90deg, #feedbf 0%, #f8c998 56%, #f4b07c 100%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  .type_tab_wrapper {
    display: flex;
    justify-content: center;
    .type_tab {
      display: flex;
      width: 320px;
      height: 46px;
      justify-content: space-between;
      .type_tab_item {
        width: 160px;
        height: 46px;
      }
    }
  }

  .challenge_list_wrapper {
    // transform: translateY(-20px);
    padding: 0 15px;
    .normal_list_wrapper {
      padding-top: 20px;
    }

    .active_item {
      height: 230px;
      background-image: url("@/assets/images/active_item_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 10px;
      padding-left: 9px;
      padding-right: 10px;
      padding-top: 6px;

      color: rgba($color: #fff, $alpha: 0.6);
      .active_title {
        position: relative;
        padding: 6px 10px;
        height: 50px;
        background: linear-gradient(1deg, #662a90 0%, #1e0c2a 100%);
        .title {
          color: #f1d9ad;
          font-size: 16px;
          font-weight: 1000;
        }

        .active_status_wrapper {
          font-size: 10px;
          margin-top: 2px;
          display: flex;
          .active_end_time {
            flex: 1;
            text-align: left;
            white-space: nowrap;
          }
          .active_statu {
            // flex: 1;
            text-align: center;
            color: #fee22a;
            // font-weight: bold;
            white-space: nowrap;
            text-wrap: nowrap;
          }
          .match_time {
            // flex: 1;
            text-align: center;
            padding-right: 10px;
          }
          .join_count {
            // flex: 1;
            text-align: center;
          }
        }

        .active_statu_0_icon,
        .active_statu_1_icon,
        .active_statu_2_icon,
        .active_statu_3_icon {
          position: absolute;
          right: 0;
          top: 0;
          width: 50px;
          height: 50px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/active_statu_1.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .active_statu_2_icon {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/active_statu_2.png") !important;
        }
        .active_statu_3_icon {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/active_statu_3.png") !important;
        }
        .active_statu_0_icon {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/active_statu_0.png") !important;
        }

        .active_statu_4_icon {
          @extend .active_statu_0_icon;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/active_statu_4.png") !important;
        }
        .active_statu_5_icon {
          @extend .active_statu_0_icon;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/active_statu_5.png") !important;
        }
        .active_statu_6_icon {
          @extend .active_statu_0_icon;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/active_statu_6.png") !important;
        }
      }
      .active_container {
        padding: 10px;
        height: 174px;
        color: #fff;
        .active_info {
          height: 100px;
          display: flex;
          .active_index {
            position: relative;
            width: 84px;
            height: 100%;
            min-width: 84px;
            .active_cover {
              position: relative;
              width: 100%;
              height: 84px;
              z-index: 1;
            }
            .active_index_name {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 20px;
              background-image: url("@/assets/images/active_index_bg.png");
              background-size: 100% 100%;
              text-align: center;
              z-index: 2;
              color: #fff;
              font-size: 14px;
              line-height: 20px;
              font-weight: bold;
            }
          }

          .active_details {
            padding: 0 10px;
            flex: 1;
            .details_row {
              display: flex;
              margin-top: 6px;
              .row_item {
                display: flex;
                align-items: center;
                // margin-right: 30px;
                white-space: nowrap;
              }
              .row_reward_item {
                display: flex;
                align-items: center;
                min-width: 60px;
                background-color: #58247c;
                height: 20px;
                border-radius: 10px;
                padding: 0 6px 0 0;
                white-space: nowrap;
              }

              .gold_icon {
                min-width: 16px;
                width: 16px;
                height: 16px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                margin: 0 4px;
              }
              .dashi_icon {
                @extend .gold_icon;

                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/dashi_icon.png");
              }
              .dashicard_active {
                @extend .gold_icon;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/dashicard_active.png");
              }
              .zongshi_active {
                @extend .gold_icon;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/zongshicard_active.png");
              }

              .look_rank_btn {
                font-size: 10px;
                width: 74px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                background: linear-gradient(154deg, #8e45c9 0%, #608dfc 100%);
                border-radius: 4px;
              }
            }
          }
        }

        .apply_active_wrapper {
          padding-top: 14px;

          .apply_active_btn {
            width: 180px;
            height: 30px;
            background-image: url("@/assets/images/active_apply.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 0 auto;
            line-height: 30px;
          }

          .apply_look_rank_btn {
            width: 180px;
            height: 30px;
            background-image: url("@/assets/images/active_end_apply.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 0 auto;
            line-height: 30px;
          }
          .apply_c_rank_btn {
            width: 180px;
            height: 30px;
            background-image: url("@/assets/images/active_c_apply.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 0 auto;
            line-height: 30px;
          }
          .apply_b_rank_btn {
            width: 180px;
            height: 30px;
            background-image: url("@/assets/images/active_b_apply.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 0 auto;
            line-height: 30px;
          }
        }
      }
    }
  }
}

// 我的战绩
.my_standings_wrapper {
  position: relative;
  border-radius: 5px;
  // background-color: #2b134c;
  height: 254px;
  // margin-top: 10px;
  padding: 40px 15px 15px 15px;

  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/my_standings_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .my_cards_wrapper {
    height: 100px;

    display: flex;
    justify-content: center;
    .my_card_item {
      position: relative;
      width: 86px;
      height: 100px;

      margin-right: 14px;

      .no_card_mask {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($color: #3b2959, $alpha: 0.8);
        width: 100%;
        height: 100%;
        z-index: 3;
        text-align: center;
        line-height: 100px;
      }
    }
  }

  .my_standings {
    .top_title {
      width: 100%;
      display: flex;
      height: 20px;
      margin-top: 12px;
      .top_text {
        margin-right: 8px;
        color: transparent;
        font-size: 10px;
      }
      .top_title_list {
        flex: 1;
        color: #f9e1a1;
        display: flex;
        .top_title_item {
          position: relative;
          flex: 1;
          text-align: center;
          // &::after {
          //   content: "";
          //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/Line_15@2x.png");
          //   background-size: 100% 100%;
          //   background-repeat: no-repeat;
          //   width: 1px;
          //   height: 14px;
          //   position: absolute;
          //   right: 0;
          //   top: 50%;
          //   transform: translateY(-50%);
          // }

          // &:last-child {
          //   // padding-right: 26px;
          //   &::after {
          //     visibility: hidden;
          //   }
          //   &::before {
          //     content: "";
          //     background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
          //     background-size: 100% 100%;
          //     background-repeat: no-repeat;

          //     position: absolute;
          //     top: 50%;
          //     right: 10px;
          //     height: 14px;
          //     width: 14px;
          //     transform: translateY(-50%);
          //   }
          // }
        }
      }
    }

    .bottom_value {
      .bottom_item {
        margin-top: 8px;
        display: flex;
        height: 20px;
        line-height: 20px;

        .bottom_text {
          margin-right: 8px;
          color: #f9e1a1;
          font-size: 10px;
        }
        .bottom_value_list {
          flex: 1;
          display: flex;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/week_bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .bottom_value_item {
            flex: 1;
            text-align: center;
            position: relative;
            &::after {
              content: "";
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/Line_15@2x.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 1px;
              height: 14px;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }

            &:last-child {
              // padding-right: 26px;
              &::after {
                visibility: hidden;
              }
              &::before {
                content: "";
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;

                position: absolute;
                top: 50%;
                right: 8px;
                height: 14px;
                width: 14px;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
}

// 排行榜
.rank-pup-wrapper {
  background: #1d1133
    linear-gradient(180deg, #7c6e95 0%, rgba(59, 38, 82, 0.8) 5%, #1d1133 13%);
  position: relative;
  height: 70%;
  min-height: 60vh;
  // padding-top: 22px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/rank_pup_title.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    transform: translate(-50%, -50%);
    width: 318px;
    height: 42px;
  }

  .rank_type_tab_wrapper {
    padding: 32px 58px 12px 58px;
    .rank_type_tab_box {
      background: linear-gradient(180deg, #2b202c 0%, #322f3b 100%);
      border-radius: 30px;
      position: relative;
      .rank_type_tab {
        display: flex;
        .rank_type_tab_item {
          flex: 1;
          text-align: center;
          height: 30px;
          line-height: 30px;
          color: #f4e7cf;
          position: relative;
          z-index: 2;
        }
        .rank_type_tab_item_active {
          color: #400c16;
          font-weight: 800;
        }
      }

      .rank_tab_active_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/active_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 1;
        transition: transform 0.3s;
      }
    }
  }

  .my_rank_info {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
  }

  .rank_list_content {
    width: 100%;
    max-height: calc(70vh - 76px);
    // min-height: 100%;
    // flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
    .rank_list {
      border: 1px solid #fedd79;
      border-radius: 16px;
      height: 100%;
      overflow: hidden;
      .first_item {
        background: linear-gradient(
          90deg,
          #dda820 0%,
          rgba(221, 168, 32, 0) 100%
        );
      }
      .two_item {
        background: linear-gradient(
          90deg,
          #358bc8 0%,
          rgba(53, 139, 200, 0) 100%
        );
      }
      .three_item {
        background: linear-gradient(
          90deg,
          #cb652c 0%,
          rgba(203, 101, 44, 0) 100%
        );
      }
      .rank_list_item {
        display: flex;
        padding: 10px 10px 0 0;
        .ranking {
          width: 60px;
          position: relative;
          .ranking_text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;

            border: 1px solid #ffffff;
            font-size: 12px;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
          }
        }
        .rank_info {
          flex: 1;
          padding-left: 20px;
          display: flex;

          .player_name {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-wrap: nowrap;
          }
        }
        .rank_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 6px;
          text-align: right;
          .rank_number {
            font-size: 14px;
          }
          .rank_item_rewrad {
            padding-right: 20px;
            font-size: 12px;
            line-height: 20px;
            color: #fee22a;
            position: relative;
            font-weight: bold;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 18px;
              height: 18px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }
        }
      }

      .awardnum-wrapper {
        position: relative;
        color: #fee22a;
        padding-right: 22px;
        .jinbi-icon {
          position: absolute;
          right: 0;
          top: 50%;
          width: 20px;
          height: 20px;
          transform: translateY(-50%);
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .odd_item {
        background: linear-gradient(
          90deg,
          #342522 0%,
          rgba(52, 37, 34, 0) 100%
        );
      }
      .eve_item {
        border-image: linear-gradient(
            180deg,
            rgba(255, 245, 172, 1),
            rgba(254, 221, 121, 1),
            rgba(252, 230, 158, 1)
          )
          2 2;
      }
    }
  }
}

// 选择金币
.challenge-recognition {
  width: 342px;
  position: relative;
  background-position: center;
  padding: 20px 24px 12px 24px;
  font-size: 16px;

  border: 10px solid rgb(152, 67, 229);
  border-radius: 20px;
  background-color: rgb(75, 35, 128);

  .pup-close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 26px;
    height: 26px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
    background-size: 100% 100%;
  }

  .gold-level-item {
    border: 1px solid #fff;
    background-color: #5f3a92;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    padding: 6px 0;

    .icon_jinbi {
      display: block;
      height: 16px;
      width: 16px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
      background-size: 100% 100%;
    }
  }

  .gold-level-item-active {
    background-color: #fff;
    color: black;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 60%;
    height: 12px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_top_icon.png");
    background-size: 100% 100%;
  }

  .icon_card {
    width: 12px;
    height: 16px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/icon_card.png");
    background-size: 100% 100%;
  }
}

// 购买挑战卡
.pay_card_wrapper {
  position: relative;
  width: 305px;
  background-color: #1d1133;
  padding: 40px 15px 20px 15px;
  text-align: center;
  .pay_card_title {
    font-size: 20px;
    font-weight: bold;
  }
  .pay_card_list {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .pay_card_item {
      width: 30%;
      padding: 10px 4px;
      border-radius: 8px;
      background-color: #322f3b;
      .card_number_wrapper {
        display: flex;
        justify-content: center;
        .card_icon {
          width: 18px;
          height: 22px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/icon_card.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .card_number {
          margin-left: 4px;
          font-size: 16px;
          line-height: 22px;
        }
      }
      .card_price {
        font-size: 12px;
      }
    }

    .pay_card_item_active {
      background-color: #fdf4e0;
      color: #221c2b;
    }
  }

  .pay_card_btn {
    margin-top: 12px;
    background-color: #fcb200;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-radius: 8px;
    color: #221c2b;
  }
  .close_pay_card_icon {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
// 规则说明
.rule_wrapper {
  width: 306px;
  height: 447px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/tutor_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 26px 30px 26px;

  .rule_title {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%);
    height: 52px;
    line-height: 56px;
    width: 188px;
    font-size: 16px;
    font-weight: 1000;
    text-align: center;

    background-image: url("@/assets/images/challenge/guize_title.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .close_icon {
    position: absolute;
    right: 12px;
    top: 12px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }

  .rule_content {
    height: 100%;
    overflow: auto;
  }
}
</style>
