<template>
  <LandscapePopup :show="store.gemShopShow" position="right">
    <div class="gem_shop_wrapper">
      <div
        class="close_icon"
        @click="store.storeShowToggle('gemShopShow', false)"
      ></div>

      <!-- <Sprites :left="100" :bottom="32" :bg_index="9" @_click="goToLongShop" /> -->

      <div class="user_info_block">
        <div class="top_block">
          <div class="user_head">
            <HeadPortrait
              width="100%"
              height="100%"
              :src="store.userInfo.headimgurl"
              :level="Number(store.userInfo.recharge_level)"
              :ae_id="store.userInfo.select_title"
              ae
              fe
            />
          </div>
          <div class="user_name">
            {{ store.userInfo.player_name }}
          </div>
        </div>
        <div class="user_level_wrapp">
          <Level
            :border="false"
            :level_value="Number(store.userInfo.recharge_level)"
            level_type="recharge_level"
          />
          <span style="display: inline-block; width: 4px"></span>
          <Level :border="false" :level_value="Number(store.userInfo.level)" />
        </div>
      </div>

      <div
        class="user_buff_block"
        v-if="
          store.user_gem_info.buff.gem_fanbei_card ||
          store.user_gem_info.buff.gem_gaobao_card
        "
      >
        <div class="card_list">
          <div
            class="use_buff_card"
            v-for="(cardV, cardK) in store.user_gem_info.buff"
          >
            <van-image
              width="100%"
              height="100%"
              fit="contain"
              position="center"
              :src="goods_cover_maps[cardK]"
              :show-loading="false"
            />
          </div>
        </div>

        <div class="text">使用中</div>
      </div>

      <div class="shop_cotainer">
        <div class="rule_en_text" @click="rule_show_toggle(true)">
          <span class="wh_icon"></span> <span>宝珠说明</span>
        </div>
        <div class="logo_wrapper"></div>
        <div class="type_wrapper">
          <div class="type_bg">
            <van-image
              v-show="shop_type == 0"
              width="100%"
              height="100%"
              fit="contain"
              position="center"
              :src="shop_type_1"
              :show-loading="false"
            />
            <van-image
              v-show="shop_type == 1"
              width="100%"
              height="100%"
              fit="contain"
              position="center"
              :src="shop_type_2"
              :show-loading="false"
            />
          </div>
          <div class="type_item" @click="shop_type_toggle(0)"></div>
          <div class="type_item" @click="shop_type_toggle(1)"></div>
        </div>

        <div class="user_gem_info_top_wrap">
          <div class="text_">我的宝珠</div>
          <div
            class="my_gem_item"
            v-for="(gemValue, gemKey) in store.user_gem_info.gem"
            v-show="gemKey != 'long'"
          >
            <div class="gem_icon">
              <van-image
                width="100%"
                height="100%"
                fit="contain"
                position="center"
                :src="gem_info_maps[gemKey].big_img"
                :show-loading="false"
              />
            </div>
            <span>{{ gemValue }}</span>
          </div>
        </div>

        <div class="shop_content">
          <div class="goods_list_wrapper" v-show="shop_type == 1">
            <div class="goods_list">
              <div class="goods_item" v-for="goodsItem in shop_goods_datas">
                <div class="goods_name">
                  <span class="name_text">{{ goodsItem.goods_name }}</span>
                </div>
                <div class="goods_cover">
                  <van-image
                    width="100%"
                    height="100%"
                    fit="contain"
                    position="center"
                    :src="goodsItem.goods_cover"
                    :show-loading="false"
                  />
                </div>
                <div class="goods_price">
                  <div
                    class="price_item"
                    v-for="(pV, pK) in goodsItem.price"
                    v-show="pV"
                  >
                    <span> {{ pV }}</span>
                    <div
                      class="gem_icon"
                      :style="{
                        opacity:
                          store.userInfo.recharge_level <
                          gem_info_maps[pK].level
                            ? 0.5
                            : 1,
                      }"
                    >
                      <van-image
                        width="100%"
                        height="100%"
                        fit="contain"
                        position="center"
                        :src="gem_info_maps[pK].big_img"
                        :show-loading="false"
                      />
                      <div
                        class="gem_lock"
                        v-if="
                          store.userInfo.recharge_level <
                          gem_info_maps[pK].level
                        "
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="pay_btn" @click="handlePayGoodsItem(goodsItem)">
                  兑换
                </div>
              </div>
            </div>
          </div>

          <div class="user_gem_info_wrapper" v-show="shop_type == 0">
            <div class="user_gem_list">
              <div
                class="user_gem_item"
                v-for="(gemValue, gemKey) in store.user_gem_info.gem"
                @click="useGemClick(gemKey)"
              >
                <div class="user_gem_name">
                  <van-image
                    width="100%"
                    height="100%"
                    fit="contain"
                    position="center"
                    :src="gem_info_maps[gemKey].text_img"
                    :show-loading="false"
                  />
                </div>

                <div class="user_gem_wrap">
                  <div
                    class="user_gem_img"
                    :style="{
                      opacity:
                        store.userInfo.recharge_level <
                          gem_info_maps[gemKey].level || gemKey == 'long'
                          ? 0.4
                          : 1,
                    }"
                  >
                    <van-image
                      width="100%"
                      height="100%"
                      fit="contain"
                      position="center"
                      :src="gem_info_maps[gemKey].big_img"
                      :show-loading="false"
                    />
                  </div>
                  <div
                    class="user_gem_lock"
                    v-if="
                      store.userInfo.recharge_level <
                        gem_info_maps[gemKey].level || gemKey == 'long'
                    "
                  ></div>
                  <!-- <div
                    class="lock_level_text"
                    v-if="
                      store.userInfo.recharge_level <
                      gem_info_maps[gemKey].level
                    "
                  >
                    {{
                      gradLevelIcon[gem_info_maps[gemKey].level - 1].level_text
                    }}激活
                  </div> -->
                </div>

                <div class="user_gem_name_wrapper">
                  <div class="span_1">数量</div>
                  <div class="span_2">{{ gemValue }}</div>
                </div>
              </div>
            </div>
            <div class="clear_text">每个赛季结束后将清零,请注意有效时间！</div>
          </div>
        </div>
      </div>

      <div class="rule_wrap" v-show="rule_show">
        <div class="rule_block scale_in_center_class">
          <div class="rule_close_icon" @click="rule_show_toggle(false)"></div>
          <div class="title">宝珠说明</div>
          <div class="content">
            1、风、火、雷、电宝珠分别在推币机、魔鬼城、海王3、爆爆乐四款游戏中获得奖项或者击杀怪物后,有几率获。房间币值越高,获得的几率越大。<br />
            2、击杀雷霸龙、至尊神龙、狂暴飞龙,获得你是传奇和全盘奖有几率获得龙珠。房间币值越高,获得几率越大,龙珠仅限在龙珠商店使用。<br />
            3、风、火、雷、电珠需满足等级条件方可激活,进行金币兑换。 <br />
            风 无限制 &nbsp;&nbsp; 火 白银3以上激活 <br />
            雷 黄金4以上激活 &nbsp;&nbsp; 电 钻石4以上激活 <br />
            4、风、火、雷、电珠赛季内可有效兑换,赛季结束数据清零<br />
          </div>
        </div>
      </div>

      <div class="exchange_pup" v-if="exchange_pup_show">
        <div class="exchange_container scale_in_center_class">
          <div
            class="close_icon_2"
            @click="exchange_pup_show_toggle(false)"
          ></div>

          <div class="exchange_limit_num_text">
            此商品您今日兑换的次数剩余
            <span style="color: #fee22a">{{ exchange_max_num }}</span> 次
          </div>

          <div class="exchange_goods_info_wrapper">
            <div class="goods_price_list">
              <div
                class="gem_item"
                v-for="(pV, pK) in current_exchange_target_goods.price"
                v-show="pV"
              >
                <div class="gem_img">
                  <van-image
                    width="100%"
                    height="100%"
                    fit="contain"
                    position="center"
                    :src="gem_info_maps[pK].big_img_2"
                    :show-loading="false"
                  />
                </div>
                <div class="gem_num">{{ pV }}</div>
              </div>
            </div>

            <div class="exchange_icon"></div>

            <div class="exchange_target_goods">
              <div class="goods_cover">
                <van-image
                  width="100%"
                  height="100%"
                  fit="contain"
                  position="center"
                  :src="current_exchange_target_goods.goods_cover"
                  :show-loading="false"
                />
              </div>

              <div class="goods_name">
                {{ current_exchange_target_goods.goods_name }}
              </div>
            </div>
          </div>
          <div class="exchange_stepper_wrapper">
            <div class="stepper_text">数量</div>
            <div class="stepper_wrap">
              <div
                class="sub_count_btn"
                @click="exchange_count_num_toggle('sub')"
              ></div>
              <div class="stepper_cotent">{{ exchange_count_num }}</div>
              <div
                class="add_count_btn"
                @click="exchange_count_num_toggle('add')"
              ></div>
            </div>
          </div>
          <div class="exchange_btn" @click="confirmExchangeGoodsItem">兑换</div>
        </div>
      </div>

      <RewardPup
        :show="buyGoodsSuccShow"
        @confirm="buyGoodsSuccShowToggle(false)"
      >
        <div class="reward_block">
          <div class="reward_item">
            <div class="reward_type">
              <van-image
                width="100%"
                height="100%"
                fit="contain"
                position="center"
                :src="current_exchange_target_goods.goods_cover"
                :show-loading="false"
              />
            </div>
            <div class="reward_num">
              {{ current_exchange_target_goods.goods_name }} *
              {{ exchange_count_num }}
            </div>
          </div>
        </div>
      </RewardPup>
    </div>
  </LandscapePopup>
</template>

<script setup>
import LandscapePopup from "@/components/LandscapePopup.vue";
import RewardPup from "@/components/RewardPup.vue";
import { useStore } from "@/store/store";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import { nextTick, reactive, ref, watch } from "vue";
import { showToast } from "vant";
import { useToggle } from "@vant/use";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Sprites from "@/components/Sprites.vue";
import Level from "@/components/Level.vue";
import Decimal from "decimal.js";
import { gradLevelIcon } from "@/utils/levelData";
import feng_big from "@/assets/images/gemShop/feng.png";
import huo_big from "@/assets/images/gemShop/huo.png";
import lei_big from "@/assets/images/gemShop/lei.png";
import dian_big from "@/assets/images/gemShop/dian.png";
import long_big from "@/assets/images/gemShop/long.png";

import feng_big_2 from "@/assets/images/gemShop/feng_2.png";
import huo_big_2 from "@/assets/images/gemShop/huo_2.png";
import lei_big_2 from "@/assets/images/gemShop/lei_2.png";
import dian_big_2 from "@/assets/images/gemShop/dian_2.png";
import long_big_2 from "@/assets/images/gemShop/long_2.png";
import feng_text from "@/assets/images/gemShop/feng_text.png";
import huo_text from "@/assets/images/gemShop/huo_text.png";
import lei_text from "@/assets/images/gemShop/lei_text.png";
import dian_text from "@/assets/images/gemShop/dian_text.png";
import long_text from "@/assets/images/gemShop/long_text.png";

import gem_fanbei_card_img from "@/assets/images/gemShop/gem_fanbei_card.png";
import gem_gaobao_card_img from "@/assets/images/gemShop/gem_gaobao_card.png";
import tianti_buff_card_img from "@/assets/images/gemShop/tianti_buff_card.png";
const jiacheng_card_img =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/jiacheng_card.png";

import shop_type_1 from "@/assets/images/gemShop/tab_1.png";
import shop_type_2 from "@/assets/images/gemShop/tab_2.png";

const goods_cover_maps = {
  gold: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
  lottery:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png",
  gem_fanbei_card: gem_fanbei_card_img,
  gem_gaobao_card: gem_gaobao_card_img,
  tianti_buff_card: tianti_buff_card_img,
  jiacheng_card: jiacheng_card_img,
};

const store = useStore();

const shop_goods_datas = ref([]);

//#region 数据配置
const gem_info_maps = {
  feng: {
    big_img: feng_big,
    big_img_2: feng_big_2,

    text_img: feng_text,
    level: 1,
  },
  huo: {
    big_img: huo_big,
    big_img_2: huo_big_2,

    text_img: huo_text,
    level: 4,
  },
  lei: {
    big_img: lei_big,
    big_img_2: lei_big_2,

    text_img: lei_text,
    level: 7,
  },
  dian: {
    big_img: dian_big,
    big_img_2: dian_big_2,

    text_img: dian_text,
    level: 15,
  },
  long: {
    big_img: long_big,
    big_img_2: long_big_2,

    text_img: long_text,
    level: 1,
  },
};

const get_shop_data = async () => {
  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_gem_collect",
    });

    const { player_gem_collect, gem_shop } = res;

    const gold_num_index = {
      30: 8,
      100: 9,
      1000: 12,
    };

    store.$patch((state) => {
      state.user_gem_info.gem.feng = player_gem_collect.feng;
      state.user_gem_info.gem.huo = player_gem_collect.huo;
      state.user_gem_info.gem.lei = player_gem_collect.lei;
      state.user_gem_info.gem.dian = player_gem_collect.dian;
      state.user_gem_info.gem.long = player_gem_collect.long;

      state.user_gem_info.buff = player_gem_collect.buff;
      state.user_gem_info.shop = player_gem_collect.shop;
    });

    const init_shop_goods_list = [];
    for (const _index in gem_shop) {
      const item = gem_shop[_index];

      const goodsInfo = {
        goods_name: "30金币",
        goods_type: "gold",
        goods_num: 30,
        goods_cover: "",
        good_pay_index: _index,
        price: {
          feng: 0,
          huo: 0,
          lei: 0,
          dian: 0,
        },
        level_exchange_limit: item.level,
      };

      // [
      //   { max: 1, exchange_num: 50, min: 1 },
      //   { max: 3, exchange_num: 100, min: 2 },
      //   { max: 1000, exchange_num: 500, min: 4 },
      // ],

      goodsInfo.goods_type = item.reward.type;

      let g_name = "";
      switch (item.reward.type) {
        case "gold":
          g_name = item.reward.num + "金币";
          goodsInfo.goods_cover = `${store.imgBaseUrl}/pay/pay_gold_${
            gold_num_index[item.reward.num] ?? 6
          }.png`;
          break;
        case "lottery":
          goodsInfo.goods_cover = store.imgBaseUrl + "/global/lottery.png";
          g_name = item.reward.num + "彩券";
          break;
        case "gem_fanbei_card":
          goodsInfo.goods_cover = gem_fanbei_card_img;
          g_name = `宝珠翻倍卡(${item.reward.add_mul}倍)`;
          break;
        case "gem_gaobao_card":
          goodsInfo.goods_cover = gem_gaobao_card_img;
          g_name = `宝珠高爆卡(${item.reward.add_mul}倍)`;
          break;
        case "tianti_buff_card":
          goodsInfo.goods_cover = tianti_buff_card_img;
          const buff_v = new Decimal(item.reward.num)
            .sub(1)
            .mul(100)
            .toNumber();

          g_name = `天梯buff卡(${buff_v}%)`;
          break;
        case "jiacheng_card":
          goodsInfo.goods_cover = jiacheng_card_img;
          const cz_add = new Decimal(item.reward.num)
            .sub(1)
            .mul(100)
            .toNumber();
          g_name = `充值加成卡(${cz_add}%)`;
          break;
      }
      goodsInfo.goods_name = g_name;
      goodsInfo.price.feng = item.condition.feng ?? 0;
      goodsInfo.price.huo = item.condition.huo ?? 0;
      goodsInfo.price.lei = item.condition.lei ?? 0;
      goodsInfo.price.dian = item.condition.dian ?? 0;

      init_shop_goods_list.push(goodsInfo);
    }

    shop_goods_datas.value = init_shop_goods_list;
  } catch (error) {}
};

watch(
  () => store.gemShopShow,
  (newV) => {
    if (newV) {
      get_shop_data();
    }
  }
);

//#endregion

//#region 类型切换
const shop_type = ref(0);
const shop_type_toggle = (index) => {
  shop_type.value = index;
};

//#endregion

//#region 支付

const [exchange_pup_show, exchange_pup_show_toggle] = useToggle(false);
const [buyGoodsSuccShow, buyGoodsSuccShowToggle] = useToggle(false);

const current_exchange_target_goods = ref({
  goods_name: "30金币",
  goods_type: "gold",
  goods_num: 30,
  goods_cover: "",
  good_pay_index: 1,
  price: {
    feng: 0,
    huo: 0,
    lei: 0,
    dian: 0,
  },
  level_exchange_limit: [],
});

function getMaxGoodsCount(goodsPrice, walletNum) {
  let maxGoodsCount = 0;
  while (true) {
    let canBuy = true;
    for (let key in goodsPrice) {
      if (walletNum[key] < goodsPrice[key] * (maxGoodsCount + 1)) {
        canBuy = false;
        break;
      }
    }
    if (canBuy) {
      maxGoodsCount++;
    } else break;
  }
  return maxGoodsCount;
}

const myfindLast = function (array, callback, thisArg) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (callback.call(thisArg, array[i], i, array)) {
      return array[i];
    }
  }
  return undefined;
};

const handlePayGoodsItem = async (goods) => {
  current_exchange_target_goods.value = goods;
  const maxNum = getMaxGoodsCount(goods.price, store.user_gem_info.gem);
  let residue_num = 0;

  const last_limit = myfindLast(
    goods.level_exchange_limit,
    (limititem) => store.userInfo.recharge_level >= limititem.min
  );

  let msgt = "宝珠未激活";
  // 剩几次
  if (last_limit) {
    if (store.user_gem_info.shop[goods.good_pay_index]) {
      residue_num =
        last_limit.exchange_num -
        store.user_gem_info.shop[goods.good_pay_index];
      msgt = "兑换已上限";
    } else {
      residue_num = last_limit.exchange_num;
    }
  }
  const min_num = Math.min(maxNum, residue_num);
  exchange_max_num.value = min_num;
  exchange_count_num.value = min_num;

  if (maxNum <= 0) msgt = "宝珠数量不足";

  if (min_num <= 0) {
    showToast({
      type: "fail",
      message: msgt,
      teleport: ".gem_shop_wrapper",
    });
    return;
  }

  nextTick(() => {
    exchange_pup_show_toggle(true);
  });
};
const exchange_max_num = ref(1);
const exchange_count_num = ref(1);
const exchange_count_num_toggle = (type = "add") => {
  if (type == "add") {
    if (exchange_count_num.value + 1 > exchange_max_num.value) return;
    exchange_count_num.value = exchange_count_num.value + 1;
    return;
  }

  if (exchange_count_num.value - 1 <= 0) return;
  exchange_count_num.value = exchange_count_num.value - 1;
};

const confirmExchangeGoodsItem = async () => {
  const g = current_exchange_target_goods.value;
  const ul = store.userInfo.recharge_level;
  const en = exchange_count_num.value;

  if (en > exchange_max_num.value || en <= 0) {
    showToast({
      type: "fail",
      message: "今日已上限",
      teleport: ".gem_shop_wrapper",
    });
    return;
  }

  const limit1 = g.price.huo > 0 && ul < gem_info_maps.huo.level;
  const limit2 = g.price.lei > 0 && ul < gem_info_maps.lei.level;
  const limit3 = g.price.dian > 0 && ul < gem_info_maps.dian.level;

  const limit4 = store.user_gem_info.gem.feng < g.price.feng * en;
  const limit5 = store.user_gem_info.gem.huo < g.price.huo * en;
  const limit6 = store.user_gem_info.gem.lei < g.price.lei * en;
  const limit7 = store.user_gem_info.gem.dian < g.price.dian * en;

  const isCanPay = [
    limit1,
    limit2,
    limit3,
    limit4,
    limit5,
    limit6,
    limit7,
  ].some((limitItem) => {
    return limitItem;
  });

  if (isCanPay) {
    showToast({
      type: "fail",
      message: "激活宝珠不足",
      teleport: ".gem_shop_wrapper",
    });
    return;
  }

  try {
    const res = await asyncSendInstructions({
      msg_type: "buy_gem_shop",
      index: g.good_pay_index,
      num: en,
    });

    // const res = {
    //   num: 1,
    //   index: "1",
    //   player_gem_collect: {
    //     feng: 79,
    //     update_time: 1729180800,
    //     buff: {},
    //     lei: 25,
    //     uid: 1,
    //     dian: 96,
    //     huo: 92,
    //     long: 46,
    //     shop: { 1: 2 },
    //   },
    //   error: "ok",
    //   reward: { num: 10, type: "lottery" },
    // };

    exchange_pup_show_toggle(false);
    if (res.error == "ok") {
      store.$patch((state) => {
        state.user_gem_info.gem.feng = res.player_gem_collect.feng;
        state.user_gem_info.gem.huo = res.player_gem_collect.huo;
        state.user_gem_info.gem.lei = res.player_gem_collect.lei;
        state.user_gem_info.gem.dian = res.player_gem_collect.dian;
        state.user_gem_info.shop = res.player_gem_collect.shop;
      });

      const _num = Number(res.reward.num) * res.num;

      buyGoodsSuccShowToggle(true);
      store.$patch((state) => {
        res.reward.type == "gold" &&
          (state.userInfo.gold = state.userInfo.gold + _num);
        res.reward.type == "lottery" &&
          (state.userInfo.lottery = state.userInfo.lottery + _num);
      });
    } else {
      const error_maps = {
        "condition limit": "宝珠不足",
        "gem shop level limit": "宝珠未激活",
      };

      showToast({
        type: "fail",
        message: error_maps[res.error] ?? res.error ?? "稍后重试",
        teleport: ".gem_shop_wrapper",
        onClose() {
          get_shop_data();
        },
      });
    }
  } catch (error) {
    showToast({
      type: "fail",
      message: "稍后重试",
      teleport: ".gem_shop_wrapper",
      onClose() {
        get_shop_data();
      },
    });
  }
};

//#endregion

//#region 规则
const [rule_show, rule_show_toggle] = useToggle(false);

const useGemClick = (gemKey) => {
  const gem = gem_info_maps[gemKey];

  if (store.userInfo.recharge_level < gem.level || gemKey == "long") {
    let msg = gradLevelIcon[gem_info_maps[gemKey].level].level_text + "激活";

    gemKey == "long" && (msg = "龙珠商店暂未开放");

    showToast({
      type: "text",
      message: msg,
      teleport: ".gem_shop_wrapper",
    });
  }
};

//#endregion

//#region 龙珠商店
const goToLongShop = () => {
  store.storeShowToggle("gemShopShow", false);
  store.storeShowToggle("longShopShow", true);
};
//#endregion
</script>

<style lang="scss" scoped>
.gem_shop_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  //   background-color: pink;
  background-image: url("@/assets/images/gemShop/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .close_icon {
    position: absolute;
    left: 0;
    top: 30px;
    width: 68px;
    height: 32px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/close_icon_blue.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .exchange_pup {
    position: absolute;
    // inset: 0;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 11;
    background-color: rgba($color: #000000, $alpha: 0.5);

    .exchange_container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 380px;
      height: 284px;
      background-image: url("@/assets/images/gemShop/exchange_pup.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 70px 30px 0 30px;
      .close_icon_2 {
        position: absolute;
        right: 0px;
        top: 12px;
        width: 28px;
        height: 28px;
        transform: translateX(100%);
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/close_icon_white.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .exchange_limit_num_text {
        position: absolute;
        left: 0;
        bottom: 8px;
        font-size: 10px;
        width: 100%;
        text-align: center;
      }

      .exchange_goods_info_wrapper {
        height: 62px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .goods_price_list {
          // width: 172px;
          max-width: 172px;
          min-width: 54px;
          height: 100%;
          display: flex;
          justify-content: space-around;
          .gem_item {
            position: relative;
            width: 54px;
            height: 100%;
            border-radius: 6px;
            background-color: #8149ec;
            padding: 0 4px;
            margin-right: 5px;
            .gem_img {
              width: 38px;
              height: 38px;
              margin: 0 auto;
            }

            .gem_num {
              height: 16px;
              border-radius: 8px;
              background-color: #352959;
              font-size: 10px;
              line-height: 16px;
              text-align: center;
              position: absolute;
              width: 90%;
              bottom: 2px;
              left: 50%;
              transform: translate(-50%);
            }
          }
        }

        .exchange_icon {
          width: 40px;
          height: 40px;
          background-image: url("@/assets/images/gemShop/exchange_right.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 5px;
        }

        .exchange_target_goods {
          position: relative;
          border-radius: 6px;
          width: 96px;
          height: 62px;
          background-color: #8149ec;

          .goods_cover {
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 50px;
            height: 50px;
          }

          .goods_name {
            position: absolute;
            bottom: 2px;
            left: 50%;
            transform: translate(-50%);
            width: 86px;
            height: 16px;
            background-color: #352959;
            border-radius: 8px;
            text-align: center;
            line-height: 16px;
            font-size: 10px;
            white-space: nowrap;
          }
        }
      }

      .exchange_stepper_wrapper {
        margin-top: 20px;
        height: 24px;
        line-height: 24px;
        padding: 0 60px;
        display: flex;
        justify-content: space-between;
        .stepper_text {
          width: 40px;
          text-align: center;
          font-size: 14px;
        }

        .stepper_wrap {
          width: 150px;
          display: flex;
          justify-content: space-between;

          .stepper_cotent {
            width: 80px;
            background-color: #352959;
            text-align: center;
          }

          .sub_count_btn {
            width: 24px;
            height: 100%;
            background-image: url("@/assets/images/longshop/count_sub.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .add_count_btn {
            @extend .sub_count_btn;
            background-image: url("@/assets/images/longshop/count_add.png");
          }
        }
      }
      .exchange_btn {
        margin: 18px auto 0 auto;
        width: 120px;
        height: 30px;
        text-align: center;
        background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
        color: #693015;
        font-size: 16px;
        line-height: 30px;
        border-radius: 16px;
        font-weight: bold;
      }
    }
  }

  .user_gem_info_top_wrap {
    position: absolute;
    left: 50%;
    top: 0px;
    height: 18px;
    transform: translate(-40%, -140%);
    // background-color: pink;
    line-height: 18px;
    display: flex;
    .text_ {
      font-size: 12px;
      white-space: nowrap;
    }

    .my_gem_item {
      position: relative;
      width: 48px;
      height: 18px;
      border-radius: 10px;
      background-color: #35295980;
      border: 1px solid #8149ec;
      text-align: right;
      padding-right: 6px;
      margin-left: 10px;
      .gem_icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        transform: translate(-4px, -2px);
      }
    }
  }

  .user_info_block {
    position: absolute;
    left: 12px;
    top: 100px;

    .top_block {
      display: flex;
      align-items: center;
      .user_head {
        width: 26px;
        height: 26px;
        margin-right: 6px;
      }
    }

    .user_level_wrapp {
      margin-top: 6px;
    }
  }

  .user_buff_block {
    position: absolute;
    left: 18px;
    top: 216px;
    min-width: 66px;
    height: 48px;
    // padding: 0 5px;
    background-color: #501abc;
    border-radius: 5px;

    .card_list {
      display: flex;
      .use_buff_card {
        width: 30px;
        height: 30px;
      }
    }

    .text {
      position: absolute;
      left: 50%;
      bottom: 4px;
      transform: translate(-50%, 0);
      width: 33px;
      height: 10px;
      font-size: 10px;
      font-size: 8px;
      line-height: 10px;
      border-radius: 6px;
      text-align: center;
      color: #93f6fc;
      background-color: #352959;
    }
  }

  .shop_cotainer {
    position: absolute;
    left: 112px;
    top: 60px;
    width: 646px;
    height: 282px;
    background-image: url("@/assets/images/gemShop/shop_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .rule_en_text {
      position: absolute;
      right: 50px;
      top: -30px;
      font-size: 12px;
      display: flex;
      align-items: center;
      .wh_icon {
        width: 12px;
        height: 12px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/wenhao.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 4px;
      }
    }

    .logo_wrapper {
      position: absolute;
      top: -38px;
      left: -8px;
      width: 200px;
      height: 55px;
      background-image: url("@/assets/images/gemShop/logo.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 2;
    }

    .type_wrapper {
      position: absolute;
      left: -8px;
      top: 0px;
      width: 48px;
      height: 232px;
      background-image: url("@/assets/images/gemShop/tab_1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 1;

      .type_bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .type_item {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 50%;
      }
    }

    .shop_content {
      position: absolute;
      top: 10px;
      left: 42px;
      width: 564px;
      height: 262px;

      // background-color: pink;

      .goods_list_wrapper {
        width: 100%;
        height: 100%;
        padding: 0px 12px 20px 12px;
        overflow: auto;
        .goods_list {
          display: flex;
          flex-wrap: wrap;

          .goods_item {
            position: relative;
            width: 126px;
            // height: 180px;
            // min-height: 160px;
            padding-bottom: 40px;
            background-color: #501abc;
            margin-right: 10px;
            margin-top: 10px;
            border-radius: 6px;
            &:nth-child(4n) {
              margin-right: 0;
            }

            .goods_name {
              height: 24px;
              text-align: center;
              // line-height: 24px;
              height: 24px;
              font-size: 12px;
              width: 100%;

              display: flex;
              justify-content: center;
              align-items: center;
              .name_text {
                padding: 0 4px;
                height: 18px;
                white-space: nowrap;
                font-weight: bold;
                background-color: #a524c1;
              }
            }

            .goods_cover {
              // position: absolute;
              // top: 22px;
              // left: 50%;
              width: 70px;
              height: 70px;
              margin: 0 auto;

              // transform: translateX(-50%);
              // background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
              // background-size: 52px 52px;
              // background-position: center center;
              // background-repeat: no-repeat;
            }

            .goods_price {
              width: 100%;
              min-height: 20px;
              padding: 0 6px;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              align-items: center;

              .price_item {
                position: relative;
                width: 54px;
                height: 20px;
                border-radius: 10px;
                padding: 0 6px 0 26px;
                background-color: #352959;
                text-align: center;
                margin-top: 5px;

                .gem_icon {
                  position: absolute;
                  top: -2px;
                  left: -2px;
                  width: 24px;
                  height: 24px;

                  .gem_lock {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 14px;
                    height: 14px;
                    background-image: url("@/assets/images/slots/lock.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    z-index: 3;
                  }
                }
              }
            }

            .pay_btn {
              position: absolute;
              bottom: 10px;
              left: 50%;
              transform: translateX(-50%);
              width: 80px;
              height: 22px;
              // margin: 10px auto;
              line-height: 22px;
              text-align: center;
              background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
              border-radius: 12px;
              color: #693015;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }

      .user_gem_info_wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        .user_gem_list {
          position: absolute;
          top: 40px;
          width: 100%;
          height: 180px;
          display: flex;
          // align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          .user_gem_item {
            position: relative;
            width: 100px;

            height: 100%;
            background-color: #501abc;
            border-radius: 6px;
            // margin-right: 10px;
            .user_gem_name {
              position: absolute;
              width: 42px;
              height: 22px;
              top: 0px;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .user_gem_wrap {
              position: absolute;
              top: 20px;
              left: 50%;
              transform: translateX(-50%);
              width: 82px;
              height: 98px;
              background-color: #8149ec;
              border-radius: 6px;

              .user_gem_img {
                position: absolute;
                left: 50%;
                top: 4px;
                width: 80px;
                height: 80px;
                transform: translate(-50%, 0);
              }

              .user_gem_lock {
                position: absolute;
                left: 50%;
                top: 48px;
                transform: translate(-50%, 0);
                width: 38px;
                height: 38px;
                background-image: url("@/assets/images/slots/lock.png");
                background-size: 100% 100%;
                // background-position: center center;
                background-repeat: no-repeat;
              }
              .lock_level_text {
                position: absolute;
                width: 100%;
                bottom: 0;
                font-size: 10px;
                text-align: center;
              }
            }

            .user_gem_name_wrapper {
              position: absolute;
              bottom: 16px;
              left: 50%;
              transform: translateX(-50%);
              width: 82px;
              height: 25px;
              background-color: #352959;
              border-radius: 6px;
              display: flex;
              justify-content: space-between;
              padding: 0 6px;
              line-height: 25px;
              .span_1 {
                font-size: 10px;
              }
              .span_2 {
                font-size: 14px;
              }
            }
          }
        }

        .clear_text {
          position: absolute;
          bottom: 14px;
          left: 50%;
          transform: translate(-50%);
          font-size: 12px;
          color: #ffffff60;
        }
      }
    }
  }

  .reward_block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .reward_item {
      position: relative;
      width: 48px;
      height: 68px;
      .reward_type {
        width: 48px;
        height: 48px;
        background-color: #9369e1;
        border-radius: 6px;
      }

      .reward_num {
        position: absolute;
        left: 50%;
        bottom: 0;
        height: 20px;
        transform: translateX(-50%);
        text-align: center;
        white-space: nowrap;
        color: #724026;
        font-size: 14px;
        padding: 4px 0;
      }
    }
  }

  .rule_wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background-color: rgba($color: #000000, $alpha: 0.3);

    .rule_block {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 335px;
      height: 260px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/sm_pup_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translate(-50%, -50%);

      .rule_close_icon {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 30px;
        height: 30px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 99;
      }
      .title {
        position: absolute;
        width: 120px;
        height: 36px;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        line-height: 36px;
        font-size: 16px;
        color: #621064;
      }
      .content {
        position: absolute;
        width: 84%;
        left: 50%;
        top: 50px;
        transform: translate(-50%, 0);
        max-height: 200px;
        overflow: auto;
        line-height: 22px;
      }
    }
  }
}
</style>
