<template>
  <div
    class="record-wrapper"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <van-nav-bar
      title="记录查询"
      left-arrow
      @click-left="router.back()"
      :border="false"
      :placeholder="true"
      :right-text="`UID:${store.userInfo.uid}`"
    >
    </van-nav-bar>

    <van-tabs
      v-model:active="curLogType"
      background="#3a1b67"
      title-active-color="#fff"
      title-inactive-color="rgba(255,255,255,0.5)"
      swipeable
      @change="recordTabChange"
    >
      <van-tab
        :title="logItem.text"
        :name="logItem.type"
        v-for="logItem in logTabList"
        :key="logItem.type"
      >
        <div class="record_list_wrapper">
          <div
            class="record_list_item"
            v-for="(recordItem, recordIndex) in store.recordDataList[
              logItem.type
            ]"
            :key="'record_' + recordIndex"
          >
            <div class="record_list_item_left">
              <p class="record_text">
                {{ recordItem.text }}
              </p>
              <p class="record_text" v-if="recordItem.text2 != ''">
                {{ recordItem.text2 }}
              </p>
              <p class="record_time">{{ recordItem.time }}</p>
            </div>
            <div class="record_list_item_right">
              <div class="item_type" v-if="recordItem.gold != 0">
                <div
                  :class="
                    recordItem.gold > 0
                      ? 'item_type_number_add'
                      : 'item_type_number_minus'
                  "
                >
                  <span
                    v-if="logItem.type == 'game_log' && recordItem.gold > 0"
                    style="color: #fff"
                  >
                    {{ recordItem.gold }}
                  </span>
                  <span v-else>
                    {{
                      recordItem.gold > 0
                        ? "+" + recordItem.gold
                        : recordItem.gold
                    }}
                  </span>
                </div>

                <div class="item_type_icon item_type_gold_icon"></div>
              </div>
              <div class="item_type" v-if="recordItem.add_gold != 0">
                <div
                  :class="
                    recordItem.add_gold > 0
                      ? 'item_type_number_add'
                      : 'item_type_number_minus'
                  "
                >
                  <span style="white-space: nowrap">
                    等级加成：{{
                      recordItem.add_gold > 0
                        ? "+" + recordItem.add_gold
                        : recordItem.add_gold
                    }}
                  </span>
                </div>

                <div class="item_type_icon item_type_gold_icon"></div>
              </div>

              <div
                class="item_type"
                v-if="recordItem.add_mul && recordItem.add_mul != 0"
              >
                <div
                  :class="
                    recordItem.add_gold > 0
                      ? 'item_type_number_add'
                      : 'item_type_number_minus'
                  "
                >
                  <span style="white-space: nowrap">
                    加成卡：{{
                      recordItem.add_mul > 0
                        ? "+" + recordItem.add_mul
                        : recordItem.add_mul
                    }}
                  </span>
                </div>

                <div class="item_type_icon item_type_gold_icon"></div>
              </div>

              <div class="item_type" v-if="recordItem.lottery != 0">
                <div
                  :class="
                    recordItem.lottery > 0
                      ? 'item_type_number_add'
                      : 'item_type_number_minus'
                  "
                >
                  {{
                    recordItem.lottery > 0
                      ? "+" + recordItem.lottery
                      : recordItem.lottery
                  }}
                </div>

                <div class="item_type_icon item_type_lottery_icon"></div>
              </div>
              <div class="item_type" v-if="recordItem.tiaozhan_ticket != 0">
                <div
                  :class="
                    recordItem.tiaozhan_ticket > 0
                      ? 'item_type_number_add'
                      : 'item_type_number_minus'
                  "
                >
                  {{
                    recordItem.tiaozhan_ticket > 0
                      ? "+" + recordItem.tiaozhan_ticket
                      : recordItem.tiaozhan_ticket
                  }}
                </div>

                <div
                  class="item_type_icon item_type_tiaozhan_ticket_icon"
                ></div>
              </div>
              <div
                class="item_type"
                v-if="recordItem.dashi_score && recordItem.dashi_score != 0"
              >
                <div class="item_type_number_add">
                  大师分
                  {{
                    recordItem.dashi_score > 0
                      ? "+" + recordItem.dashi_score
                      : recordItem.dashi_score
                  }}
                </div>

                <!-- <div
                  class="item_type_icon item_type_tiaozhan_ticket_icon"
                ></div> -->
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, onBeforeMount } from "vue";
import { sendInstructions } from "@/api/sendData";
import { useStore } from "@/store/store";
import { showToast } from "vant";
import { getRankLog } from "@/api/index";
import { formatTimestamp } from "@/utils/utils.js";
const store = useStore();
const router = useRouter();
const curLogType = ref("game_log");

const logTabList = [
  {
    text: "电玩记录",
    type: "game_log",
  },
  {
    text: "电玩币记录",
    type: "gold_log",
  },
  {
    text: "充值记录",
    type: "recharge_log",
  },
  {
    text: "挑战卡记录",
    type: "tiaozhan_ticket_log",
  },
  {
    text: "积分记录",
    type: "lottery_log",
  },
  {
    text: "大师分记录",
    type: "dashi_score_log",
  },
  {
    text: "赛事记录",
    type: "active_log",
  },
];

const recordTabChange = (name, title) => {
  // store.$patch((state) => {
  //   state.recordDataList = [];
  // });`
  // 	{
  // 	"index": 2,
  // 	"num": 8200,
  // 	"uid": 13,
  // 	"time": 1710314400,
  // 	"reward_data": {
  // 		"reward_type": "gold",
  // 		"reward": 2000,
  // 		"reward_name": "gold"
  // 	},
  // 	"active_name": null,
  // 	"player_name": "_Test_"
  // }
  // 23-11/17 需求修改 - -
  if (name == "active_log") {
    getRankLog({ uid: parseInt(store.userInfo.uid) }).then((res) => {
      if (res.data) {
        const list = [];
        res.data.forEach((item) => {
          const iitem = {
            time: formatTimestamp(item.time),
            text: `${item.active_name} 第${item.index}名`,
            type: "active_log",
            lottery: 0,
            gold: 0,
            tiaozhan_ticket: 0,
            dashi_score: 0,
            add_gold: 0,
          };
          if (item.reward_data.reward_type == "gold") {
            iitem.gold = item.reward_data.reward;
          }
          if (item.reward_data.reward_type == "lottery") {
            iitem.lottery = item.reward_data.reward;
          }
          list.push(iitem);
        });

        store.$patch((state) => {
          state.recordDataList.active_log = list;
        });
      }
    });

    return;
  }

  const params = {
    msg_type: "get_all_log",
    type: name,
  };

  sendInstructions(params);
};

onBeforeMount(() => {
  recordTabChange("game_log", null);

  if (store.initPayPupData.base.length == 0) {
    sendInstructions(
      {
        msg_type: "get_player_recharge_status",
      },
      50
    );
  }
});
</script>

<style lang="scss" scoped>
.record-wrapper {
  overflow: hidden;
  background: linear-gradient(
    270deg,
    rgba(149, 69, 226, 0.2) 0%,
    rgba(149, 69, 226, 0.5) 100%
  );
  min-height: 100vh;
  .record_list_wrapper {
    padding: 0 15px;
    min-height: 80vh;
    max-height: 86vh;
    overflow-y: auto;
    padding-bottom: 20px;
    .record_list_item {
      margin-top: 10px;
      background: linear-gradient(
        270deg,
        rgba(149, 69, 226, 0.2) 0%,
        rgba(149, 69, 226, 0.5) 100%
      );

      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .record_list_item_left {
        flex: 1;
        width: calc(100% - 90px);
        overflow: hidden;
        .record_text {
          width: 100%;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .record_time {
          margin-top: 2px;
          color: #fff;
        }
      }

      .record_list_item_right {
        display: flex;
        // max-width: 90px;
        flex-direction: column;
        justify-content: center;
        .item_type {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .item_type_number_add {
            color: #fee22a;
            line-height: 20px;
            margin-right: 4px;
          }
          .item_type_number_minus {
            color: #ff4016;
            line-height: 20px;
            margin-right: 4px;
          }

          .item_type_icon {
            width: 20px;
            height: 20px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .item_type_gold_icon {
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
          }
          .item_type_lottery_icon {
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
          }
          .item_type_tiaozhan_ticket_icon {
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/icon_card.png");
          }
        }
      }
    }
  }
}
</style>
