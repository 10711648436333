<template>
  <div class="reward_pup_wrapper" v-if="props.show">
    <div class="_mask" v-if="props.mask"></div>

    <div class="win_pup_wrap scale_in_center_class">
      <div class="win_content"><slot></slot></div>
      <div class="confirm_btn" @click="handleConfirm">
        {{ props.confirm_text }}
      </div>
    </div>
  </div>
</template>

<script setup>
import openBoxHint from "@/assets/audios/sign_hint.mp3";
import { Howl, Howler } from "howler";
const openBoxSound = new Howl({
  src: [openBoxHint],
  autoplay: false,
  loop: false,
});
const props = defineProps({
  show: {
    type: Boolean,
    default: () => false,
  },
  mask: {
    type: Boolean,
    default: () => false,
  },
  confirm_text: {
    type: String,
    default: () => "确认",
  },
});

const emits = defineEmits(["confirm"]);

const handleConfirm = () => {
  openBoxSound.play();
  emits("confirm");
};
</script>

<style lang="scss" scoped>
.reward_pup_wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;

  ._mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }

  .win_pup_wrap {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 350px;
    height: 350px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/win_pup_bg_2.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    padding-top: 150px;
    transform: translate(-50%, -50%);
    z-index: 2;

    .win_content {
      //   background-color: skyblue;
      width: 100%;
      height: 100px;
    }

    .confirm_btn {
      position: absolute;
      bottom: 70px;
      left: 50%;
      width: 120px;
      height: 32px;
      transform: translate(-50%, 20px);
      color: #693015;
      line-height: 32px;
      text-align: center;
      border-radius: 16px;
      font-size: 16px;
      background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
    }
  }
}
</style>
