<template>
  <div class="halloween_view">
    <NavBar fixed />
    <div class="sm_text_img"></div>

    <div class="hw_my_collect_wrap">
      <div class="lingpai_num">{{ user_collect_data.lingpai }}</div>
      <div class="nangua_num">{{ user_collect_data.kill_boss_1 }}</div>
      <div class="sisheng_num">{{ user_collect_data.kill_boss_3 }}</div>
    </div>
    <div class="hw_shop_info_wrap">
      <div class="shop_row" v-for="(shopValue, shopType) in shop_info">
        <div class="shop_goods_item" v-for="goods in shopValue">
          <div class="goods_cover">
            <div class="goods_cover_img">
              <van-image
                width="100%"
                :show-loading="false"
                height="100%"
                fit="fill"
                position="center"
                :src="goods.cover"
              />
            </div>

            <div
              class="goods_num"
              :style="{
                fontSize: shopType == 'chenghao_shop' ? '0.2667rem' : '0.32rem',
                textAlign: shopType == 'chenghao_shop' ? 'center' : 'right',
              }"
            >
              {{ goods.goods_num }}
            </div>
          </div>
          <div class="goods_price">
            <div class="price_item" v-for="pI in goods.price">
              <div class="price_icon">
                <van-image
                  width="100%"
                  :show-loading="false"
                  height="100%"
                  fit="fill"
                  position="center"
                  :src="icons_maps[pI.type]"
                />
              </div>
              <span>{{ pI.num }}</span>
            </div>
          </div>

          <div class="exchange_btn" @click="handleExchangeGoods(goods)">
            兑换
          </div>
          <div class="limit_exchange_text">
            {{
              shopType == "gold_shop"
                ? `每日可兑 ${goods.buy_num}/${goods.max_buy_num}`
                : `活动期可兑 ${goods.buy_num}/${goods.max_buy_num}`
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="hw_sm_text2">
      1、活动截止时间为：11月9日23点59分59秒。 <br />
      2、门槛说明：参与本次活动的房间门槛为10币房、20币房以及30币房，魔鬼城5币房不参与此次活动。其中20币房
      “赏金令牌”道具掉落x2，30币房“赏金令牌”道具掉落x3。<br />
      3、奖励兑换说明：在活动期间玩家完成任务获得所有奖项道具为“赏金令牌”，其可用以兑换电玩币、充值加成卡奖励，完成亡魂BOSS挑战可兑换限定头像框奖励。金币档位奖励每天兑换上限为2次，加成卡和头像框奖励兑换上限次数为1次。<br />
      4、本活动最终解释权归“极客竞技App”所有。<br />
    </div>

    <RewardPup
      :show="exchangeSuccShow"
      @confirm="exchangeSuccShowToggle(false)"
      mask
    >
      <div class="exchange_gggoods_wrap">
        <div class="wwit">
          <div class="wwit_img">
            <van-image
              width="100%"
              :show-loading="false"
              height="100%"
              fit="fill"
              position="center"
              :src="curExchangeTarget.cover"
            />
          </div>

          <div class="wwit_num">
            {{ curExchangeTarget.goods_num }}
          </div>
        </div>
      </div>
    </RewardPup>

    <van-overlay
      :show="exchangeShow"
      z-index="2000"
      @click="exchangeShowToggle(false)"
    >
      <div class="exchange_pup_wrap" @click.stop>
        <div class="exchange_info_wrap">
          <div class="wwit" v-for="pi in curExchangeTarget.price">
            <div class="wwit_img">
              <van-image
                width="100%"
                :show-loading="false"
                height="100%"
                fit="fill"
                position="center"
                :src="icons_maps[pi.type]"
              />
            </div>

            <div class="wwit_num">
              {{ pi.num }}
            </div>
          </div>

          <div class="exchange_icon"></div>

          <div class="wwit">
            <div class="wwit_img">
              <van-image
                width="100%"
                :show-loading="false"
                height="100%"
                fit="fill"
                position="center"
                :src="curExchangeTarget.cover"
              />
            </div>

            <div class="wwit_num">
              {{ curExchangeTarget.goods_num }}
            </div>
          </div>
        </div>

        <div class="exchange_confirm_btn" @click="confirmExchange">
          确定兑换
        </div>
      </div>
    </van-overlay>

    <!-- <div class=" scale_in_center_class" v-show="exchangeShow"></div> -->
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import RewardPup from "@/components/RewardPup.vue";
import { asyncSendInstructions } from "@/api/sendData";
import { ref, reactive, onBeforeMount } from "vue";
import { useStore } from "@/store/store";
import Decimal from "decimal.js";
import ng_icon from "@/assets/images/active/hw_ng.png";
import ss_icon from "@/assets/images/active/hw_ss.png";
import lp_icon from "@/assets/images/active/hw_lp.png";

import hw_goods_gold_1 from "@/assets/images/active/hw_goods_gold.png";
import hw_goods_gold_2 from "@/assets/images/active/hw_goods_gold_2.png";
const jiacheng_card_img =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/jiacheng_card.png";
const h_31_img =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_31.png";
const h_32_img =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_32.png";

import { closeToast, showToast } from "vant";
import { useToggle } from "@vant/use";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();

const icons_maps = {
  kill_boss_1: ng_icon,
  kill_boss_3: ss_icon,
  lingpai: lp_icon,
};
const goods_cover_maps = {
  1: hw_goods_gold_1,
  2: hw_goods_gold_2,
  3: jiacheng_card_img,
  4: jiacheng_card_img,
  5: jiacheng_card_img,
  6: h_31_img,
  7: h_32_img,
};

const user_collect_data = reactive({
  lingpai: 0,
  kill_boss_1: 0,
  kill_boss_3: 0,
});

const shop_info = reactive({
  gold_shop: [],
  card_shop: [],
  chenghao_shop: [],
});

const initConfigData = async () => {
  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_halloween",
    });

    shop_info.gold_shop.length = 0;
    shop_info.card_shop.length = 0;
    shop_info.chenghao_shop.length = 0;

    const { player_halloween, shop } = res;

    user_collect_data.lingpai = player_halloween.lingpai;
    user_collect_data.kill_boss_1 = player_halloween.kill_boss["1"];
    user_collect_data.kill_boss_3 = player_halloween.kill_boss["3"];

    for (const _ikey in shop) {
      const item = shop[_ikey];
      const goodsItem = {
        goods_index: _ikey,
        max_buy_num: 1,
        buy_num: player_halloween.shop[_ikey].num,
        limit_level: 1,
        goods_type: item.reward.type,
        goods_num: item.reward.num ?? 1,
        cover: goods_cover_maps[_ikey],
        price: [],
      };

      let _max_buy_num = 0;
      item.buy_num.forEach((ittt) => {
        if (store.userInfo.recharge_level >= ittt.min) {
          _max_buy_num = ittt.max_num;
        }
      });

      goodsItem.max_buy_num = _max_buy_num;

      item.condition.forEach((pi) => {
        const _pI = {
          type: pi.type,
          num: pi.num,
        };

        if (pi.type == "kill_boss") {
          _pI.type = _pI.type + "_" + pi.class_id;
        }

        goodsItem.price.push(_pI);
      });

      if (item.reward.type == "gold") {
        shop_info.gold_shop.push(goodsItem);
      }
      if (item.reward.type == "jiacheng_card") {
        const _add_v = new Decimal(item.reward.num).sub(1).mul(100);

        goodsItem.goods_num = "+ " + _add_v + " %";
        shop_info.card_shop.push(goodsItem);
      }
      if (item.reward.type == "chenghao") {
        goodsItem.goods_num =
          item.reward.title_name == "nangualieshou" ? "南瓜猎手" : "幻影死神";
        shop_info.chenghao_shop.push(goodsItem);
      }
    }
  } catch (error) {
    showToast({
      type: "fail",
      message: "活动已结束",
      onClose() {
        router.replace("/home");
      },
    });
  }
};

const [exchangeSuccShow, exchangeSuccShowToggle] = useToggle(false);
const [exchangeShow, exchangeShowToggle] = useToggle(false);

const curExchangeTarget = ref({
  goods_index: 0,
  max_buy_num: 1,
  buy_num: 0,
  limit_level: 1,
  goods_type: "gold",
  goods_num: 1,
  cover: "",
  price: [],
});

const handleExchangeGoods = async (goods) => {
  curExchangeTarget.value = goods;

  exchangeShowToggle(true);
};

const confirmExchange = async () => {
  const goods = curExchangeTarget.value;

  if (goods.buy_num >= goods.max_buy_num) {
    showToast({
      type: "fail",
      message: "兑换已上限",
    });

    return;
  }

  const isCanBuy = goods.price.some(
    (pI) => user_collect_data[pI.type] < pI.num
  );

  if (isCanBuy) {
    showToast({
      type: "fail",
      message: "兑换材料不足",
    });
    return;
  }
  showToast({
    type: "loading",
    duration: 0,
    forbidClick: true,
  });
  exchangeShowToggle(false);
  try {
    const res = await asyncSendInstructions({
      msg_type: "halloween_shop",
      index: goods.goods_index,
    });

    closeToast();
    console.log("resresres", res);

    if (res.error == "ok") {
      // showToast({
      //   type: "success",
      //   message: "兑换成功",
      // });

      exchangeSuccShowToggle(true);

      const { player_halloween, reward } = res;
      user_collect_data.lingpai = player_halloween.lingpai;
      user_collect_data.kill_boss_1 = player_halloween.kill_boss["1"];
      user_collect_data.kill_boss_3 = player_halloween.kill_boss["3"];

      const dataKey = {
        gold: "gold_shop",
        jiacheng_card: "card_shop",
        chenghao: "chenghao_shop",
      };

      const isRget = shop_info[dataKey[reward.type]].some((item) => {
        if (item.goods_index == goods.goods_index) {
          item.buy_num = player_halloween.shop[goods.goods_index].num;
          return true;
        } else return false;
      });

      if (reward.type == "gold") {
        store.$patch((state) => {
          state.userInfo.gold = state.userInfo.gold + Number(reward.num);
        });
      }

      if (!isRget) initConfigData();
    } else throw new Error(res.error);
  } catch (error) {
    console.log("errorrrrr", error);

    closeToast();
    initConfigData();
    showToast({
      type: "fail",
      message: "请稍后重试",
    });
  }
};

onBeforeMount(() => {
  initConfigData();
});
</script>

<style lang="scss" scoped>
.halloween_view {
  min-height: 100vh;
  background-color: #11071c;
  background-image: url("@/assets/images/active/hw_top_img.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

  padding-bottom: 40px;

  .sm_text_img {
    height: 388px;
    background-image: url("@/assets/images/active/hw_sm_text.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  .hw_my_collect_wrap {
    position: relative;
    height: 330px;
    background-image: url("@/assets/images/active/hw_my_collect.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    .lingpai_num {
      position: absolute;
      bottom: 40px;
      left: 55px;
      width: 75px;
      height: 14px;
      text-align: center;
      color: #fee22a;
      line-height: 14px;
    }

    .nangua_num {
      @extend .lingpai_num;
      left: 150px;
    }

    .sisheng_num {
      @extend .nangua_num;
      left: 245px;
    }
  }

  .hw_shop_info_wrap {
    height: 679px;
    background-image: url("@/assets/images/active/hw_shop_info.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 70px 40px 0 40px;
    .shop_row {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;

      .shop_goods_item {
        position: relative;
        width: 96px;
        // height: 166px;
        // min-height: ;
        // padding-top: 96px;
        .goods_cover {
          position: relative;
          width: 80px;
          height: 80px;
          margin: 0 auto;
          background: linear-gradient(180deg, #6e4de0 0%, #4b23a4 100%);
          box-shadow: inset 0px 0px 4px 3px rgba(175, 150, 255, 0.8),
            0px 0px 5px 1px rgba(165, 136, 255, 0.5);
          border-radius: 5px 5px 5px 5px;
          border: 2px solid #6821df;

          .goods_cover_img {
            position: absolute;
            left: 50%;
            top: 2px;
            transform: translateX(-50%);
            width: 60px;
            height: 60px;
          }

          .goods_num {
            position: absolute;
            width: 100%;
            padding: 0 4px;
            bottom: 2px;
            text-shadow: 1px 2px 1px #000000;
          }
        }
        .goods_price {
          margin-top: 8px;
          width: 106px;
          transform: translateX(-5px);
          height: 20px;

          display: flex;
          justify-content: center;

          .price_item {
            position: relative;
            width: 48px;
            height: 20px;
            line-height: 20px;
            background-color: #4e27a9;
            border-radius: 10px;
            padding-left: 10px;
            padding-right: 6px;
            white-space: nowrap;
            text-align: center;
            &:nth-child(2) {
              margin-left: auto;
            }
            .price_icon {
              position: absolute;
              left: 0;
              top: 0;
              transform: translate(-8px, -4px);
              width: 24px;
              height: 24px;
            }
          }
        }
        .exchange_btn {
          width: 70px;
          height: 24px;
          margin: 0 auto;
          margin-top: 10px;
          background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
          border-radius: 12px;
          line-height: 24px;
          text-align: center;
          color: #693015;
          font-weight: bold;
        }

        .limit_exchange_text {
          margin-top: 6px;
          text-align: center;
          width: 100%;
          font-size: 10px;
          color: #ffffff60;
        }
      }
    }
  }

  .hw_sm_text2 {
    padding: 80px 40px 0 40px;
    height: 323px;
    background-image: url("@/assets/images/active/hw_sm_text2.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .exchange_pup_wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 280px;
    height: 243px;
    transform: translate(-50%, -50%);
    background-image: url("@/assets/images/active/hw_exchange_pup.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    .exchange_info_wrap {
      position: absolute;
      top: 90px;
      left: 0;
      width: 100%;
      height: 52px;
      padding: 0 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .wwit {
        position: relative;
        width: 52px;
        height: 52px;
        background: linear-gradient(180deg, #6e4de0 0%, #4b23a4 100%);
        box-shadow: inset 0px 0px 4px 3px rgba(175, 150, 255, 0.8),
          0px 0px 5px 1px rgba(165, 136, 255, 0.5);
        border-radius: 5px 5px 5px 5px;
        border: 2px solid #6821df;
        margin-right: 10px;

        .wwit_img {
          position: absolute;
          width: 42px;
          height: 42px;
          left: 50%;
          top: 0px;
          transform: translate(-50%);
        }
        .wwit_num {
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: right;
          font-size: 10px;
          text-shadow: 1px 2px 1px #000000;
        }
      }

      .exchange_icon {
        width: 40px;
        height: 40px;
        background-image: url("@/assets/images/gemShop/exchange_right.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // margin-right: 5px;
      }
    }

    .exchange_confirm_btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      width: 120px;
      height: 30px;
      background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
      text-align: center;
      line-height: 30px;
      color: #693015;
      font-size: 14px;
      border-radius: 16px;
      font-weight: bold;
    }
  }

  .exchange_gggoods_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .wwit {
      position: relative;
      width: 52px;
      height: 52px;
      background: linear-gradient(180deg, #6e4de0 0%, #4b23a4 100%);
      box-shadow: inset 0px 0px 4px 3px rgba(175, 150, 255, 0.8),
        0px 0px 5px 1px rgba(165, 136, 255, 0.5);
      border-radius: 5px 5px 5px 5px;
      border: 2px solid #6821df;
      margin-right: 10px;

      .wwit_img {
        position: absolute;
        width: 42px;
        height: 42px;
        left: 50%;
        top: 0px;
        transform: translate(-50%);
      }
      .wwit_num {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: right;
        font-size: 10px;
        text-shadow: 1px 2px 1px #000000;
      }
    }
  }
}
</style>
