<template>
  <van-popup
    v-model:show="store.payPupStatu.show"
    :style="popStyle"
    @open="whenPupOpenEvent"
    @close="whenPupCloseEvent"
    position="right"
  >
    <div class="recharge_wrapper">
      <div class="container_rotate" id="_rotate_box" :style="container_style">
        <!-- 竖屏 -->
        <template v-if="!store.isLandscape">
          <div
            class="flex_column_box"
            :style="{
              paddingTop: store.areaInsetTopValue + 'px',
            }"
          >
            <div class="nav_bar_wrapper">
              <div class="nav_bar_text"></div>

              <div
                class="nav_bar_back_wrapper"
                @click="store.payPupToggle(false)"
              ></div>
            </div>

            <div class="user_info_wrapper">
              <div class="user_info">
                <div class="info">
                  <div class="head">
                    <HeadPortrait
                      :level="Number(store.userInfo.recharge_level)"
                      :src="store.userInfo.headimgurl"
                      width="100%"
                      height="100%"
                      :ae_id="store.userInfo.select_title"
                      ae
                      fe
                    />
                  </div>
                  <div class="user_name">{{ store.userInfo.player_name }}</div>
                </div>
                <div class="lottery_wrapper">
                  {{ addCommas(store.userInfo.lottery) }}
                </div>
                <div class="gold_wrapper">
                  {{ addCommas(store.userInfo.gold) }}
                </div>
              </div>
            </div>

            <div class="tabs_wrapper">
              <van-config-provider :theme-vars="tabsVars">
                <van-tabs
                  v-model:active="currentPayType"
                  swipeable
                  swipe-threshold="4"
                >
                  <van-tab
                    v-for="payTypeItem in store.initPayPupData.typeList"
                    :title="payTypeTitle[payTypeItem].title"
                    :name="payTypeItem"
                  >
                    <div class="pay_container_wrapper">
                      <div class="pay_list_tips">
                        <span>您是</span>
                        <Level
                          style="margin: 0 6px"
                          level_type="recharge_level"
                          :level_value="Number(store.userInfo.recharge_level)"
                        />
                        <span>等級用戶,充值额外获得</span>
                        <span
                          >{{
                            store.initPayPupData.userPayInfo.extraGoldRatio
                          }}%</span
                        >
                      </div>
                      <!-- 基础礼包 -->
                      <template v-if="payTypeItem == 'base'">
                        <div class="pay_list_wrapper">
                          <div
                            class="pay_list_item"
                            v-for="(payItem, payListIndex) in store
                              .initPayPupData[payTypeItem]"
                            @click="selectPayItem(payItem)"
                          >
                            <div
                              class="yield_wrapper"
                              v-if="payItem.yield > 100"
                            >
                              收益{{ payItem.yield }}%
                            </div>
                            <div class="gold_wrapper">
                              <div class="gold_icon_wrapper">
                                <van-image
                                  width="100%"
                                  height="100%"
                                  fit="fill"
                                  position="center"
                                  :show-loading="false"
                                  :src="payItemiconList[payListIndex]"
                                />
                              </div>

                              <div class="gold_number">
                                {{ payItem.gold }} 币
                              </div>
                            </div>

                            <div class="price_wrapper">
                              <div class="price_btn">
                                ￥ {{ payItem.price }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- 首冲礼包 -->
                      <template v-if="payTypeItem == 'first'">
                        <div class="pay_list_wrapper">
                          <div
                            class="pay_list_item"
                            v-for="(payItem, payListIndex) in store
                              .initPayPupData[payTypeItem]"
                            @click="selectPayItem(payItem, payListIndex)"
                          >
                            <div
                              class="yield_wrapper"
                              v-if="payItem.yield > 100"
                            >
                              收益{{ payItem.yield }}%
                            </div>

                            <div class="first_icon"></div>
                            <div class="sold_out" v-if="!payItem.isPay"></div>

                            <div class="gold_wrapper">
                              <div class="gold_icon_wrapper">
                                <van-image
                                  width="100%"
                                  height="100%"
                                  fit="fill"
                                  position="center"
                                  :show-loading="false"
                                  :src="payItemiconList[payListIndex]"
                                />
                              </div>
                              <div class="gold_number">
                                {{ payItem.gold }} 币
                              </div>
                            </div>

                            <div class="price_wrapper">
                              <div class="price_btn">
                                ￥ {{ payItem.price }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- 每日礼包 -->
                      <template v-if="payTypeItem == 'day'">
                        <div class="pay_list_wrapper">
                          <div
                            class="pay_list_item"
                            v-for="(payItem, payListIndex) in store
                              .initPayPupData[payTypeItem]"
                            @click="selectPayItem(payItem, payListIndex)"
                          >
                            <template v-if="payListIndex == 0">
                              <van-image
                                width="100%"
                                height="auto"
                                fit="center"
                                :src="dayOne"
                                :show-loading="false"
                              />
                              <van-image
                                class="day_star"
                                width="60%"
                                height="auto"
                                fit="center"
                                :src="dayOneStar"
                                :show-loading="false"
                              />
                            </template>

                            <template v-else>
                              <div
                                class="yield_wrapper"
                                v-if="payItem.yield > 100"
                              >
                                收益{{ payItem.yield }}%
                              </div>

                              <div class="sold_out" v-if="!payItem.isPay"></div>

                              <div class="gold_wrapper">
                                <div class="gold_icon_wrapper">
                                  <van-image
                                    width="100%"
                                    height="100%"
                                    fit="fill"
                                    position="center"
                                    :src="payItemiconList[payListIndex]"
                                    :show-loading="false"
                                  />
                                </div>
                                <div class="gold_number">
                                  {{ payItem.gold }} 币
                                </div>
                              </div>

                              <div class="price_wrapper">
                                <div class="price_btn">
                                  ￥ {{ payItem.price }}
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </template>

                      <!-- 特惠卡 -->
                      <template v-if="payTypeItem == 'card'">
                        <div class="card_list_wrapper">
                          <div
                            class="card_item"
                            v-for="(cardItem, cardIndex) in store.initPayPupData
                              .card"
                            @click="selectPayItem(cardItem, cardIndex)"
                          >
                            <div class="card_cover_img">
                              <van-image
                                width="100%"
                                height="auto"
                                fit="fill"
                                position="center"
                                :src="cardCover[cardIndex]"
                                :show-loading="false"
                              />
                            </div>

                            <div class="card_yield">
                              收益{{ cardItem.yield }}%
                            </div>

                            <p class="bold t-center">
                              立即获得<span style="color: #fee22a">{{
                                cardItem.gold
                              }}</span
                              >金币
                            </p>
                            <p class="bold t-center">
                              <span style="color: #fee22a">{{
                                cardItem.challengeCard
                              }}</span
                              >挑战卡
                            </p>
                            <p class="bold t-center">
                              连续{{ cardItem.days }}天每日再领<span
                                style="color: #fee22a"
                                >{{ cardItem.daily_gold }}</span
                              >金币
                            </p>

                            <div class="card_price_wrapper">
                              ￥{{ cardItem.price }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- 兑换 -->
                      <template v-if="payTypeItem == 'exchange'">
                        <div
                          class="exchange_wrapper"
                          v-show="currentPayType == 'exchange'"
                        >
                          <div class="user_wallet">
                            <div class="user_lottery">
                              <span class="text">当前彩券:</span>
                              <span class="value">{{
                                addCommas(store.userInfo.lottery)
                              }}</span>
                            </div>
                            <div class="user_gold">
                              <span class="text">可兑换金币:</span>
                              <span class="value">{{
                                addCommas(
                                  parseInt(
                                    Number(store.userInfo.lottery) /
                                      Number(
                                        store.initPayPupData.userPayInfo
                                          .exchangeRatio
                                      )
                                  )
                                )
                              }}</span>
                            </div>
                          </div>

                          <div class="exchange_value">
                            <div>兑换:</div>
                            <div class="exchange_value_data">
                              +{{
                                addCommas(
                                  parseInt(
                                    Number(store.userInfo.lottery) /
                                      Number(
                                        store.initPayPupData.userPayInfo
                                          .exchangeRatio
                                      )
                                  )
                                )
                              }}
                            </div>
                            <div class="gold_icon"></div>
                          </div>
                          <p class="t-center mt-6">
                            积分兑换电玩币比例：
                            {{ store.initPayPupData.userPayInfo.exchangeRatio }}
                            : 1
                          </p>

                          <div class="exchange_btn" @click="handleExchange">
                            立即兑换
                          </div>
                        </div>
                      </template>
                    </div>
                  </van-tab>
                </van-tabs>
              </van-config-provider>
            </div>
          </div>
        </template>

        <!-- 横屏 -->
        <template v-else>
          <div class="top_nav">
            <div
              class="nav_bar_back_wrapper"
              @click="store.payPupToggle(false)"
            ></div>
            <div class="nav_bar_text"></div>
            <div class="head">
              <HeadPortrait
                :level="Number(store.userInfo.recharge_level)"
                :src="store.userInfo.headimgurl"
                width="100%"
                height="100%"
                :ae_id="store.userInfo.select_title"
                ae
                fe
              />
            </div>
            <div class="user_name">{{ store.userInfo.player_name }}</div>
            <div class="lottery_wrapper">
              {{ addCommas(store.userInfo.lottery) }}
            </div>
            <div class="gold_wrapper">{{ addCommas(store.userInfo.gold) }}</div>
          </div>
          <div class="bottom_cotent">
            <div
              class="left_nav_wrapper"
              :style="{
                width: 100 + store.areaInsetTopValue + 8 + 'px',
                paddingLeft: store.areaInsetTopValue + 4 + 'px',
                paddingRight: '4px',
              }"
            >
              <div
                :class="[
                  'left_nav_item',
                  currentPayType == payTypeItem && 'left_nav_item_active',
                ]"
                v-for="payTypeItem in store.initPayPupData.typeList"
                @click="currentPayTypeChange(payTypeItem)"
              >
                <div
                  class="left_nav_title"
                  :style="{
                    backgroundImage: `url('${payTypeTitleIcon[payTypeItem]}')`,
                  }"
                ></div>
              </div>
            </div>
            <div class="right_container">
              <!-- 首充 -->
              <div class="pay_list_wrapper" v-show="currentPayType == 'first'">
                <div
                  class="pay_list_item"
                  v-for="(payItem, payListIndex) in store.initPayPupData.first"
                  @click="selectPayItem(payItem, payListIndex)"
                >
                  <div class="yield_wrapper" v-if="payItem.yield > 100">
                    收益{{ payItem.yield }}%
                  </div>

                  <div class="first_icon"></div>
                  <div class="sold_out" v-if="!payItem.isPay"></div>

                  <div class="gold_wrapper">
                    <div class="gold_icon_wrapper">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="fill"
                        position="center"
                        :src="payItemiconList[payListIndex]"
                        :show-loading="false"
                      />
                    </div>
                    <div class="gold_number">{{ payItem.gold }} 币</div>
                  </div>

                  <div class="price_wrapper">
                    <div class="price_btn">￥ {{ payItem.price }}</div>
                  </div>
                </div>
              </div>
              <!-- 基础充值 -->
              <div class="pay_list_wrapper" v-show="currentPayType == 'base'">
                <div
                  class="pay_list_item"
                  v-for="(payItem, payListIndex) in store.initPayPupData.base"
                  @click="selectPayItem(payItem, payListIndex)"
                >
                  <div class="yield_wrapper" v-if="payItem.yield > 100">
                    收益{{ payItem.yield }}%
                  </div>
                  <div class="gold_wrapper">
                    <div class="gold_icon_wrapper">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="fill"
                        position="center"
                        :src="payItemiconList[payListIndex]"
                        :show-loading="false"
                      />
                    </div>

                    <div class="gold_number">{{ payItem.gold }} 币</div>
                  </div>

                  <div class="price_wrapper">
                    <div class="price_btn">￥ {{ payItem.price }}</div>
                  </div>
                </div>
              </div>

              <!-- 每日 -->
              <div class="pay_list_wrapper" v-show="currentPayType == 'day'">
                <div
                  class="pay_list_item"
                  v-for="(payItem, payListIndex) in store.initPayPupData.day"
                  @click="selectPayItem(payItem, payListIndex)"
                >
                  <van-image
                    class="day_one"
                    v-if="payListIndex == 0"
                    :src="dayOne"
                    :show-loading="false"
                  />
                  <van-image
                    v-if="payListIndex == 0"
                    class="day_star"
                    width="60%"
                    height="auto"
                    fit="center"
                    :src="dayOneStar"
                    :show-loading="false"
                  />

                  <div class="yield_wrapper" v-if="payItem.yield > 100">
                    收益{{ payItem.yield }}%
                  </div>

                  <div class="first_icon"></div>
                  <div class="sold_out" v-if="!payItem.isPay"></div>

                  <div class="gold_wrapper">
                    <div class="gold_icon_wrapper">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="fill"
                        position="center"
                        :show-loading="false"
                        :src="payItemiconList[payListIndex]"
                      />
                    </div>
                    <div class="gold_number">{{ payItem.gold }} 币</div>
                  </div>

                  <div class="price_wrapper">
                    <div class="price_btn">￥ {{ payItem.price }}</div>
                  </div>
                </div>
              </div>
              <!-- 特惠卡 -->
              <div class="card_list_wrapper" v-show="currentPayType == 'card'">
                <div
                  class="card_item"
                  v-for="(cardItem, cardIndex) in store.initPayPupData.card"
                  @click="selectPayItem(cardItem, cardIndex)"
                >
                  <div class="card_cover_img">
                    <van-image
                      width="100%"
                      height="auto"
                      fit="fill"
                      position="center"
                      :show-loading="false"
                      :src="cardCover[cardIndex]"
                    />
                  </div>

                  <div class="card_yield">收益{{ cardItem.yield }}%</div>

                  <p class="bold t-center">
                    立即获得<span style="color: #fee22a">{{
                      cardItem.gold
                    }}</span
                    >金币
                  </p>
                  <p class="bold t-center">
                    <span style="color: #fee22a">{{
                      cardItem.challengeCard
                    }}</span
                    >挑战卡
                  </p>
                  <p class="bold t-center">
                    连续{{ cardItem.days }}天每日再领<span
                      style="color: #fee22a"
                      >{{ cardItem.daily_gold }}</span
                    >金币
                  </p>

                  <div class="card_price_wrapper">￥{{ cardItem.price }}</div>
                </div>
              </div>

              <!-- 兑换 -->
              <div
                class="exchange_wrapper"
                v-show="currentPayType == 'exchange'"
              >
                <div class="gold_icon"></div>

                <p class="f-24 bold t-center">兑换金币</p>
                <div class="exchange_container">
                  <p>
                    {{
                      addCommas(
                        parseInt(
                          Number(store.userInfo.lottery) /
                            Number(
                              store.initPayPupData.userPayInfo.exchangeRatio
                            )
                        )
                      )
                    }}金币
                  </p>
                  <p>消耗{{ addCommas(store.userInfo.lottery) }}</p>
                </div>

                <p class="t-center t-10">
                  兑换比例：
                  {{ store.initPayPupData.userPayInfo.exchangeRatio }} : 1
                </p>

                <div class="exchange_btn" @click="handleExchange">立即兑换</div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- 购买确认 -->
      <van-popup
        v-model:show="confirmPupShow"
        :close-on-click-overlay="false"
        position="bottom"
        round
        closeable
        :style="{
          height: '54vh',
          backgroundColor: '#f7f8fa',
          overflow: 'hidden',
        }"
      >
        <div class="pay_confirm_wrapper" :style="cpupStyle">
          <div class="pay_title">充值金币</div>

          <div class="price_container">
            <div class="level_wrapper">
              <span>您是 </span>
              <span>{{
                gradLevelIcon[store.userInfo.recharge_level].level_text
              }}</span>
              <span> 等級用戶,充值额外获得 </span>
              <span class="bold"
                >{{ store.initPayPupData.userPayInfo.extraGoldRatio }}%</span
              >
            </div>
            <div class="pay_price">
              <span
                class="cost"
                v-if="
                  use_card_item_data.add_mul != 1 &&
                  use_card_item_data.type == 'zhekou_card'
                "
                >￥{{ currentSelectPayItem.price }}&nbsp;&nbsp;
              </span>
              <span class="price">￥{{ _price }}</span>
            </div>
            <div class="get_gold_wrapper" v-if="currentPayType != 'card'">
              <p>
                本次充值可获得
                <span class="f-16" style="color: #e2c820">
                  {{ currentSelectPayItem.gold }}
                </span>
                <span class="f-16" style="color: #e2c820">
                  + {{ currentSelectPayItem.extraGold }}
                </span>
                <span class="f-16" style="color: #e2c820" v-if="_extraGold > 0">
                  + {{ _extraGold }}
                </span>

                电玩币
              </p>
            </div>
            <div class="get_gold_wrapper" v-if="currentPayType == 'card'">
              <p>
                立即获得
                <span class="f-16" style="color: #e2c820">
                  {{
                    currentSelectPayItem.gold + currentSelectPayItem.extraGold
                  }}
                </span>
                电玩币 +

                <span class="f-16" style="color: #e2c820">
                  {{ currentSelectPayItem.challengeCard }}
                </span>

                挑战卡
              </p>
              <p>
                连续
                <span class="f-16" style="color: #e2c820">
                  {{ currentSelectPayItem.days }}
                </span>
                天&nbsp; 每日再领
                <span class="f-16" style="color: #e2c820">
                  {{ currentSelectPayItem.daily_gold }} 电玩币
                </span>
              </p>
            </div>
          </div>

          <div class="use_card_wrapper">
            <van-config-provider :theme-vars="themeVars">
              <van-cell
                title="使用卡券"
                :value="
                  use_card_item_data.item_id
                    ? use_card_item_data.card_use_name
                    : ''
                "
                is-link
                @click="selectUseCard(true)"
              />
            </van-config-provider>
          </div>
          <p class="tips">
            <span v-if="currentPayType == 'first'"
              >当前礼包每个账号限购一次</span
            >
            <span v-if="currentPayType == 'day'">当前礼包账号每天限购三次</span>
          </p>
          <div class="confirm_pay_btn" @click="confirmPay">确认充值</div>
          <p class="tips">注意：未成年人禁止在app内充值消费</p>
        </div>
      </van-popup>

      <!-- 使用卡券 -->
      <van-popup
        v-model:show="useCardPupShow"
        :close-on-click-overlay="false"
        position="bottom"
        round
        closeable
        :style="{
          height: '54vh',
          backgroundColor: '#f7f8fa',
        }"
      >
        <div class="useCardPupWrapper" :style="cpupStyle">
          <div class="title">使用卡券</div>

          <div class="use_card_list" v-if="useCardTypeList.use.length > 0">
            <div
              class="use_card_item"
              v-for="_card in useCardTypeList.use"
              @click="selectUseCardItem(_card)"
            >
              <p class="card_name">{{ _card.card_use_name }}</p>
              <p class="card_end_tiem">有效时间至：{{ _card.end_time }}</p>

              <div class="check_icon_wrapper">
                <van-image
                  width="100%"
                  height="100%"
                  fit="contain"
                  position="center"
                  :src="checkIcon"
                  :show-loading="false"
                  v-if="use_card_item_data.item_id == _card.item_id"
                />
              </div>
            </div>
          </div>
          <div class="no_card_tips" v-else>- 暂无卡券 -</div>

          <div class="confirm_card_btn" @click="useCardConfirm">确定</div>
        </div>
      </van-popup>
    </div>
  </van-popup>
</template>

<script setup>
import { computed, reactive, ref, onBeforeUnmount, watch } from "vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Level from "@/components/Level.vue";
import { useStore } from "@/store/store";
import { closeToast, showToast } from "vant";
import { sendInstructions } from "@/api/sendData";
import { playerRechargeStatus } from "@/api/index";
import { addCommas } from "@/utils/utils";
import { gradLevelIcon } from "@/utils/levelData";
const store = useStore();

import dayOne from "@/assets/images/pay/day_one.png";
import dayOneStar from "@/assets/images/pay/star.gif";

const gold01 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_1.png";
const gold02 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_2.png";
const gold03 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_3.png";
const gold04 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_4.png";
const gold05 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_5.png";
const gold06 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_6.png";
const gold07 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_7.png";
const gold08 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_8.png";
const gold09 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_9.png";
const gold10 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_10.png";
const gold11 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_11.png";
const gold12 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_gold_12.png";

const card_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/card_1.png";
const card_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/card_2.png";
const card_3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/card_3.png";
const card_4 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/card_4.png";

import checkIcon from "@/assets/images/check_icon.png";

const cardCover = [card_1, card_2, card_3, card_4];

const payItemiconList = [
  gold01,
  gold02,
  gold03,
  gold04,
  gold05,
  gold06,
  gold07,
  gold08,
  gold09,
  gold10,
  gold11,
  gold12,
  gold12,
  gold12,
  gold12,
];

const popStyle = {
  padding: "0",
  margin: "0",
  background: "none",
};

const currentPayType = ref("first");
const themeVars = reactive({
  cellFontSize: "14px",
  cellLineHeight: "42px",
  cellTextColor: "#724026",
  cellValueColor: "#806CF2",
});
const payTypeTitle = {
  base: {
    title: "基础礼包",
    icon: "",
  },
  first: {
    title: "首充礼包",
    icon: "",
  },
  day: {
    title: "每日礼包",
    icon: "",
  },
  card: {
    title: "周卡月卡",
    icon: "",
  },
  exchange: {
    title: "彩券兑换",
    icon: "",
  },
};

const currentPayTypeChange = (type) => {
  currentPayType.value = type;
};

const base_title =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/base_title.png";
const first_title =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/first_title.png";
const day_title =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/day_title.png";
const card_title =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/card_title.png";
const exchange_title =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/exchange_title.png";

const payTypeTitleIcon = {
  base: base_title,
  first: first_title,
  day: day_title,
  card: card_title,
  exchange: exchange_title,
};
const tabsVars = reactive({
  tabsNavBackground: "none",
  tabTextColor: "#fff",
  tabActiveTextColor: "#FAEF85",
  tabsBottomBarColor: "#FAEF85",
  tabsLineHeight: "35px",
  tabFontSize: "16px",
});

const container_style = reactive({
  width: "100vh",
  height: "100vw",
  transform: "rotate(90deg) translateY(-100%)",
});

const cpupStyle = reactive({
  width: "100%",
  height: "100%",
  transform: "",
});
const confirmPupShow = ref(false);
const useCardPupShow = ref(false);

const selectUseCard = () => {
  if (
    currentSelectPayItem.gold == 999999 ||
    (currentSelectPayItem.payIndex == 9 && currentPayType.value == "day")
  ) {
    showToast({
      type: "fail",
      message: "该礼包不支持",
    });

    return;
  }

  useCardPupToggle(true);
};
const useCardPupToggle = (statu) => {
  // 优惠卡信息
  statu &&
    sendInstructions({
      msg_type: "get_item",
    });

  useCardPupShow.value = statu;
};

const useCardConfirm = () => {
  useCardPupToggle(false);
};

const useCardTypeList = computed(() => {
  const list = {
    use: [],
    no_use: [],
  };

  //   cardItem.type == 'jifen_card' && 'jifen_item',
  // cardItem.type == 'jiacheng_card' && 'jiacheng_item',
  // cardItem.type == 'tianti_buff_card' && 'jifen_item',
  // cardItem.type == 'zhekou_card' && 'zhekou_item',

  if (currentPayType.value == "base" || currentPayType.value == "day") {
    const card_types = ["zhekou_card", "jiacheng_card"];
    store.cardBagData.waitting.forEach((card) => {
      if (card_types.includes(card.type)) {
        list.use.push(card);
      }
    });
  } else {
    list.use = store.cardBagData.waitting.filter((_card) => {
      return _card.type == "zhekou_card";
    });
  }

  console.log("list", list);

  return list;
});

const currentSelectPayItem = reactive({
  challengeCard: 0,
  daily_gold: 0,
  days: 0,
  gold: 0,
  isPay: false,
  price: "0.00",
  product_id: "jk_LNfk0cQOrOw0AdRj",
  type: "0",
  yield: 0,
  extraGold: 0,
  payIndex: 0,
});

const use_card_item_data = reactive({
  type: "",
  card_use_name: "",
  card_name: "",
  item_id: "",
  start_time: "",
  end_time: "",
  statu: "",
  add_mul: 1,
});

const init_use_card_item_data = () => {
  use_card_item_data.type = "";
  use_card_item_data.card_use_name = "";
  use_card_item_data.card_name = "";
  use_card_item_data.item_id = "";
  use_card_item_data.start_time = "";
  use_card_item_data.end_time = "";
  use_card_item_data.statu = "";
  use_card_item_data.add_mul = 1;
};

const _price = computed(() => {
  if (use_card_item_data.type == "zhekou_card") {
    return (
      Number(currentSelectPayItem.price) * use_card_item_data.add_mul
    ).toFixed(2);
  }

  return currentSelectPayItem.price;
});

const _extraGold = computed(() => {
  return parseInt(
    Number(currentSelectPayItem.gold) *
      ((use_card_item_data.add_mul * 10 - 10) / 10)
  );
});

const selectUseCardItem = (data) => {
  if (data.item_id == use_card_item_data.item_id) {
    init_use_card_item_data();
    return;
  }

  const item_key = Object.keys(data);

  item_key.forEach((key) => {
    use_card_item_data[key] = data[key];
  });
};

const selectPayItem = (data, index) => {
  if (!data.isPay) return;

  const extraGold = parseInt(
    (data.gold * store.initPayPupData.userPayInfo.extraGoldRatio) / 100
  );
  currentSelectPayItem.payIndex = data.payIndex;
  currentSelectPayItem.gold = data.gold;
  currentSelectPayItem.extraGold = extraGold;
  currentSelectPayItem.yield = data.yield;
  currentSelectPayItem.price = data.price;
  currentSelectPayItem.product_id = data.product_id;

  if (currentPayType.value == "card") {
    currentSelectPayItem.challengeCard = data.challengeCard;
    currentSelectPayItem.daily_gold = data.daily_gold;
    currentSelectPayItem.days = data.days;
  }

  init_use_card_item_data();

  confirmPupShow.value = true;
};

// 确认支付
const confirmPay = async () => {
  // 实名认证
  if (!store.userInfo.id_verification && !store.userInfo.is_gm) {
    confirmPupShow.value = false;
    store.storeShowToggle("auth_user_show", true);
    return;
  }

  confirmPupShow.value = false;
  showToast({
    type: "loading",
    forbidClick: true,
    message: "正在充值,\n请稍后...",
    duration: 0,
  });

  if (currentPayType.value == "first" || currentPayType.value == "day") {
    const { data } = await playerRechargeStatus({
      uid: parseInt(store.userInfo.uid),
    });

    if (
      (currentPayType.value == "first" &&
        data.first[currentSelectPayItem.payIndex]) ||
      (currentPayType.value == "day" &&
        data.day[currentSelectPayItem.payIndex] >= 3)
    ) {
      closeToast();

      showToast({
        type: "fail",
        message: "购买上限！",
      });

      sendInstructions(
        {
          msg_type: "get_player_recharge_status",
        },
        50
      );

      return;
    }
  }

  // 充值~
  const params = {
    msg_type: "player_recharge",
    type: currentPayType.value,
    index: currentSelectPayItem.payIndex,
    product_id: currentSelectPayItem.product_id,
    item_id: use_card_item_data.item_id,
    area_name: "jk",
  };
  // if (store.channelType != "ios") {
  //   params.recharge_platform = "wx";
  // }
  sendInstructions(params, 0);
};

// 积分兑换
const handleExchange = () => {
  if (
    parseInt(
      Number(store.userInfo.lottery) /
        Number(store.initPayPupData.userPayInfo.exchangeRatio)
    ) <= 0
  ) {
    if (store.isLandscape) {
      showToast({
        type: "fail",
        message: "积分不足",
        teleport: "#_rotate_box",
      });
    } else {
      showToast({
        type: "fail",
        message: "积分不足",
      });
    }

    return;
  }

  sendInstructions({
    msg_type: "exchange_lottery",
  });
};

const whenPupOpenEvent = () => {
  if (store.payPupStatu.type != currentPayType.value)
    currentPayType.value = store.payPupStatu.type;

  confirmPupShow.value && (confirmPupShow.value = false);

  sendInstructions({
    msg_type: "get_player_recharge_status",
  });

  if (store.isLandscape) {
    container_style.width = "100vh";
    container_style.height = "100vw";
    container_style.transform = "rotate(90deg) translateY(-100%)";
    cpupStyle.width = "54vh";
    cpupStyle.height = "100vw";
    cpupStyle.transform = "rotate(90deg) translateY(-100%) ";
  } else {
    container_style.width != "100vw" && (container_style.width = "100vw");
    container_style.height != "100vh" && (container_style.height = "100vh");
    container_style.transform != "rotate(0deg) translateY(0)" &&
      (container_style.transform = "rotate(0deg) translateY(0)");

    cpupStyle.width != "100%" && (cpupStyle.width = "100%");
    cpupStyle.height != "100%" && (cpupStyle.height = "100%");
    cpupStyle.transform != "" && (cpupStyle.transform = "");
  }
};

const whenPupCloseEvent = () => {
  confirmPupShow.value && (confirmPupShow.value = false);
  useCardPupShow.value && (useCardPupShow.value = false);
};

onBeforeUnmount(() => {});
</script>

<style lang="scss" scoped>
.recharge_wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #401d6d;
  overflow: hidden;
  .container_rotate {
    transform-origin: left top;
  }
}

// 横屏

.flex_column_box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav_bar_wrapper {
  position: relative;
  height: 40px;

  .nav_bar_text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 96px;
    height: 36px;
    background-size: 100% auto;
    background-image: url("@/assets/images/payment_cente_title.png");
    background-repeat: no-repeat;
  }

  .nav_bar_back_wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 40px;
    background-size: 100% 100%;
    background-image: url("@/assets/images/navbar_back_icon.png");
    background-repeat: no-repeat;
  }
}
.user_info_wrapper {
  padding: 0 15px;
  // height: 28px;
  margin-bottom: 10px;
  .user_info {
    display: flex;
    margin-top: 14px;
    align-items: center;
    .info {
      margin-right: auto;
      display: flex;
      .head {
        width: 28px;
        height: 28px;
      }
      .user_name {
        font-size: 14px;
        padding-left: 8px;
        line-height: 28px;
        font-weight: bold;
      }
    }

    .lottery_wrapper,
    .gold_wrapper {
      position: relative;
      min-width: 70px;
      text-align: right;
      padding: 0 6px 0 22px;
      height: 20px;
      background-color: #00000040;
      border-radius: 10px;
      margin-left: 6px;
      line-height: 20px;
      font-size: 14px;
      font-weight: bold;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -2px;
        width: 20px;
        height: 20px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .lottery_wrapper {
      &::after {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
      }
    }
    .gold_wrapper {
      &::after {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
      }
    }
  }
}

.tabs_wrapper {
  flex: 1;
  overflow: auto;
  &:deep(.van-config-provider) {
    height: 100%;
  }

  &:deep(.van-tabs) {
    height: 100%;

    .van-tabs__content {
      height: calc(100% - 35px);
      & > div {
        height: 100% !important;
      }
    }
  }

  .pay_container_wrapper {
    position: relative;
    padding-bottom: 30px;
    height: 100%;
    overflow: auto;
    padding: 20px 12px;
    background-color: #6e23a2;
    .pay_list_tips {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      color: #faef85;
    }

    .pay_list_wrapper {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .pay_list_item {
        width: 31%;
        margin-right: 10px;
        min-height: 90px;
        margin-top: 10px;
        border-radius: 6px;
        background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
        overflow: hidden;
        position: relative;

        .day_star {
          position: absolute;
          right: 0px;
          top: 0px;
          z-index: 10;
        }

        .gold_wrapper {
          .gold_icon_wrapper {
            width: 60px;
            height: 60px;
            margin: 6px auto 0 auto;
            background-color: #783493;
            border-radius: 6px;
          }

          .gold_number {
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 800;
          }
        }

        .price_wrapper {
          margin-top: 6px;
          width: 100%;
          padding: 6px 0;
          background-color: #fff;

          .price_btn {
            width: 80%;
            height: 24px;
            margin: 0 auto;
            color: #9c5317;
            line-height: 24px;
            text-align: center;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 800;
            white-space: nowrap;
            text-wrap: nowrap;
            background: linear-gradient(
              180deg,
              #ecd10d 0%,
              #ffe000 0%,
              #ffb800 100%
            );
            box-shadow: inset 0px -2px 1px 0px #e78a00;
          }
        }

        .yield_wrapper {
          position: absolute;
          min-width: 60px;
          text-align: center;
          padding: 2px 4px;
          border-bottom-right-radius: 6px;
          text-shadow: 1px 1px 0px #185b00;
          background-color: #69cf45;
          white-space: nowrap;
          text-wrap: nowrap;
          z-index: 2;
        }

        .first_icon {
          width: 36px;
          height: 36px;
          position: absolute;
          right: 2px;
          top: 2px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/first_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .sold_out {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.45);
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/sold_out_icon.png");
          background-size: 64px 60px;
          background-position: center center;
          background-repeat: no-repeat;
          z-index: 3;
        }

        &:nth-child(3n) {
          margin-right: 0;
          // margin-right: auto;
        }
      }
    }

    .card_list_wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card_item {
        position: relative;
        margin-top: 24px;
        width: 48%;
        // height: 110px;
        padding-top: 80px;
        background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
        border-radius: 6px 20px 6px 20px;

        .card_cover_img {
          width: 130px;
          position: absolute;
          left: 50%;
          top: -30px;
          transform: translateX(-50%);
        }

        .card_yield {
          position: absolute;
          left: 0;
          top: 38%;
          transform: translateY(-50%);
          background: linear-gradient(
            90deg,
            #ffc541 0%,
            #ffe073 50%,
            #ffc141 100%
          );
          height: 18px;
          line-height: 18px;
          padding: 0 4px;
          color: #984400;
          font-weight: bold;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(50%, 0);
            // width: 18px;
            border-top: 9px solid #ffc141;
            border-right: 9px solid transparent;
            border-bottom: 9px solid #ffc141;
            border-left: 9px solid transparent;
          }
        }

        .card_price_wrapper {
          margin-top: 10px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: linear-gradient(180deg, #ffffff 0%, #d2b4fb 100%);
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 6px;
          color: #401d6d;
          font-size: 22px;
          font-weight: 800;
        }
      }
    }
    .exchange_wrapper {
      border-radius: 30px;
      background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
      padding: 30px;
      .user_wallet {
        .user_gold {
          margin-top: 16px;
        }

        .user_lottery,
        .user_gold {
          height: 32px;
          line-height: 32px;
          display: flex;
          justify-content: center;
          text-align: center;
          .text {
            text-align: right;
            font-size: 16px;
            padding-right: 6px;
          }
          .value {
            min-width: 126px;
            background-color: #fff;
            border-radius: 16px;
            color: #401d6d;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .exchange_value {
        display: flex;
        justify-content: center;
        margin-top: 26px;
        height: 40px;
        line-height: 40px;
        align-items: center;
        font-size: 14px;
        .exchange_value_data {
          min-width: 200px;
          text-align: center;
          padding: 0 20px;
          background-color: #b74bd9;
          border-radius: 20px;
          font-size: 16px;
          margin: 0 10px;
          color: #fee22a;
          font-weight: bold;
        }
        .gold_icon {
          width: 28px;
          height: 28px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
          background-size: 100% 100%;
        }
      }

      .exchange_btn {
        margin: 0 auto;
        margin-top: 22px;
        color: #401d6d;
        width: 182px;
        height: 44px;
        line-height: 22px;
        background: linear-gradient(180deg, #ffffff 0%, #d2b4fb 100%);
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 44px;
        border-radius: 10px;
      }
    }
  }
}

// 横屏
.top_nav {
  height: 54px;
  background-color: #401d6d;
  display: flex;
  justify-content: start;
  align-items: center;

  .nav_bar_back_wrapper {
    width: 80px;
    height: 40px;
    background-size: 100% 100%;
    background-image: url("@/assets/images/navbar_back_icon.png");
    background-repeat: no-repeat;
    margin-right: 8px;
  }

  .nav_bar_text {
    width: 96px;
    height: 36px;
    background-size: 100% auto;
    background-image: url("@/assets/images/payment_cente_title.png");
    background-repeat: no-repeat;
    margin-right: auto;
  }

  .head {
    width: 28px;
    height: 28px;
    margin-right: 4px;
  }
  .user_name {
    margin-right: 8px;
  }

  .lottery_wrapper,
  .gold_wrapper {
    position: relative;
    min-width: 70px;
    text-align: right;
    padding: 0 6px 0 22px;
    height: 20px;
    background-color: #00000040;
    border-radius: 10px;
    line-height: 20px;
    font-size: 14px;
    font-weight: bold;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -2px;
      width: 20px;
      height: 20px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .lottery_wrapper {
    margin-right: 10px;
    &::after {
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
    }
  }
  .gold_wrapper {
    margin-right: 20px;
    &::after {
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
    }
  }
}

.bottom_cotent {
  width: 100%;
  height: calc(100% - 54px);
  background-color: #6e23a2;
  display: flex;
  .left_nav_wrapper {
    border-right: 1px dashed #b345d4;
    .left_nav_item {
      position: relative;
      width: 100px;
      height: 45px;
      margin-top: 12px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_type_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .left_nav_title {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60px;
        height: 22px;
        transform: translate(-50%, -50%);
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .left_nav_item_active {
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/pay_type_active_bg.png");
    }
  }
  .right_container {
    position: relative;
    padding: 0 12px;
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    .pay_list_wrapper {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .pay_list_item {
        width: 23%;
        margin-right: 10px;
        min-height: 90px;
        margin-top: 10px;
        border-radius: 6px;
        background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
        overflow: hidden;
        position: relative;

        .day_one {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          z-index: 9;
        }
        .day_star {
          position: absolute;
          right: 0px;
          top: 0px;
          z-index: 10;
        }
        .gold_wrapper {
          .gold_icon_wrapper {
            width: 60px;
            height: 60px;
            margin: 6px auto 0 auto;
            background-color: #783493;
            border-radius: 6px;
          }

          .gold_number {
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 800;
          }
        }

        .price_wrapper {
          margin-top: 6px;
          width: 100%;
          padding: 6px 0;
          background-color: #fff;

          .price_btn {
            width: 80%;
            height: 24px;
            margin: 0 auto;
            color: #9c5317;
            line-height: 24px;
            text-align: center;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 800;
            white-space: nowrap;
            text-wrap: nowrap;
            background: linear-gradient(
              180deg,
              #ecd10d 0%,
              #ffe000 0%,
              #ffb800 100%
            );
            box-shadow: inset 0px -2px 1px 0px #e78a00;
          }
        }

        .yield_wrapper {
          position: absolute;
          min-width: 60px;
          text-align: center;
          padding: 2px 4px;
          border-bottom-right-radius: 6px;
          text-shadow: 1px 1px 0px #185b00;
          background-color: #69cf45;
          white-space: nowrap;
          text-wrap: nowrap;
          z-index: 2;
        }

        .first_icon {
          width: 36px;
          height: 36px;
          position: absolute;
          right: 2px;
          top: 2px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/first_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .sold_out {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.45);
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/sold_out_icon.png");
          background-size: 64px 60px;
          background-position: center center;
          background-repeat: no-repeat;
          z-index: 3;
        }

        &:nth-child(4n) {
          margin-right: 0;
          // margin-right: auto;
        }
      }
    }

    .card_list_wrapper {
      display: flex;
      flex-wrap: wrap;
      // flex-shrink: 0;
      // overflow: auto;
      // justify-content: space-between;
      // white-space: nowrap;
      // overflow-x: auto;
      // width: 100%;
      .card_item {
        position: relative;
        margin-top: 24px;
        width: 47%;
        margin-right: 12px;
        // height: 110px;
        padding-top: 80px;
        background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
        border-radius: 6px 20px 6px 20px;

        .card_cover_img {
          width: 130px;
          position: absolute;
          left: 50%;
          top: -30px;
          transform: translateX(-50%);
        }

        .card_yield {
          position: absolute;
          left: 0;
          top: 38%;
          transform: translateY(-50%);
          background: linear-gradient(
            90deg,
            #ffc541 0%,
            #ffe073 50%,
            #ffc141 100%
          );
          height: 18px;
          line-height: 18px;
          padding: 0 4px;
          color: #984400;
          font-weight: bold;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(50%, 0);
            // width: 18px;
            border-top: 9px solid #ffc141;
            border-right: 9px solid transparent;
            border-bottom: 9px solid #ffc141;
            border-left: 9px solid transparent;
          }
        }

        .card_price_wrapper {
          margin-top: 10px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: linear-gradient(180deg, #ffffff 0%, #d2b4fb 100%);
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 6px;
          color: #401d6d;
          font-size: 22px;
          font-weight: 800;
        }
      }
    }

    .exchange_wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 30px;
      background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
      width: 260px;

      padding-bottom: 16px;

      .gold_icon {
        width: 42px;
        height: 42px;
        margin: 0 auto;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
        background-size: 100% 100%;
      }

      .exchange_container {
        margin-top: 6px;
        background-color: #6e23a2;
        border-radius: 10px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #fee22a;
        width: 185px;
        margin: 0 auto;
        padding: 10px 0 14px 0;
      }

      .exchange_btn {
        margin: 0 auto;
        margin-top: 22px;
        color: #401d6d;
        width: 182px;
        height: 44px;
        line-height: 22px;
        background: linear-gradient(180deg, #ffffff 0%, #d2b4fb 100%);
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 44px;
        border-radius: 10px;
      }
    }
  }
}

// 充值确认
.pay_confirm_wrapper {
  transform-origin: left top;
  overflow-y: auto;
  padding: 0 28px;
  .pay_title {
    padding: 8px 0;
    font-size: 20px;
    font-weight: 800;
    color: #724026;
    text-align: center;
  }

  .price_container {
    position: relative;
    margin-top: 26px;
    background-color: #fff;
    border-radius: 10px;
    height: 104px;
    .level_wrapper {
      position: absolute;
      top: -14px;
      left: 0;
      height: 28px;
      line-height: 28px;
      padding: 0 4px;
      border-radius: 14px;
      background: linear-gradient(270deg, #f8887e 0%, #e2487c 100%);
      color: #fff;
      font-size: 12px;
    }

    .pay_price {
      font-size: 28px;
      padding-top: 18px;
      width: 100%;
      text-align: center;

      color: #f91111;
      font-weight: 800;
      .cost {
        // color: #f8f8f8;
        font-size: 20px;
        text-decoration: line-through;
      }
    }

    .get_gold_wrapper {
      font-size: 14px;
      min-height: 37px;
      // line-height: 37px;
      padding-top: 4px;
      font-weight: 500;
      text-align: center;
      background: linear-gradient(90deg, #7c69f2 0%, #b28ce7 100%);
      color: #fff;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .use_card_wrapper {
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  .tips {
    text-align: center;
    color: #a0a0a0;
    font-size: 12px;
    padding: 4px;
  }

  .confirm_pay_btn {
    height: 44px;
    background: linear-gradient(270deg, #f8887e 0%, #e2487c 100%);
    line-height: 44px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 22px;
  }

  // .pay_tips {
  //   text-align: center;
  //   color: #a0a0a0;
  //   padding-bottom: 10px;
  // }

  // .pay_price {
  //   width: 90%;
  //   margin: 0 auto;
  //   background: #ffffff;
  //   box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  //   border-radius: 10px;
  //   height: 56px;
  //   line-height: 56px;
  //   color: #ff3450;
  //   text-align: center;
  //   font-size: 24px;
  //   font-weight: 1000;
  // }

  // .get_gold_wrapper {
  //   margin-top: 16px;
  //   margin-bottom: 16px;
  //   color: #9c5317;
  //   text-align: center;
  //   font-size: 14px;
  //   font-weight: 800;
  //   white-space: nowrap;
  // }

  // .card_detail {
  //   padding: 0 60px;
  //   color: #9c5317;
  //   // color: rgb(226, 200, 32);
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  //   font-weight: 800;
  //   text-align: center;
  // }
  // .confirm_pay {
  //   width: 90%;
  //   margin: 0 auto;
  //   background: linear-gradient(90deg, #e6dbff 0%, #b798fa 100%);
  //   height: 46px;
  //   border-radius: 23px;
  //   line-height: 46px;
  //   text-align: center;
  //   color: #1d0053;
  //   font-weight: 800;
  //   font-size: 18px;
  // }
}

.useCardPupWrapper {
  // position: relative;
  transform-origin: left top;
  overflow-y: auto;
  padding: 0 28px;
  padding-bottom: 20px;
  .title {
    padding: 8px 0;
    font-size: 20px;
    font-weight: 800;
    color: #724026;
    text-align: center;
  }

  .no_use_text {
    color: #000000;
    font-size: 14px;
    padding: 10px;
  }
  .use_card_list {
    .use_card_item {
      margin-top: 4px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      padding: 10px 10px 20px 10px;
      color: #000;

      .no_use {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #fff, $alpha: 0.6);
        z-index: 10;
      }

      .card_name {
        font-weight: 700;
        font-size: 20px;
      }
      .card_end_tiem {
        font-size: 12px;
      }

      .check_icon_wrapper {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-color: #d9d9d9;
        border-radius: 12px;
      }
    }
  }

  .no_card_tips {
    padding: 80px 0;
    text-align: center;
    font-size: 18px;
    color: #000;
    font-weight: bold;
  }

  .confirm_card_btn {
    // position: fixed;
    // bottom: 20px;
    // left: 5%;
    // width: 90%;
    margin-top: 20px;
    height: 44px;
    background: linear-gradient(270deg, #f8887e 0%, #e2487c 100%);
    line-height: 44px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 22px;
  }
}
</style>
