<template>
  <div class="game_list_wrapper">
    <van-config-provider :theme-vars="themeVars">
      <van-tabs
        v-model:active="store.curTabActive.parent"
        swipeable
        shrink
        @click-tab="handleTabClick"
        @change="handleTabChange"
        background="linear-gradient(180deg, #9544E2 0%, #4B2383 100%)"
        color="#3de1c3"
        title-active-color="#fff"
        title-inactive-color="#fff"
      >
        <van-tab
          v-for="(tabItem, tabIndex) in gameNameTabList"
          :key="tabItem.productName"
          :name="tabItem.productName"
        >
          <template #title>
            <div class="tab_tltle_custom">
              {{ tabItem.title }}

              <div class="new_icon" v-if="tabItem.new"></div>
            </div>
          </template>

          <div class="game_tab_content">
            <!-- 推币机 -->
            <template v-if="tabItem.productName == 'msqy'">
              <div class="game_banner">
                <div
                  class="activity_card_left"
                  @click="cardBannerLeftCilik(store.curTabActive.children)"
                >
                  <div class="card_bg">
                    <van-image
                      width="100%"
                      height="auto"
                      fit="fill"
                      :show-loading="false"
                      :src="bannerData[store.curTabActive.children].left_bg"
                    />
                  </div>
                </div>
                <div
                  class="activity_card_right"
                  @click="cardBannerRightCilik(store.curTabActive.children)"
                >
                  <div class="right_cover_img">
                    <van-image
                      width="100%"
                      height="auto"
                      fit="fill"
                      :show-loading="false"
                      :src="bannerData[store.curTabActive.children].right_bg"
                    />
                  </div>
                </div>
              </div>

              <div class="game_children_tab">
                <div class="game_children_tab_bg">
                  <van-image
                    v-show="store.curTabActive.children == 'msqy'"
                    width="100%"
                    height="100%"
                    :src="gameChildrenTabBgList.msqy.bg"
                    block
                    :show-loading="false"
                  />
                  <van-image
                    v-show="store.curTabActive.children == 'msqy_line'"
                    width="100%"
                    height="100%"
                    :src="gameChildrenTabBgList.msqy_line.bg"
                    block
                    :show-loading="false"
                  />
                  <van-image
                    v-show="store.curTabActive.children == 'mxt'"
                    width="100%"
                    height="100%"
                    :show-loading="false"
                    :src="gameChildrenTabBgList.mxt.bg"
                    block
                  />
                </div>
                <div class="game_children_tab_content">
                  <div
                    class="game_children_tab_item"
                    @click="curChildrenTypeChange('msqy')"
                  ></div>
                  <div
                    class="game_children_tab_item"
                    @click="curChildrenTypeChange('msqy_line')"
                  ></div>

                  <div
                    class="game_children_tab_item"
                    @click="curChildrenTypeChange('mxt')"
                  ></div>
                </div>
              </div>

              <template v-if="store.curTabActive.children !== 'msqy_line'">
                <div class="list_wrapper">
                  <div
                    class="game_list_item"
                    v-for="(gameItemCard, gameIndex) in store.gameDataList[
                      store.curTabActive.children
                    ]"
                    :key="'gamekey' + gameItemCard.product_id"
                    v-show="gameItemCard.group == '' || !gameItemCard.group"
                    @click="goToGameView($event, gameItemCard)"
                  >
                    <div class="game_name">
                      {{
                        findGameInfoData(
                          gameItemCard.child_name || gameItemCard.product_name
                        ).game_name +
                        " " +
                        gameItemCard.machine_index
                      }}
                      房
                    </div>
                    <div class="game_cover">
                      <van-image
                        width="100%"
                        height="auto"
                        :show-loading="false"
                        :src="
                          findGameInfoData(
                            gameItemCard.child_name || gameItemCard.product_name
                          ).cover
                        "
                        block
                      />

                      <div
                        :class="[
                          'level_room',
                          gameItemCard.level == '1' ? 'level_room_no' : '',
                        ]"
                      >
                        {{
                          gameItemCard.level == "1"
                            ? "不限等级"
                            : `${
                                gradLevelIcon[Number(gameItemCard.level)]
                                  .level_text
                              }`
                        }}
                      </div>
                    </div>
                    <div class="game_card_footer">
                      <div class="playing_number">
                        <span
                          class="maintenance"
                          v-if="gameItemCard.status == 0"
                          >维护中</span
                        >
                        <span
                          class="playing"
                          v-else-if="gameItemCard.occupy.length > 0"
                          >{{ gameItemCard.occupy.length }}热玩中</span
                        >

                        <span class="playnull" v-else>空闲</span>
                      </div>

                      <div class="exchange_number_wrapper">
                        <span class="exchange_number">
                          {{ gameItemCard.exchange }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="msqy_line_list">
                  <div
                    class="msqy_line_list_item"
                    v-for="msqy_line_item in store.msqyLineListData"
                  >
                    <div class="msqy_line_list_item_title">
                      魔术连线 {{ msqy_line_item.line_reward_index }} 房
                    </div>
                    <div class="msqy_line_list_item_cover">
                      <div
                        :class="[
                          'level_room',
                          Number(msqy_line_item.level) == 1
                            ? 'level_room_no'
                            : '',
                        ]"
                      >
                        {{
                          Number(msqy_line_item.level) == 1
                            ? "不限等级"
                            : `${
                                gradLevelIcon[Number(msqy_line_item.level)]
                                  .level_text
                              }`
                        }}
                      </div>

                      <div class="exchange_number_wrapper">
                        <span class="exchange_number">
                          {{ msqy_line_item.exchange }}
                        </span>
                      </div>

                      <div class="msqy_line_list_item_cover_center">
                        <div class="msqy_line_list_item_cover_center_block">
                          <div class="line_reward_data_item">
                            <div
                              class="line_reward_data_item_name"
                              style="color: #00fdff"
                            >
                              JP1
                            </div>
                            <div class="line_reward_data_item_value">
                              {{ msqy_line_item.line_reward_data.jp1 }}
                            </div>
                          </div>
                          <div class="line_reward_data_item">
                            <div
                              class="line_reward_data_item_name"
                              style="color: #00ff29"
                            >
                              JP2
                            </div>
                            <div class="line_reward_data_item_value">
                              {{ msqy_line_item.line_reward_data.jp2 }}
                            </div>
                          </div>
                          <div class="line_reward_data_item">
                            <div
                              class="line_reward_data_item_name"
                              style="color: #ffc700"
                            >
                              JP3
                            </div>
                            <div class="line_reward_data_item_value">
                              {{ msqy_line_item.line_reward_data.jp3 }}
                            </div>
                          </div>
                          <div class="line_reward_data_item">
                            <div
                              class="line_reward_data_item_name"
                              style="color: #ff0000"
                            >
                              ALL
                            </div>
                            <div class="line_reward_data_item_value">
                              {{ msqy_line_item.line_reward_data.quanpan }}
                            </div>
                          </div>
                        </div>

                        <div class="lighting_1"></div>
                        <div class="lighting_2"></div>
                      </div>
                    </div>

                    <div class="msqy_line_list_item_seats">
                      <div
                        class="msqy_line_list_item_seat_item"
                        v-for="(
                          machineItem, machineIndex
                        ) in msqy_line_item.machines"
                        :key="'msql_line' + machineItem.machine_index"
                      >
                        <div
                          class="msqy_line_list_item_seat_btn_ing"
                          v-if="
                            machineItem.status == 1 &&
                            machineItem.device_info[0].occupy &&
                            machineItem.device_info[0].occupy.player_id
                          "
                          @click="goToGameView($event, machineItem)"
                        >
                          <div class="head_box">
                            <HeadPortrait
                              width="100%"
                              height="100%"
                              style="margin: 0 auto"
                              :ae_id="
                                machineItem.device_info[0].occupy
                                  .select_title || 0
                              "
                              ae
                              fe
                              :level="
                                machineItem.device_info[0].occupy
                                  .recharge_level || 1
                              "
                              :src="
                                machineItem.device_info[0].occupy.head ||
                                machineItem.device_info[0].occupy.head_frame
                              "
                            />
                          </div>

                          <div class="_index">
                            <span v-if="store.userInfo.is_gm"
                              >{{ machineItem.machine_index }}号</span
                            >
                            <span v-else>围观 {{ machineIndex + 1 }}P</span>
                          </div>
                        </div>

                        <div
                          class="msqy_line_list_item_seat_btn_pause"
                          v-else-if="machineItem.status == 0"
                          @click="goToGameView($event, machineItem)"
                        >
                          <span v-if="store.userInfo.is_gm"
                            >{{ machineItem.machine_index }}号 维护</span
                          >
                          <span v-else> {{ machineIndex + 1 }}P 维护中</span>
                        </div>

                        <div
                          class="msqy_line_list_item_seat_btn_go"
                          v-else
                          @click="goToGameView($event, machineItem)"
                        >
                          <span v-if="store.userInfo.is_gm"
                            >{{ machineItem.machine_index }}号</span
                          >
                          <span v-else>进入{{ machineIndex + 1 }}P</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>

            <!-- 捕鱼捕鸟 -->
            <template v-else-if="tabItem.productName == 'hw3'">
              <div class="game_banner">
                <div
                  class="activity_card_left"
                  @click="cardBannerLeftCilik(store.curTabActive.children)"
                >
                  <div class="card_bg">
                    <van-image
                      width="100%"
                      height="auto"
                      fit="fill"
                      :show-loading="false"
                      :src="bannerData[store.curTabActive.children].left_bg"
                    />
                  </div>
                </div>
                <div
                  class="activity_card_right"
                  @click="cardBannerRightCilik(store.curTabActive.children)"
                >
                  <div class="right_cover_img">
                    <van-image
                      width="100%"
                      height="auto"
                      fit="fill"
                      :show-loading="false"
                      :src="bannerData[store.curTabActive.children].right_bg"
                    />
                  </div>
                </div>
              </div>

              <div class="game_children_tab">
                <div class="game_children_tab_bg">
                  <van-image
                    v-show="store.curTabActive.children == 'hw3'"
                    width="100%"
                    height="100%"
                    :show-loading="false"
                    :src="gameChildrenTabBgList.hw3.bg"
                    block
                  />
                  <van-image
                    v-show="store.curTabActive.children == 'bird'"
                    width="100%"
                    height="100%"
                    :show-loading="false"
                    :src="gameChildrenTabBgList.bird.bg"
                    block
                  />
                </div>
                <div class="game_children_tab_content">
                  <div
                    class="game_children_tab_item"
                    @click="curChildrenTypeChange('hw3')"
                  ></div>
                  <div
                    class="game_children_tab_item"
                    @click="curChildrenTypeChange('bird')"
                  ></div>
                </div>
              </div>
              <div class="list_wrapper">
                <div
                  class="game_list_item"
                  v-for="(gameItemCard, gameIndex) in store.gameDataList[
                    store.curTabActive.children
                  ]"
                  :key="'gamekey' + gameItemCard.product_id"
                  v-show="gameItemCard.group == '' || !gameItemCard.group"
                  @click="goToGameView($event, gameItemCard)"
                >
                  <div class="game_name">
                    {{
                      findGameInfoData(
                        gameItemCard.child_name || gameItemCard.product_name
                      ).game_name +
                      " " +
                      gameItemCard.machine_index
                    }}
                    房
                  </div>
                  <div class="game_cover">
                    <van-image
                      width="100%"
                      height="auto"
                      :show-loading="false"
                      :src="
                        findGameInfoData(
                          gameItemCard.child_name || gameItemCard.product_name
                        ).cover
                      "
                      block
                    />

                    <div
                      :class="[
                        'level_room',
                        gameItemCard.level == '1' ? 'level_room_no' : '',
                      ]"
                    >
                      {{
                        gameItemCard.level == "1"
                          ? "不限等级"
                          : `${
                              gradLevelIcon[Number(gameItemCard.level)]
                                .level_text
                            }`
                      }}
                    </div>
                  </div>
                  <div class="game_card_footer">
                    <div class="playing_number">
                      <span class="maintenance" v-if="gameItemCard.status == 0"
                        >维护中</span
                      >
                      <span
                        class="playing"
                        v-else-if="gameItemCard.occupy.length > 0"
                        >{{ gameItemCard.occupy.length }}热玩中</span
                      >

                      <span class="playnull" v-else>空闲</span>
                    </div>

                    <div class="exchange_number_wrapper">
                      <span class="exchange_number">
                        {{ gameItemCard.exchange }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- 夺宝 -->
            <template v-else-if="tabItem.productName == 'duobao'">
              <div class="duobao_room_list">
                <div
                  class="duobao_room_item"
                  v-for="duobaoItem in store.duobaoListData"
                >
                  <div class="duobao_room_item_title">秘境夺宝</div>

                  <div class="duobao_room_item_cover">
                    <div
                      :class="[
                        'level_room',
                        Number(duobaoItem.level) == 1 ? 'level_room_no' : '',
                      ]"
                    >
                      {{
                        Number(duobaoItem.level) == 1
                          ? "不限等级"
                          : `${
                              gradLevelIcon[Number(duobaoItem.level)].level_text
                            }`
                      }}
                    </div>

                    <div class="exchange_number_wrapper">
                      <span class="exchange_number">
                        {{ duobaoItem.exchange }}
                      </span>
                    </div>
                  </div>
                  <div class="duobao_room_item_seats">
                    <div
                      class="duobao_room_item_seat_item"
                      v-for="(machineItem, machineIndex) in duobaoItem.machines"
                      :key="'duobao' + machineItem.machine_index"
                    >
                      <div
                        class="duobao_room_item_seat_btn_ing"
                        v-if="machineItem.status == 1 && machineItem.occupy[0]"
                        @click="goToGameView($event, machineItem)"
                      >
                        <div class="head_box">
                          <HeadPortrait
                            width="100%"
                            height="100%"
                            style="margin: 0 auto"
                            :ae_id="machineItem.occupy[0].select_title || 0"
                            ae
                            fe
                            :level="machineItem.occupy[0].recharge_level || 1"
                            :src="machineItem.occupy[0].head"
                          />
                        </div>

                        <div class="_index">
                          <span>{{ machineItem.machine_index }}号热玩中</span>
                        </div>
                      </div>

                      <div
                        class="duobao_room_item_seat_btn_pause"
                        v-else-if="machineItem.status == 0"
                        @click="goToGameView($event, machineItem)"
                      >
                        <span>{{ machineItem.machine_index }}号 维护</span>
                      </div>

                      <div
                        class="duobao_room_item_seat_btn_go"
                        v-else
                        @click="goToGameView($event, machineItem)"
                      >
                        <span>{{ machineItem.machine_index }}号 空闲</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- 空中霸主 -->
            <template v-else-if="tabItem.productName == 'kzbz'">
              <div class="kzbz_list">
                <div
                  class="kzbz_room_item"
                  v-for="roomItem in kzbzList"
                  @click="goToKzbzGameView(roomItem.statu)"
                >
                  <div class="room_title">{{ roomItem.game_name }}</div>
                  <div class="room_cover">
                    <div
                      :class="[
                        'level_room',
                        Number(roomItem.level) == 1 ? 'level_room_no' : '',
                      ]"
                    >
                      {{
                        Number(roomItem.level) == 1
                          ? "不限等级"
                          : `${
                              gradLevelIcon[Number(roomItem.level)].level_text
                            }`
                      }}
                    </div>

                    <div class="exchange_number_wrapper">
                      <span class="exchange_number">
                        {{ roomItem.exchange }}
                      </span>
                    </div>

                    <div
                      class="maintenance_mask"
                      v-if="roomItem.statu == 0"
                    ></div>

                    <van-image
                      width="100%"
                      height="auto"
                      fit="fill"
                      :show-loading="false"
                      :src="roomItem.cover"
                    />
                  </div>
                </div>
              </div>
            </template>

            <!-- 其他 -->
            <template v-else>
              <div class="game_banner" v-if="tabItem.bannerShow">
                <div
                  class="activity_card_left"
                  @click="cardBannerLeftCilik(tabItem.productName)"
                >
                  <div class="card_bg">
                    <van-image
                      width="100%"
                      height="auto"
                      fit="fill"
                      :show-loading="false"
                      :src="bannerData[tabItem.productName].left_bg"
                    />
                  </div>
                </div>
                <div
                  class="activity_card_right"
                  @click="cardBannerRightCilik(tabItem.productName)"
                >
                  <div class="right_cover_img">
                    <van-image
                      width="100%"
                      height="auto"
                      fit="fill"
                      :show-loading="false"
                      :src="bannerData[tabItem.productName].right_bg"
                    />
                  </div>
                </div>
              </div>

              <div class="list_wrapper">
                <div
                  class="game_list_item"
                  v-for="(gameItemCard, gameIndex) in store.gameDataList[
                    tabItem.productName
                  ]"
                  :key="tabItem.productName + gameItemCard.machine_index"
                  @click="goToGameView($event, gameItemCard)"
                >
                  <div class="game_name">
                    {{
                      findGameInfoData(
                        gameItemCard.child_name || gameItemCard.product_name
                      ).game_name +
                      " " +
                      gameItemCard.machine_index
                    }}
                    房
                  </div>
                  <div class="game_cover">
                    <van-image
                      width="100%"
                      height="auto"
                      :src="
                        findGameInfoData(
                          gameItemCard.child_name || gameItemCard.product_name
                        ).cover
                      "
                      block
                      :show-loading="false"
                    />

                    <div
                      :class="[
                        'level_room',
                        gameItemCard.level == '1' ? 'level_room_no' : '',
                      ]"
                    >
                      {{
                        gameItemCard.level == "1"
                          ? "不限等级"
                          : `${
                              gradLevelIcon[Number(gameItemCard.level)]
                                .level_text
                            }`
                      }}
                    </div>
                  </div>
                  <div class="game_card_footer">
                    <div class="playing_number">
                      <span class="maintenance" v-if="gameItemCard.status == 0"
                        >维护中</span
                      >
                      <span
                        class="playing"
                        v-else-if="gameItemCard.occupy.length > 0"
                        >{{ gameItemCard.occupy.length }}热玩中</span
                      >
                      <span class="playnull" v-else>空闲</span>
                    </div>

                    <div class="exchange_number_wrapper">
                      <span class="exchange_number">
                        {{ gameItemCard.exchange }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </van-tab>
      </van-tabs>
    </van-config-provider>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  reactive,
} from "vue";

import findGameInfoData from "@/utils/gameInfoMap";
import { useStore } from "@/store/store";
import { sendInstructions, pollingGameinfo } from "@/api/sendData";
import { getGameStatus, getGameServer } from "@/api";
import { useRouter } from "vue-router";
import { showToast } from "vant";
import { gradLevelIcon } from "@/utils/levelData";
import HeadPortrait from "@/components/HeadPortrait.vue";

import gemshopentrance from "@/assets/images/gemShop/entrance.png";

const store = useStore();
const router = useRouter();

const tbj_mxt =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/tbj_mxt.png";
const tbj_msqy_line =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/tbj_msqy_line.png";
const tbj_msqy =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/tbj_msqy.png";

const bybn_left =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/bybn_left.png";
const bybn_right =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/bybn_right.png";

const collectPupShow = ref(false);

const bg_banner_left_mgc =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/mgc_banner_left.png";
const bg_banner_right_mgc =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/mgc_banner_right.png";

const bg_banner_left_bbl =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/bbl_banner_left.png";
const bg_banner_right_bbl =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/bbl_banner_right.png";

const bg_banner_left_mxt =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/banner_left.png";
const bg_banner_right_mxt =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/banner_right.png";

const bg_banner_left_msqy =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/msqy_banner_left.png";
const bg_banner_right_msqy =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/msqy_banner_right.png";

const bannerData = {
  mgc: {
    left_bg:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/mgc_rank_entrance.png",
    right_bg: gemshopentrance,
  },
  mxt: {
    left_bg:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/mxt_rank_entrance.png",
    right_bg: gemshopentrance,
  },
  msqy: {
    left_bg:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/msqy_rank_entrance.png",
    right_bg: gemshopentrance,
  },
  msqy_line: {
    left_bg:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/msqy_rank_entrance.png",
    right_bg: gemshopentrance,
  },
  bbl: {
    left_bg:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/bbl_rank_entrance.png",
    right_bg: gemshopentrance,
  },
  zww: {
    left_bg: "",
    right_bg: gemshopentrance,
  },
  hw3: {
    left_bg:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/hw3_rank_entrance.png",
    right_bg: gemshopentrance,
  },
  bird: {
    left_bg:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/hw3_rank_entrance.png",
    right_bg: gemshopentrance,
  },
  duobao: {
    left_bg: gemshopentrance,
    right_bg: "",
  },
};

const themeVars = {
  tabsLineHeight: "1.42rem",
};

//#region 空中霸主
const kzbzList = reactive([
  {
    game_name: "空中霸主之雷震子",
    level: 1,
    cover: store.imgBaseUrl + "/game/kzbz_lzz_room_cover.png",
    exchange: 20,
    statu: 1,
  },
]);

const goToKzbzGameView = async (statu) => {
  if (statu == 0) {
    showToast({
      type: "fail",
      message: "机器维护中",
    });
    return;
  }

  const res = await getGameServer();

  // console.log("res", res.data.wid);

  sendInstructions({
    msg_type: "jump_to_game",
    game_name: res.data.wid,
    table_id: "",
  });
};

//#endregion

//#region 游戏名 tab
// 父级游戏名
const gameNameTabList = reactive([
  {
    title: "推币机",
    productName: "msqy",
    bannerShow: true,
    new: false,
  },
  {
    title: "会员推币",
    productName: "vip_tbj",
    bannerShow: false,
    new: false,
  },
  // {
  //   title: "空中霸主",
  //   productName: "kzbz",
  //   bannerShow: false,
  //   new: true,
  // },
  {
    title: "魔鬼城",
    productName: "mgc",
    bannerShow: true,
    new: false,
  },
  {
    title: "秘境夺宝",
    productName: "duobao",
    bannerShow: false,
    new: false,
  },
  {
    title: "海王/捕鸟",
    productName: "hw3",
    bannerShow: true,
    new: false,
  },
  {
    title: "爆爆乐",
    productName: "bbl",
    bannerShow: true,
    new: false,
  },
  {
    title: "娱乐街机",
    productName: "other",
    bannerShow: false,
    new: false,
  },
  {
    title: "抓娃娃",
    productName: "zww",
    bannerShow: false,
    new: false,
  },
]);

const handleTabChange = async (gName) => {
  if (gName == "kzbz") {
    console.log("空中霸主");

    pollingGameinfo({
      game_name: "none",
      statu: "close",
    });

    const status = await getGameStatus();

    console.log("status", status);

    const a = status.data.filter((item) => {
      return item.display == 0 || item.display == "0";
    });

    if (a.length == 0) {
      kzbzList.forEach((ii) => {
        ii.statu = 0;
      });
    }

    return;
  }

  if (gName == "msqy" && store.curTabActive.children == "msqy_line") {
    pollingGameinfo({
      game_name: "msqy_line",
      statu: "start",
      instant: true,
    });
    return;
  }

  if (gName == "hw3" && store.curTabActive.children != "hw3") {
    store.$patch((state) => {
      state.curTabActive.children = "hw3";
    });
  }

  if (gName == "msqy" && store.curTabActive.children != "msqy") {
    store.$patch((state) => {
      state.curTabActive.children = "msqy";
    });
  }

  pollingGameinfo({
    game_name: gName,
    statu: "start",
    instant: true,
  });
};

//#endregion

//#region 推币机的类型改变

const gameChildrenTabBgList = {
  mxt: {
    bg: tbj_mxt,
    title: "超级马戏团",
  },
  msqy_line: {
    title: "魔术连线",
    bg: tbj_msqy_line,
  },
  msqy: {
    title: "魔术奇缘",
    bg: tbj_msqy,
  },
  hw3: {
    title: "海王III",
    bg: bybn_left,
  },
  bird: {
    title: "捕鸟系列",
    bg: bybn_right,
  },
};

const curChildrenTypeChange = (type) => {
  store.$patch((state) => {
    state.curTabActive.children = type;
  });

  pollingGameinfo({
    game_name: type,
    statu: "start",
    instant: true,
  });
};

//#endregion

const goToGameView = (evt, gameData) => {
  evt.preventDefault();

  if (gameData.status == 0 && !store.userInfo.is_gm) {
    showToast({
      type: "fail",
      message: "机器维护中",
    });
    return;
  }

  if (
    Number(gameData.level) > Number(store.userInfo.recharge_level) &&
    !store.userInfo.is_gm
  ) {
    showToast({
      type: "fail",
      message: "等级不足",
    });
    return;
  }

  // 秘境夺宝

  if (gameData.product_name === "duobao") {
    // 已占位
    if (gameData.occupy.length > 0) {
      showToast({
        type: "fail",
        message: "暂不支持观战",
      });
      return;
    }

    // 币量不足
    if (store.userInfo.gold < gameData.site_limit) {
      showToast({
        type: "fail",
        message: "币量不足",
        forbidClick: true,
        onClose() {
          store.payPupToggle(true);
        },
      });

      return;
    }

    sendInstructions({
      msg_type: "jump_to_game",
      game_name: "duobao_server7600",
      table_id: gameData.machine_index,
    });

    return;
  }

  store.$patch((state) => {
    state.current_machine = gameData;
  });

  if (!store.gameIsWatch) {
    store.$patch((state) => {
      state.gameIsWatch = true;
    });
  }

  if (store.settlementResult.type == "normal") {
    store.gameIsWatch && (store.gameIsWatch = true);
  } else {
    store.gameIsWatch && (store.gameIsWatch = false);
  }

  let _router_name = gameData.product_name;

  // 魔术连线
  if (_router_name == "msqy" && gameData.group == "msqy_line") {
    _router_name = "msqy_line";
  }

  // 魔术奇缘
  if (_router_name == "msqy" && gameData.group == "") {
    _router_name = "mxt";
  }

  // 会员推币
  // const vip_tbj_p
  if (gameData.group == "vip_tbj") {
    _router_name = "mxt";
  }

  // 娱乐街机 部分界面拥有独立页面
  if (_router_name === "other") {
    const independentArr = ["ttq", "xycs"];
    independentArr.includes(gameData.child_name) &&
      (_router_name = gameData.child_name);
  }

  router.replace({
    name: _router_name,
  });

  // router.push({
  //   name: _router_name,
  // });
};

const cardBannerLeftCilik = (key) => {
  let _key = key;

  if (key == "msqy_line") {
    _key = "msqy";
  }

  if (key == "bird") {
    _key = "hw3";
  }

  store.$patch((state) => {
    state.current_machine.product_name = _key;
  });

  store.storeShowToggle("dayRankShow", true);
};

const cardBannerRightCilik = (key) => {
  store.storeShowToggle("gemShopShow", true);
};

const handleTabClick = (event) => {};

onBeforeMount(() => {});

onMounted(() => {
  if (
    store.curTabActive.parent == "msqy" &&
    store.curTabActive.children == "msqy_line"
  ) {
    pollingGameinfo({
      game_name: "msqy_line",
      statu: "start",
      instant: true,
    });
  } else {
    pollingGameinfo({
      game_name: store.curTabActive.parent,
      statu: "start",
      instant: true,
    });
  }
});

onBeforeUnmount(() => {
  pollingGameinfo({
    game_name: "none",
    statu: "close",
  });
});
</script>

<style lang="scss" scoped>
.game_list_wrapper {
  border: 2px solid #9544e2;
  border-radius: 8px;
  min-height: 200px;
  overflow: hidden;

  .tab_tltle_custom {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 60px;
    min-height: 24px;
    line-height: 24px;
    text-align: center;
    .title_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 24px;
      transform: translate(-50%, -50%);
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/duobao_title.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 1;
    }

    .new_icon {
      position: absolute;
      top: -20px;
      left: -10px;
      width: 35px;
      height: 35px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/new_game_icon.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 0 0;
      z-index: 3;

      animation-name: newSequenceframe;
      animation-duration: 1s;
      animation-timing-function: step-start;
      animation-iteration-count: infinite;
    }
  }

  .game_tab_content {
    //  display: flex;
    .game_children_tab {
      position: relative;
      height: 40px;
      background-color: rgba($color: #000000, $alpha: 0.4);
      // display: flex;

      .game_children_tab_bg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .game_children_tab_content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        .game_children_tab_item {
          flex: 1;
        }
      }
    }

    .game_banner {
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.4);
      padding: 10px 10px 0 10px;
      display: flex;
      justify-content: space-between;

      .activity_card_left {
        width: 65%;
        // width: 212px;
        // height: 60px;
        // border: 2px solid #9559fe;
        border-radius: 6px;
        overflow: hidden;
        position: relative;

        .card_bg {
          width: 100%;
          height: 100%;
        }
      }

      .activity_card_right {
        position: relative;
        width: 33%;
        // width: 108px;
        // height: 60px;
        // border: 2px solid #9559fe;
        border-radius: 6px;

        .right_cover_img {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  .list_wrapper {
    padding: 10px 10px 14px 10px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .game_list_item {
      width: calc(50% - 7px);
      margin-top: 4px;
      overflow: hidden;
      padding: 6px 12px 13px 12px;

      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gamg_card_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .game_name {
        font-size: 14px;
        text-align: center;
        padding: 4px 0;
      }
      .game_cover {
        width: 100%;
        height: auto;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        .level_room {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 10px;
          text-align: center;
          padding: 4px 8px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_card_level.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          color: #fcd344;
        }
        .level_room_no {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_card_level_no.png") !important;
          color: #004661 !important;
        }
      }

      .game_card_footer {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        .playing_number {
          font-size: 12px;
          flex: 1;
          padding: 4px 0;
          text-align: center;
          background-color: #3a1b67;
          border-radius: 10px;
          margin-right: 8px;
          position: relative;
          .playing {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #fcda3a;
            flex-wrap: nowrap;
            width: 100%;
          }
          .playnull {
            color: #4ef4e3;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            flex-wrap: nowrap;
            width: 100%;
          }
          .maintenance {
            color: #f44e4e;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            flex-wrap: nowrap;
            width: 100%;
          }
        }

        .exchange_number_wrapper {
          padding: 2px 6px 2px 24px;
          text-align: center;
          background-color: #3a1b67;
          border-radius: 10px;
          font-size: 14px;
          position: relative;
          &::before {
            content: "";
            height: 28px;
            width: 28px;
            position: absolute;
            left: -6px;
            top: 0px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .msqy_line_list {
    // padding-top: 16px;
    padding: 0 8px 14px 8px;
    overflow: hidden;

    .msqy_line_list_item {
      margin-top: 10px;
      width: 100%;
      height: 180px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/game_big_block.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 12px;
      .msqy_line_list_item_title {
        height: 34px;
        text-align: center;
        line-height: 34px;

        font-size: 14px;
      }

      .msqy_line_list_item_cover {
        position: relative;
        width: 100%;
        height: 90px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/msqy_line_cover.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .level_room {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 10px;
          text-align: center;
          padding: 4px 8px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_card_level.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          color: #fcd344;
        }
        .level_room_no {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_card_level_no.png") !important;
          color: #004661 !important;
        }

        .exchange_number_wrapper {
          // padding: 2px 6px 2px 24px;
          background-color: #150330;
          // border-radius: 10px;
          line-height: 22px;
          font-size: 14px;
          position: absolute;
          right: 5px;
          top: 5px;
          width: 50px;
          height: 22px;
          // text-indent: 22px;
          padding-left: 22px;
          border-radius: 12px;
          // text-align: center;
          // border-bottom-right-radius: 6px;
          // border-top-left-radius: 6px;
          &::before {
            content: "";
            height: 24px;
            width: 24px;
            position: absolute;
            left: -4px;
            top: -2px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        .msqy_line_list_item_cover_center {
          position: absolute;
          left: 50%;
          top: 8px;
          transform: translateX(-50%);
          width: 182px;
          height: 72px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/msqy_cover_center.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;

          padding-top: 28px;
          padding-left: 20px;
          padding-right: 20px;

          .msqy_line_list_item_cover_center_block {
            display: flex;
            justify-content: space-around;
            .line_reward_data_item {
              text-align: center;
              height: 32px;
              width: 26px;

              .line_reward_data_item_name {
                font-weight: bold;
              }

              .line_reward_data_item_value {
                position: relative;
                top: -3px;
                font-size: 11px;
                text-shadow: 1px 1px 0px #0b0004;
              }
            }
          }

          .lighting_1 {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/light_1.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-size: 100% auto;
            animation-name: lighting;
            animation-duration: 0.5s;
            animation-timing-function: linear;
            animation-delay: 0;
            animation-iteration-count: infinite;
            animation-direction: alternate-reverse;
          }

          .lighting_2 {
            @extend .lighting_1;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/light_2.png");
            animation-direction: alternate;
          }
        }
      }

      .msqy_line_list_item_seats {
        height: 46px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .msqy_line_list_item_seat_item {
          width: 96px;
          height: 30px;
          background-color: #3a1b67;
          line-height: 30px;
          text-align: center;
          border-radius: 16px;
          overflow: hidden;

          .msqy_line_list_item_seat_btn_ing {
            display: flex;
            justify-content: center;
            align-items: center;
            .head_box {
              width: 20px;
              height: 20px;
            }
            ._index {
              padding-left: 6px;
              color: #fcda3a;
            }
          }

          .msqy_line_list_item_seat_btn_pause {
            color: #ff0000;
            font-weight: bold;
          }

          .msqy_line_list_item_seat_btn_go {
            background-color: #00c69f;
          }
        }
      }
    }
  }

  .duobao_room_list {
    padding: 0 8px 14px 8px;
    overflow: hidden;
    .duobao_room_item {
      margin-top: 10px;
      width: 100%;
      height: 180px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/game_big_block.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 12px;
      .duobao_room_item_title {
        height: 34px;
        text-align: center;
        line-height: 34px;

        font-size: 14px;
      }

      .duobao_room_item_cover {
        position: relative;
        width: 100%;
        height: 90px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/duobao_room_big_cover.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .level_room {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 10px;
          text-align: center;
          padding: 4px 8px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_card_level.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          color: #fcd344;
        }
        .level_room_no {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_card_level_no.png") !important;
          color: #004661 !important;
        }

        .exchange_number_wrapper {
          // padding: 2px 6px 2px 24px;
          background-color: #150330;
          // border-radius: 10px;
          line-height: 22px;
          font-size: 14px;
          position: absolute;
          right: 5px;
          bottom: 5px;
          width: 50px;
          height: 22px;
          // text-indent: 22px;
          padding-left: 22px;
          border-radius: 12px;
          // text-align: center;
          // border-bottom-right-radius: 6px;
          // border-top-left-radius: 6px;
          &::before {
            content: "";
            height: 24px;
            width: 24px;
            position: absolute;
            left: -4px;
            top: -2px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }

      .duobao_room_item_seats {
        height: 46px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .duobao_room_item_seat_item {
          width: 96px;
          height: 30px;
          background-color: #3a1b67;
          line-height: 30px;
          text-align: center;
          border-radius: 16px;
          overflow: hidden;

          .duobao_room_item_seat_btn_ing {
            display: flex;
            justify-content: center;
            align-items: center;
            .head_box {
              width: 20px;
              height: 20px;
            }
            ._index {
              padding-left: 6px;
              color: #fcda3a;
            }
          }

          .duobao_room_item_seat_btn_pause {
            color: #ff0000;
            font-weight: bold;
          }

          .duobao_room_item_seat_btn_go {
            background-color: #00c69f;
          }
        }
      }
    }
  }

  .kzbz_list {
    padding: 0 8px 14px 8px;
    .kzbz_room_item {
      margin-top: 10px;
      width: 100%;
      height: 150px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/game_big_block.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 12px;

      .room_title {
        height: 34px;
        text-align: center;
        line-height: 34px;
        font-size: 14px;
      }
      .room_cover {
        position: relative;
        width: 100%;
        height: 90px;
        .level_room {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 10px;
          text-align: center;
          padding: 4px 8px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_card_level.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          color: #fcd344;
          z-index: 2;
        }
        .level_room_no {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_card_level_no.png") !important;
          color: #004661 !important;
          z-index: 3;
        }

        .exchange_number_wrapper {
          // padding: 2px 6px 2px 24px;
          background-color: #150330;
          // border-radius: 10px;
          line-height: 22px;
          font-size: 14px;
          position: absolute;
          right: 5px;
          bottom: 5px;
          width: 50px;
          height: 22px;
          // text-indent: 22px;
          padding-left: 22px;
          border-radius: 12px;
          z-index: 2;
          // text-align: center;
          // border-bottom-right-radius: 6px;
          // border-top-left-radius: 6px;
          &::before {
            content: "";
            height: 24px;
            width: 24px;
            position: absolute;
            left: -4px;
            top: -2px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        .maintenance_mask {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.6);
          z-index: 9;

          &::after {
            content: "维护中";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 92px;
            height: 24px;
            background-color: #15033080;
            transform: translate(-50%, -50%);
            color: #ff0000;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
  }
}

@keyframes lighting {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lighting

/* Safari 与 Chrome */ {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes newSequenceframe {
  0% {
    background-position: 0 0px;
  }
  14.28% {
    background-position: 0 -35px;
  }
  28.56% {
    background-position: 0 -70px;
  }
  42.84% {
    background-position: 0 -105px;
  }
  57.12% {
    background-position: 0 -140px;
  }
  71.4% {
    background-position: 0 -175px;
  }
  85.68% {
    background-position: 0 -210px;
  }
  100% {
    background-position: 0 0px;
  }
}
@keyframes newSequenceframe {
  0% {
    background-position: 0 0px;
  }
  7.14% {
    background-position: 0 -35px;
  }
  14.28% {
    background-position: 0 -70px;
  }
  21.42% {
    background-position: 0 -105px;
  }
  28.56% {
    background-position: 0 -140px;
  }
  35.7% {
    background-position: 0 -175px;
  }
  42.84% {
    background-position: 0 -210px;
  }
  100% {
    background-position: 0 0px;
  }
}
</style>
