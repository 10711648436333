<template>
  <LandscapePopup
    :show="store.longShopShow"
    position="right"
    @open="whenPopOpen"
  >
    <div class="long_shop_container">
      <div class="bg_1" v-show="content_type !== 'mary'"></div>
      <div class="bg_2" v-show="content_type == 'mary'"></div>

      <div
        class="close_icon"
        @click="store.storeShowToggle('longShopShow', false)"
      ></div>

      <div class="player_info_wrapper">
        <div class="player_info">
          <div class="player_head">
            <HeadPortrait
              width="100%"
              height="100%"
              :src="store.userInfo.headimgurl"
              :level="store.userInfo.recharge_level"
              :ae_id="store.userInfo.select_title"
              ae
              fe
            />
          </div>
          <div class="player_name">
            {{ store.userInfo.player_name }}
          </div>
        </div>
        <div class="long_gem">
          <div class="long_gem_num">{{ store.user_gem_info.gem.long }}</div>
        </div>
        <div class="player_level">
          <Level
            level_type="recharge_level"
            :level_value="store.userInfo.recharge_level"
            :height="18"
            style="margin-right: 6px"
            :border="true"
          />

          <Level
            :level_value="store.userInfo.level"
            :height="18"
            :border="true"
          />
        </div>
      </div>

      <div class="mary_type_wrapper" v-show="content_type == 'mary'">
        <div
          class="mary_type_item"
          v-for="(maryV, maryK) in mary_type"
          @click="mary_type_change(maryK)"
        >
          <div class="mary_type_text">{{ maryV.text }}</div>
          <div class="mary_type_lock" v-if="maryV.lock"></div>
          <div
            class="mary_type_active"
            v-show="maryK == current_mary_type"
          ></div>
        </div>
      </div>

      <div class="left_nav_type_wrap">
        <div
          class="nav_item"
          v-for="navI in content_type_list"
          @click="handleNavItemClick(navI.type)"
        >
          <div class="nav_text">
            {{ navI.text }}
          </div>
          <div class="nav_active_item" v-show="content_type == navI.type"></div>
        </div>
      </div>

      <div class="type_content">
        <!-- 抽奖 -->
        <div class="mary_outer_wrapper" v-show="content_type == 'mary'">
          <div class="mary_wrapper">
            <div class="mary_spin_wrapper">
              <div
                class="mary_spin_item_block"
                v-for="spinItem in spinData"
                :style="{
                  left: spinItem.left + 'rem',
                  top: spinItem.top + 'rem',
                }"
              >
                <transition name="van-fade">
                  <div
                    class="normal_start_ing_mask"
                    v-show="
                      current_mary_type == 'normal_mali' &&
                      start_light_statu &&
                      !(
                        winIndexs.includes(spinItem.item_index) ||
                        active_block_index == spinItem.item_index
                      )
                    "
                  ></div>
                </transition>
                <transition name="van-fade">
                  <div
                    class="super_start_ing_mask"
                    v-show="
                      current_mary_type == 'super_mali' &&
                      start_light_statu &&
                      !(
                        winIndexs.includes(spinItem.item_index) ||
                        active_block_index == spinItem.item_index
                      )
                    "
                  ></div>
                </transition>

                <div
                  class="normal_item_bg"
                  v-show="
                    current_mary_type == 'normal_mali' &&
                    !(
                      winIndexs.includes(spinItem.item_index) ||
                      active_block_index == spinItem.item_index
                    )
                  "
                ></div>
                <div
                  class="super_item_bg"
                  v-show="
                    current_mary_type == 'super_mali' &&
                    !(
                      winIndexs.includes(spinItem.item_index) ||
                      active_block_index == spinItem.item_index
                    )
                  "
                ></div>
                <div class="light_item_bg" v-show="spinItem.type == 'light'">
                  <van-image
                    width="100%"
                    height="100%"
                    fit="fill"
                    :src="light_item_bg[spinItem.item_index]"
                    :show-loading="false"
                  />
                </div>
                <div
                  class="active_item_bg"
                  v-show="
                    winIndexs.includes(spinItem.item_index) ||
                    active_block_index == spinItem.item_index
                  "
                ></div>

                <div class="item_cover_img" v-if="spinItem.type != 'light'">
                  <!-- <div
                    class="cover_bg"
                    v-show="
                      !(
                        winIndexs.includes(spinItem.item_index) ||
                        active_block_index == spinItem.item_index
                      )
                    "
                  ></div> -->
                  <div class="cover_img">
                    <van-image
                      width="100%"
                      height="100%"
                      fit="fill"
                      :src="spinItem.img_url"
                      :show-loading="false"
                    />
                  </div>
                </div>

                <div class="item_reward_name" v-if="spinItem.type != 'light'">
                  {{ spinItem.name }}
                </div>

                <div class="item_reward_num" v-if="spinItem.type != 'light'">
                  {{ spinItem.num }}
                </div>
              </div>
            </div>
            <div class="mary_content_wrap">
              <div class="shop_tiem">活动倒计时：0天0时59分</div>

              <div class="start_btn" @click="startMary">
                <div
                  class="start_btn_img"
                  :style="{
                    opacity: mary_type[current_mary_type].lock ? 0.6 : 1,
                  }"
                >
                  <van-image
                    width="100%"
                    height="100%"
                    fit="fill"
                    v-show="current_mary_type == 'normal_mali' && !start_statu"
                    :src="start_btn_1"
                    :show-loading="false"
                  />
                  <van-image
                    width="100%"
                    height="100%"
                    fit="fill"
                    v-show="current_mary_type == 'super_mali' && !start_statu"
                    :src="start_btn_2"
                    :show-loading="false"
                  />
                  <van-image
                    width="100%"
                    height="100%"
                    fit="fill"
                    v-show="start_statu"
                    :src="start_btn_3"
                    :show-loading="false"
                  />
                </div>

                <div
                  class="lock_mask"
                  v-show="mary_type[current_mary_type].lock"
                ></div>
              </div>

              <div
                class="consume_long_gem_num"
                v-show="!mary_type[current_mary_type].lock"
              >
                <div class="num">
                  当前拥有：
                  {{ store.user_gem_info.gem.long }}
                </div>
                <div class="gem_icon"></div>
              </div>

              <div class="lock_text" v-show="mary_type[current_mary_type].lock">
                {{ mary_type[current_mary_type].lock_text }}及以上可解锁
              </div>
            </div>
          </div>
        </div>
        <!-- 任务 -->
        <div class="task_list_wrapper" v-show="content_type == 'task'">
          <div class="task_list">
            <div
              class="task_item"
              v-for="taskItem in task_data_list"
              :key="'t_k_' + taskItem.t_index"
            >
              <div class="task_info">
                <div class="task_name">{{ taskItem.t_name }}</div>
                <div class="task_progress_wrap">
                  <div class="task_progress">
                    <van-progress
                      :percentage="taskItem.progress"
                      stroke-width="4"
                      :show-pivot="false"
                      color="#F97920"
                      track-color="#D7C3AD"
                    />
                  </div>
                  <div class="task_progress_num">
                    {{ `${taskItem.num}/${taskItem.complete}` }}
                  </div>
                </div>
              </div>

              <div class="task_reward">
                <div class="reward_num">{{ taskItem.reward }}</div>
              </div>

              <div
                class="task_get_btn"
                :style="{
                  opacity: taskItem.statu == 1 ? 1 : 0.5,
                }"
                @click="handleLongTaskDone(taskItem)"
              >
                {{ taskItem.statu == 2 ? "已领" : "领取" }}
              </div>
            </div>
          </div>
        </div>
        <!-- 龙珠榜 -->
        <div class="long_rank_wrapper" v-show="content_type == 'rank'">
          <div class="long_rank_title_tr">
            <div class="rank_num">排名</div>
            <div class="user_info">玩家</div>
            <div class="user_score">消耗龙珠</div>
            <div class="rank_reward">奖励</div>
          </div>
          <div class="long_rank">
            <div
              class="long_rank_item"
              v-for="(rankItem, rankIndex) in long_rank_list"
            >
              <div class="rank_num">{{ rankIndex + 1 }}</div>
              <div class="user_info">
                <div class="user_head">
                  <HeadPortrait
                    :src="rankItem.head"
                    ae
                    fe
                    :ae_id="rankItem.select_title"
                    :level="rankItem.recharge_level"
                  />
                </div>
                <div class="info_wrap">
                  <div class="user_name">
                    {{ rankItem.player_name }}
                  </div>
                  <div class="user_level">
                    <Level
                      level_type="recharge_level"
                      :level_value="rankItem.recharge_level"
                      style="margin-right: 6px"
                    />

                    <Level :level_value="rankItem.level" />
                  </div>
                </div>
              </div>
              <div class="user_score">
                <div class="score_block">
                  <div class="reward_num">{{ rankItem.score }}</div>
                </div>
              </div>
              <div class="rank_reward">
                <div class="gold_icon"></div>
                <div class="gold_num">{{ rankItem.reward }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 挑战轮 -->
        <div class="tiao_zhan_wrapper" v-show="content_type == 'tz'">
          <div class="tz_task_list_wrapper">
            <div class="tz_lun_num_wrapper">
              <div
                class="lun_num"
                :style="{
                  color:
                    long_tz_lun.current_group_id == 1 ? '#724026' : '#72402660',
                }"
              >
                第一轮
              </div>
              <div
                class="lun_num"
                :style="{
                  color:
                    long_tz_lun.current_group_id == 2 ? '#724026' : '#72402660',
                }"
              >
                第二轮
              </div>
              <div
                class="lun_num"
                :style="{
                  color:
                    long_tz_lun.current_group_id == 3 ? '#724026' : '#72402660',
                }"
              >
                第三轮
              </div>
            </div>
            <div class="tz_list">
              <div
                class="tz_item"
                v-for="tzTaskItem in long_tz_lun.tz_lun_list[
                  long_tz_lun.current_group_id
                ]"
              >
                <div class="tz_info">
                  <div class="tz_name">{{ tzTaskItem.t_name }}</div>
                  <div class="tz_progress_wrap">
                    <div class="tz_progress">
                      <van-progress
                        :percentage="tzTaskItem.progress"
                        stroke-width="4"
                        :show-pivot="false"
                        color="#F97920"
                        track-color="#D7C3AD"
                      />
                    </div>
                    <div class="tz_progress_num">
                      {{ `${tzTaskItem.num}/${tzTaskItem.complete}` }}
                    </div>
                  </div>
                </div>

                <div class="tz_reward">
                  <div
                    class="tz_reward_item"
                    v-for="reItem in tzTaskItem.reward"
                  >
                    <div class="reward_cover">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="fill"
                        :src="reItem.cover"
                        :show-loading="false"
                      />
                    </div>

                    <div class="reward_num">
                      {{ reItem.num }}
                    </div>
                  </div>
                </div>

                <div
                  class="tz_get_btn"
                  :style="{
                    opacity: tzTaskItem.statu == 1 ? 1 : 0.5,
                  }"
                  @click="handleTzTaskDone(tzTaskItem)"
                >
                  {{ tzTaskItem.statu == 2 ? "已领" : "领取" }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 购买龙珠 -->
        <div class="buy_long_zhu_wrapper" v-show="content_type == 'buy'">
          <div class="pay_text_img"></div>
          <div class="long_gem_cover"></div>

          <div class="buy_info_wrapper">
            <div class="row_block">
              <div class="text_label">数量</div>
              <div class="row_content">
                <div
                  class="sub_count_btn"
                  @click="buy_gem_num_exchange('sub')"
                ></div>
                <div class="stepper_cotent">{{ buy_gem_num }}</div>
                <div
                  class="add_count_btn"
                  @click="buy_gem_num_exchange('add')"
                ></div>
              </div>
            </div>
            <div class="row_block">
              <div class="text_label">价格</div>
              <div class="row_content">
                <div class="price_block">{{ buy_gem_num * 6 }}元</div>
              </div>
            </div>
            <div class="row_block">
              <div class="text_label"></div>
              <div class="row_content">
                <div class="gold_block">
                  <span>额外赠送</span>
                  <span class="gold_num">{{ 80 * buy_gem_num }}</span>
                  <span class="gold_icon"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="buy_btn" @click="handle_buy_gem_event"></div>
        </div>
      </div>
    </div>
    <RewardPup :show="winResultShow" @confirm="reset" mask>
      <div class="reward_block">
        <div class="reward_item" v-for="ritem in spinResult">
          <div class="reward_type">
            <van-image
              width="100%"
              height="100%"
              fit="contain"
              position="center"
              :src="ritem.img_url"
              :show-loading="false"
            />
          </div>
          <div class="reward_num">{{ ritem.num }}</div>
        </div>
      </div>
    </RewardPup>
  </LandscapePopup>
</template>

<script setup>
import LandscapePopup from "@/components/LandscapePopup.vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import RewardPup from "@/components/RewardPup.vue";
import Level from "@/components/Level.vue";
import { useStore } from "@/store/store";
import { reactive, ref, computed, watch } from "vue";
import { decodeBase64String } from "@/utils/utils";
import { asyncSendInstructions, sendInstructions } from "@/api/sendData";
import { useToggle, useRaf } from "@vant/use";
import AeList from "@/utils/playerTitleMap";
import Decimal from "decimal.js";
import { showToast } from "vant";

import start_btn_1 from "@/assets/images/longshop/start_btn_1.png";
import start_btn_2 from "@/assets/images/longshop/start_btn_2.png";
import start_btn_3 from "@/assets/images/longshop/start_btn_3.png";

import light_left from "@/assets/images/longshop/light_left.png";
import light_right from "@/assets/images/longshop/light_right.png";

import long_img from "@/assets/images/gemShop/long_2.png";
import collectClassIDs from "@/utils/collectClassIDs";

import gem_fanbei_card_img from "@/assets/images/gemShop/gem_fanbei_card.png";
import gem_gaobao_card_img from "@/assets/images/gemShop/gem_gaobao_card.png";

import { gradLevelIcon } from "@/utils/levelData";

const store = useStore();

//#region 音乐音效
import { Howl, Howler } from "howler";
import result_succeed_sound from "@/assets/audios/result_succeed_sound.mp3";
import mary_start from "@/assets/audios/mary_start.mp3";
import mary_light from "@/assets/audios/mary_light.mp3";
import mary_light_cc from "@/assets/audios/mary_light_cc.mp3";
import maryBgMusicMp3 from "@/assets/audios/mary_bg_music.mp3";

const resultSucceedSound = new Howl({
  src: [result_succeed_sound],
  autoplay: false,
  loop: false,
});

const maryStartSound = new Howl({
  src: [mary_start],
  autoplay: false,
  loop: false,
});
const maryLightSound = new Howl({
  src: [mary_light],
  autoplay: false,
  loop: false,
});
const maryCcSound = new Howl({
  src: [mary_light_cc],
  autoplay: false,
  loop: false,
});

const bgSound = new Howl({
  src: [maryBgMusicMp3],
  autoplay: false,
  loop: true,
  volume: 1,
  onplayerror() {
    bgSound.once("unlock", function () {
      bgSound.play();
    });
  },
});

watch(
  () => store.longShopShow,
  (newV) => {
    if (newV) {
      bgSound.play();
    } else bgSound.pause();
  }
);

//#endregion

//#region 数据配置
const itemCoverMaps = {
  gold: {
    name: "金币",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
  },
  lottery: {
    name: "彩券",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png",
  },
  jiacheng_card: {
    name: "加成卡",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/jiacheng_card.png",
  },
  tiaozhan_ticket: {
    name: "挑战卡",
    img_url:
      "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/global/tz_card.png",
  },

  vip_exp: {
    name: "vip经验",
    img_url:
      "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/global/vip_exp.png",
  },
  tianti_buff_card: {
    name: "buff卡",
    img_url:
      "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/global/tianti_buff_card.png",
  },

  longzuzhanshi: {
    name: AeList[30].t_name,
    img_url: AeList[30].h_url,
  },
  longzuwangzhe: {
    name: AeList[29].t_name,
    img_url: AeList[29].h_url,
  },
  zhongguoxin: {
    name: AeList[12].t_name,
    img_url: AeList[12].h_url,
  },
  long: {
    name: "龙珠",
    img_url: long_img,
  },
  gem_gaobao_card: {
    name: "宝珠高爆卡",
    img_url: gem_gaobao_card_img,
  },
  gem_fanbei_card: {
    name: "宝珠翻倍卡",
    img_url: gem_fanbei_card_img,
  },
};

const light_item_bg = {
  8: light_right,
  18: light_left,
};

const getConfigData = async () => {
  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_long",
    });

    mary_type.normal_mali.long_num = res.mali_level.normal_mali.long_num;
    mary_type.normal_mali.lock =
      store.userInfo.recharge_level < res.mali_level.normal_mali.level[0];
    mary_type.normal_mali.lock_text =
      gradLevelIcon[res.mali_level.normal_mali.level[0]].level_text;

    mary_type.super_mali.long_num = res.mali_level.super_mali.long_num;
    mary_type.super_mali.lock =
      store.userInfo.recharge_level < res.mali_level.super_mali.level[0];
    mary_type.super_mali.lock_text =
      gradLevelIcon[res.mali_level.super_mali.level[0]].level_text;

    normalData.value = createSpinItemElement(res.normal_jipan);
    superData.value = createSpinItemElement(res.super_jipan);
    initTaskDataList(res.player_long.task);
    initLongLunTaskData(res.player_long.tiaozhan);
  } catch (error) {
    console.log("errrr", error);
  }
};

const userLongGemExchange = (num) => {
  const _n = Number(num);
  if (_n == 0 || isNaN(_n)) return;
  store.$patch((state) => {
    state.user_gem_info.gem.long = state.user_gem_info.gem.long + _n;
  });
};

const whenPopOpen = () => {
  getConfigData();

  sendInstructions(
    {
      msg_type: "get_player_gem_collect",
    },
    1e3
  );
};

//#endregion

//#region 类型切换
const content_type_list = [
  {
    type: "mary",
    text: "龙珠抽奖",
  },
  {
    type: "task",
    text: "福利任务",
  },
  {
    type: "rank",
    text: "龙珠周榜",
  },
  {
    type: "tz",
    text: "挑战奖励",
  },
  {
    type: "buy",
    text: "购买龙珠",
  },
];
const content_type = ref("mary");

const handleNavItemClick = (type) => {
  if (start_statu.value) return;
  content_type.value = type;

  if (type == "rank") {
    initLongRankList();
  }
};

//#endregion

//#region 龙珠抽奖
const mary_type = reactive({
  normal_mali: {
    text: "普通",
    long_num: 1,
    lock: false,
    lock_text: "青铜III",
  },
  super_mali: { text: "高级", long_num: 5, lock: true, lock_text: "白银III" },
});
const current_mary_type = ref("normal_mali");

const active_block_index = ref(0);

const mary_type_change = (type) => {
  if (start_statu.value) return;

  current_mary_type.value = type;
};

// w418
// h230

const normalData = ref([]);
const superData = ref([]);

const spinData = computed(() => {
  if (current_mary_type.value == "normal_mali") return normalData.value;
  return superData.value;
});

const getTargeData = (normal_reward_index) => {
  let _normal_reward_index = Number(normal_reward_index);
  let targeSpin = null;
  let targeIndex = -1;

  const isFind = spinData.value.some((spinItem, spinIndex) => {
    if (spinItem.normal_reward_index == _normal_reward_index) {
      targeSpin = spinItem;
      targeIndex = spinIndex;
      return true;
    } else return false;
  });

  if (isFind) {
    return {
      targeSpin,
      targeIndex,
    };
  } else {
    new Error("No corresponding data !");
    return {
      targeSpin,
      targeIndex,
    };
  }
};

const createSpinItemElement = (data) => {
  const sort = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
  };
  const ElementStyles = [];

  let startX = 0;
  let startY = 0;
  let itemW = 1.44;
  let itemH = 1.173333;

  const spacing = 0.08;

  for (const k_index in data) {
    const item = data[k_index];

    const i = Number(k_index);

    let itemStyle = {
      ...item,
      left: 0,
      top: 0,
      item_index: i,
    };

    if (item.type == "chenghao") {
      itemStyle = {
        ...itemStyle,
        ...itemCoverMaps[item.title_name],
        num: "",
      };
    } else if (item.type != "light") {
      itemStyle = {
        ...itemStyle,
        ...itemCoverMaps[item.type],
      };
    }

    if (item.type == "jiacheng_card" || item.type == "tianti_buff_card") {
      itemStyle.num =
        new Decimal(item.num) > 1
          ? new Decimal(item.num).sub(1).mul(100) + "%"
          : new Decimal(item.num).mul(100) + "%";
    }

    if (item.type == "gem_gaobao_card" || item.type == "gem_fanbei_card") {
      itemStyle.num = `${item.num}倍`;
      item.type == "gem_gaobao_card" && (itemStyle.num = "");
    }

    if (i < 7) {
      itemStyle.left = itemW * i + spacing * i;
      i == 6 && (startX = itemW * i + spacing * i);
    }

    if (i >= 7 && i < 11) {
      itemStyle.top = (i - 6) * itemH + spacing * (i - 6);
      itemStyle.left = startX;
      i >= 9 && (itemStyle.top += 0.58667);
      i == 10 && (startY = itemStyle.top);
    }

    if (i >= 11 && i < 17) {
      itemStyle.left = startX - itemW * (i - 10) - spacing * (i - 10);
      itemStyle.top = startY;
    }

    if (i >= 17) {
      itemStyle.top = startY - itemH * (i - 16) - spacing * (i - 16);

      i >= 18 && (itemStyle.top -= 0.58667);
    }

    // if (k_index > 10) continue;

    // sort.findIndex();

    ElementStyles.push(itemStyle);
  }

  return ElementStyles;
};

const [start_statu, start_statu_toggle] = useToggle(false);
const [start_light_statu, start_light_statu_toggle] = useToggle(false);

const spinResult = reactive([]);
const [winResultShow, winResultShowToggle] = useToggle(false);
const startMary = async () => {
  if (mary_type[current_mary_type.value].lock) {
    showToast({
      type: "fail",
      message: "等级不足",
      teleport: ".long_shop_container",
    });
    return;
  }

  if (
    mary_type[current_mary_type.value].long_num > store.user_gem_info.gem.long
  ) {
    showToast({
      type: "fail",
      message: "龙珠不足",
      teleport: ".long_shop_container",
    });
    return;
  }

  if (start_statu.value) return;

  start_statu_toggle(true);
  start_light_statu_toggle(true);
  try {
    const res = await asyncSendInstructions({
      msg_type: "spin_long_mali",
      type: current_mary_type.value,
    });
    active_block_index.value = 1;
    maryStartSound.play();
    const { info, error } = res;

    if (error != "ok") {
      const errormaps = {
        "level limit": "等级不足",
      };

      showToast({
        type: "fail",
        message: errormaps[error] ?? error ?? "稍后重试",
        teleport: ".long_shop_container",
      });
      return;
    }

    userLongGemExchange(-mary_type[current_mary_type.value].long_num);

    let targeIndex = info.normal_reward_index;

    const laps = 3;

    // 普通灯
    if (targeIndex != 8 && targeIndex != 18) {
      addSpinResult(info.reward_info);
      await runing(targeIndex, laps);
      await flicker(targeIndex);
      winResultShowToggle(true);
      return;
    }

    // 射灯
    await runing(targeIndex, laps);
    maryLightSound.play();

    await flicker(targeIndex);

    if (info.light_info[0]) {
      targeIndex = info.light_info[0].light_reward_index;
      maryCcSound.play();
      await runing(targeIndex, 3, 20, false);
      await flicker(targeIndex);

      addSpinResult(info.light_info[0].reward_info);
    }
    if (info.light_info[1]) {
      targeIndex = info.light_info[1].light_reward_index;
      maryCcSound.play();
      await runing(targeIndex, 3, 20, false);
      await flicker(targeIndex);

      addSpinResult(info.light_info[1].reward_info);
    }
    if (info.light_info[2]) {
      targeIndex = info.light_info[2].light_reward_index;
      maryCcSound.play();
      await runing(targeIndex, 3, 20, false);
      await flicker(targeIndex);

      addSpinResult(info.light_info[2].reward_info);
    }
    if (info.light_info[3]) {
      targeIndex = info.light_info[3].light_reward_index;
      maryCcSound.play();
      await runing(targeIndex, 3, 20, false);
      await flicker(targeIndex);
      addSpinResult(info.light_info[3].reward_info);
    }

    //  await flicker(99);
    resultSucceedSound.play();
    winResultShowToggle(true);
  } catch (error) {
    console.log("errroor", error);

    showToast({
      type: "fail",
      message: "网络超时\n 请稍后重试",
      teleport: ".long_shop_container",
    });

    start_statu_toggle(false);
    start_light_statu_toggle(false);
    getConfigData();
    // sendInstructions({
    //   msg_type: "get_player_gem_collect",
    // });
  }
};

const addSpinResult = (resultData) => {
  const targeSpin = { type: "gold", num: 0, img_url: "" };

  targeSpin.type = resultData.type;
  targeSpin.num = resultData.num;
  targeSpin.img_url = itemCoverMaps[resultData.type].img_url;

  if (
    targeSpin.type == "jiacheng_card" ||
    targeSpin.type == "tianti_buff_card"
  ) {
    targeSpin.num =
      new Decimal(targeSpin.num) > 1
        ? new Decimal(targeSpin.num).sub(1).mul(100) + "%"
        : new Decimal(targeSpin.num).mul(100) + "%";
  }

  if (targeSpin.type == "gem_fanbei_card")
    targeSpin.num = `${itemCoverMaps[targeSpin.type].name}(${targeSpin.num}倍)`;
  else if (targeSpin.type == "gem_gaobao_card")
    targeSpin.num = itemCoverMaps[targeSpin.type].name;

  spinResult.push(targeSpin);
};

const winIndexs = ref([]);
const flicker = (index, light = false) => {
  let step = 100;
  let couu = 0;
  let lightTimer = null;

  if (light) maryCcSound.play();

  return new Promise((resolve, reject) => {
    function handleLight() {
      couu++;
      active_block_index.value = active_block_index.value == index ? -1 : index;
      if (couu == 10) {
        active_block_index.value = index;
        winIndexs.value.push(Number(index));
        clearInterval(lightTimer);
        setTimeout(() => {
          resolve();
        }, 1e3);
      }
    }

    lightTimer = setInterval(handleLight, step);
  });
};

const runing = (targe = 0, _laps = 2, _step = 380, normal = true) => {
  let _curLap = 0;
  let runTimer = null;
  let step = _step;

  return new Promise((resolve, reject) => {
    function handleIncrement() {
      active_block_index.value++;
      active_block_index.value > 20 && (active_block_index.value = 0);
      active_block_index.value == targe && _curLap++;

      if (active_block_index.value == targe && _curLap == _laps) {
        clearTimeout(runTimer);
        !normal && maryLightSound.play();
        if (!normal) {
          resolve();
        } else {
          setTimeout(() => {
            resolve();
          }, 1000);
        }

        return;
      }

      if (_curLap + 1 == _laps && normal) {
        // 最后一圈
        step += 6;
      } else if (step > 20) {
        step = step - 100 <= 20 ? 20 : step - 100;
      }

      clearTimeout(runTimer);
      runTimer = setTimeout(handleIncrement, step);
    }

    runTimer = setTimeout(handleIncrement, step);
  });
};

const reset = async () => {
  winIndexs.value = [];
  start_statu_toggle(false);
  start_light_statu_toggle(false);
  winResultShowToggle(false);

  active_block_index.value = -1;

  // await getMaryNotify();
  spinResult.length > 0 && (spinResult.length = 0);
};

//#endregion

//#region 龙珠任务
const task_data_list = ref([]);

const initTaskDataList = (taskData) => {
  const iniList = [];
  for (const k_index in taskData) {
    const item = taskData[k_index];
    const t_type = item.type;
    const taskItem = {
      t_name: "",
      t_index: k_index,
      progress: 0,
      complete: item.info[0].complete ?? 0,
      num: item.info[0].num ?? 0,
      statu: 0,
      reward: item.reward,
    };

    if (t_type == "login") {
      taskItem.t_name = `每日登录`;

      taskItem.num = 1;
      taskItem.complete = 1;
      taskItem.progress = 100;
    } else if (t_type == "recharge") {
      taskItem.t_name = `今日充值 ${item.info[0].complete} 元`;
      taskItem.num = item.info[0].num ?? 1;
      taskItem.complete = item.info[0].complete ?? 1;

      const _n = new Decimal(taskItem.num);
      const _c = new Decimal(taskItem.complete);

      taskItem.progress = Math.min(100, _n.div(_c).mul(100).toNumber());
    } else if (t_type == "collect_task") {
      const g = {
        mgc: "魔鬼城",
        hw3: "海王3",
        bbl: "爆爆乐",
      };

      const _gm = item.info[0].id.split("_")[0];

      taskItem.t_name = `${g[_gm]}里收集${
        collectClassIDs[_gm][item.info[0].condition.class_id].cardName
      }`;

      taskItem.num = item.info[0].condition.num ?? 1;
      taskItem.complete = item.info[0].condition.complete ?? 1;

      const _n = new Decimal(taskItem.num);
      const _c = new Decimal(taskItem.complete);

      taskItem.progress = Math.min(100, _n.div(_c).mul(100).toNumber());
    }

    taskItem.statu = Number(taskItem.num >= taskItem.complete);
    item.is_get && (taskItem.statu = 2);

    iniList.push(taskItem);
  }

  task_data_list.value = iniList;
};

const handleLongTaskDone = async (t_item) => {
  if (t_item.statu != 1) return;

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_long_task_reward",
      index: t_item.t_index,
    });

    if (res.error == "ok") {
      addSpinResult({
        type: "long",
        num: res.reward,
      });

      winResultShowToggle(true);

      userLongGemExchange(res.reward);
      getConfigData();
    }
  } catch (error) {
    getConfigData();
    showToast({
      type: "fail",
      message: "请稍后重试",
      teleport: ".long_shop_container",
    });
  }
};

//#endregion

//#region 龙珠榜单
const long_rank_list = ref([]);

const initLongRankList = async () => {
  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_long_rank",
      type: "real_time",
    });
    const initList = [];

    const { rank_reward, rank } = res;

    if (rank.length > 10) rank.length = 10;

    rank.forEach((rankI, rankIndex) => {
      const initRankItem = {
        ...rankI,
        player_name: decodeBase64String(rankI.player_name),
        head: rankI.head || rankI.head_frame,
        reward: rank_reward[rankIndex] ?? 0,
      };

      initList.push(initRankItem);
    });

    long_rank_list.value = initList;
  } catch (error) {}
};

// ;

//#endregion

//#region 龙珠挑战任务轮
const long_tz_lun = reactive({
  current_group_id: 1,
  tz_lun_list: {
    1: [],
    2: [],
    3: [],
  },
});

const initLongLunTaskData = (data) => {
  const _data = data;

  const l = {
    1: [],
    2: [],
    3: [],
  };
  const t = {
    1: {
      n: 0,
      c: 0,
    },
    2: {
      n: 0,
      c: 0,
    },
    3: {
      n: 0,
      c: 0,
    },
  };

  for (const l_i in _data) {
    const item = _data[l_i];
    const taskItem = {
      t_name: "",
      t_index: l_i,
      progress: 0,
      complete: 0,
      num: 0,
      statu: 0,
      reward: [],
    };

    item.is_get && (taskItem.statu = 2);

    item.reward.forEach((ii) => {
      taskItem.reward.push({
        ...ii,
        cover: itemCoverMaps[ii.type].img_url,
      });
    });

    if (item.check_group) {
      taskItem.t_name = `完成全轮任务`;
      const _n = new Decimal(t[item.check_group].n);
      const _c = new Decimal(t[item.check_group].c);
      taskItem.progress = Math.min(100, _n.div(_c).mul(100).toNumber());
      l[item.check_group].push(taskItem);

      taskItem.num = t[item.check_group].n;
      taskItem.complete = t[item.check_group].c;

      taskItem.statu = Number(_n.toNumber() > _c.toNumber());
      if (taskItem.progress >= 100) {
        long_tz_lun.current_group_id =
          Number(item.check_group) + 1 > 3 ? 3 : Number(item.check_group) + 1;
        item.is_get && (taskItem.statu = 2);
      }
    } else {
      t[item.group_id].c++;
      item.is_get && t[item.group_id].n++;

      taskItem.t_name = `累计消耗龙珠${item.complete}个`;
      taskItem.complete = item.complete;
      taskItem.num = item.num;
      const _n = new Decimal(taskItem.num);
      const _c = new Decimal(taskItem.complete);

      taskItem.progress = Math.min(100, _n.div(_c).mul(100).toNumber());
      taskItem.statu = Number(_n.toNumber() > _c.toNumber());

      item.is_get && (taskItem.statu = 2);
      l[item.group_id].push(taskItem);
    }
  }

  long_tz_lun.tz_lun_list[1] = l[1];
  long_tz_lun.tz_lun_list[2] = l[2];
  long_tz_lun.tz_lun_list[3] = l[3];
};

const handleTzTaskDone = async (t_item) => {
  if (t_item.statu != 1) return;

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_long_tiaozhan_reward",
      index: t_item.t_index,
    });

    if (res.error == "ok") {
      getConfigData();

      let _gold = 0;
      let _lottery = 0;
      let _long = 0;
      res.reward.forEach((itt) => {
        if (itt.type == "gold") _gold += Number(itt.num);
        if (itt.type == "lottery") _lottery += Number(itt.num);
        if (itt.type == "long") _long += Number(itt.num);

        addSpinResult({
          type: itt.type,
          num: itt.num,
        });
      });
      winResultShowToggle(true);
      store.$patch((state) => {
        _gold > 0 && (state.userInfo.gold = state.userInfo.gold + _gold);
        _lottery > 0 &&
          (state.userInfo.lottery = state.userInfo.lottery + _gold);
        _long > 0 &&
          (state.user_gem_info.gem.long = state.user_gem_info.gem.long + _long);
      });
    }
  } catch (error) {
    getConfigData();
    showToast({
      type: "fail",
      message: "请稍后重试",
      teleport: ".long_shop_container",
    });
  }
};

//#endregion

//#region 购买龙珠
const buy_gem_num = ref(1);

const buy_gem_num_exchange = (type) => {
  if (type == "add") {
    if (buy_gem_num.value + 1 > 100) return;
    buy_gem_num.value = buy_gem_num.value + 1;
    return;
  }

  if (buy_gem_num.value - 1 <= 0) return;
  buy_gem_num.value = buy_gem_num.value - 1;
};

const handle_buy_gem_event = () => {
  console.log("购买");
};

//#endregion
</script>

<style lang="scss" scoped>
.long_shop_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 610px;
  height: 320px;
  .bg_1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/images/longshop/bg_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .bg_2 {
    @extend .bg_1;
    background-image: url("@/assets/images/longshop/bg_2.png");
  }

  .close_icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 36px;
    z-index: 333;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/close_icon_white.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .player_info_wrapper {
    position: absolute;
    left: 274px;
    top: 10px;
    z-index: 2;
    min-width: 230px;
    height: 26px;
    display: flex;

    .player_info {
      display: flex;
      .player_head {
        width: 26px;
        height: 26px;
      }

      .player_name {
        line-height: 26px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .long_gem {
      position: relative;
      width: 54px;
      .long_gem_num {
        position: absolute;
        width: 48px;
        height: 18px;
        border: 1px solid #9a44e7;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 18px;
        text-align: right;
        padding: 0 6px 0 20px;
        font-size: 12px;

        border-radius: 10px;

        &::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          top: -2px;
          left: -2px;
          background-image: url("@/assets/images/gemShop/long_2.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .player_level {
      display: flex;
      align-items: center;
    }
  }

  .mary_type_wrapper {
    position: absolute;
    top: 16px;
    left: 120px;
    // background-color: skyblue;
    width: 140px;
    height: 26px;
    display: flex;
    justify-content: space-between;
    .mary_type_item {
      position: relative;
      width: 66px;
      text-align: center;

      background-image: url("@/assets/images/longshop/mary_type_inactive.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .mary_type_text {
        position: relative;
        width: 100%;
        height: 100%;
        line-height: 22px;
        z-index: 2;
      }

      .mary_type_lock {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;

        background-image: url("https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/global/lock.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 4;
      }

      .mary_type_active {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-image: url("@/assets/images/longshop/mary_type_active.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .left_nav_type_wrap {
    position: absolute;
    left: 6px;
    top: 46px;
    width: 92px;
    height: 270px;
    z-index: 2;
    .nav_item {
      position: relative;

      .nav_text {
        position: relative;
        text-align: center;
        width: 100%;
        height: 46px;
        font-size: 14px;
        line-height: 46px;
        z-index: 2;
      }

      .nav_active_item {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 97px;
        background-image: url("@/assets/images/longshop/nav_item_acitve_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 1;
      }
    }
  }

  .type_content {
    position: absolute;
    left: 122px;
    top: 44px;
    width: 430px;
    height: 266px;
    z-index: 2;

    .task_list_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background-color: silver;
      padding: 12px 24px 0 24px;
      .task_list {
        width: 100%;
        height: 100%;
        overflow: hidden;
        // background-color: skyblue;
        .task_item {
          // height: 42px;
          background-color: #fff2cf;
          border-radius: 6px;
          margin-bottom: 4px;
          padding: 6px 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .task_info {
            width: 150px;
            height: 100%;
            .task_name {
              height: 18px;
              color: #724026;
            }
            .task_progress_wrap {
              width: 100%;
              // height: 12px;
              display: flex;
              .task_progress {
                flex: 1;
                padding-top: 6px;
                // display: flex;
                // align-items: center;
              }

              .task_progress_num {
                margin-left: 5px;
                // line-height: 20px;
                color: #724026;
                font-size: 10px;
              }
            }
          }
          .task_reward {
            position: relative;
            width: 30px;
            height: 30px;

            background-color: #ca3edd;
            box-shadow: inset 0px 0px 5px 4px #bc1ecb;
            border-radius: 6px;
            border: 1px solid #ff50f4;
            background-image: url("@/assets/images/gemShop/long_2.png");
            background-size: 22px 22px;
            background-position: center center;
            background-repeat: no-repeat;

            .reward_num {
              position: absolute;
              right: 2px;
              bottom: 0px;
              font-size: 10px;
              color: #fff;
            }
          }
          .task_get_btn {
            width: 56px;
            height: 20px;
            line-height: 20px;
            color: #724026;
            text-align: center;
            border-radius: 10px;
            background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
          }
        }
      }
    }

    .mary_outer_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background-color: bisque;

      .mary_wrapper {
        position: absolute;
        top: 8px;
        left: 16px;

        width: 408px;
        height: 230px;
        // background-color: pink;

        .mary_spin_wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          // display: flex;
          // justify-content: space-between;
          .mary_spin_item_block {
            position: absolute;
            width: 54px;
            height: 44px;
            left: 0;
            top: 0;

            &:nth-child(8),
            &:nth-child(18) {
              width: 66px;
              height: 66px;
              transform: translateX(-5px);
            }

            .normal_start_ing_mask {
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba($color: #00022e, $alpha: 0.7);
              z-index: 111;
            }

            .super_start_ing_mask {
              @extend .normal_start_ing_mask;
              background-color: rgba($color: #322500, $alpha: 0.7);
            }

            .normal_item_bg {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-image: url("@/assets/images/longshop/normal_item_block.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              z-index: 2;
            }
            .super_item_bg {
              @extend .normal_item_bg;
              background-image: url("@/assets/images/longshop/super_item_block.png");
              z-index: 2;
            }
            .light_item_bg {
              @extend .normal_item_bg;
              background-image: none;
              z-index: 3;
            }

            .active_item_bg {
              position: absolute;
              left: 50%;
              top: 50%;
              width: 123.8%;
              height: 123.8%;
              transform: translate(-50%, -50%);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: 0 0;
              background-image: url("@/assets/images/longshop/super_item_active_block.png");
              z-index: 1;
            }

            .item_cover_img {
              position: absolute;
              top: 2px;
              left: 50%;
              width: 36px;
              height: 36px;
              transform: translate(-50%, 0);
              z-index: 2;

              .cover_bg {
                position: absolute;
                z-index: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url("@/assets/images/longshop/item_cover.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                z-index: 1;
              }
              .cover_img {
                position: absolute;
                z-index: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
              }
            }

            .item_reward_name {
              position: absolute;
              width: 100%;
              bottom: 0;
              left: 0;
              text-align: center;
              white-space: nowrap;
              z-index: 3;
              height: 14px;
              line-height: 14px;
              font-size: 10px;
              color: #fff;
              text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
            }

            .item_reward_num {
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
              text-align: right;
              white-space: nowrap;
              z-index: 3;
              height: 14px;
              line-height: 14px;
              font-size: 12px;
              color: #fff;
              text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
            }
          }
        }

        .mary_content_wrap {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 262px;
          height: 134px;
          z-index: 2;
          .shop_tiem {
            position: absolute;
            left: 0;
            top: 24px;
            width: 100%;
            font-size: 12px;
            text-align: center;
          }

          .start_btn {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 174px;
            height: 62px;
            transform: translate(-50%, -50%);
            .start_btn_img {
              width: 100%;
              height: 100%;
            }

            .lock_mask {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 24px;
              height: 24px;
              background-image: url("https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/global/lock.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }

          .consume_long_gem_num {
            position: absolute;
            left: 0;
            top: 92px;
            width: 100%;
            height: 20px;
            line-height: 20px;
            display: flex;
            justify-content: center;
            .gem_icon {
              width: 20px;
              height: 20px;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-image: url("@/assets/images/gemShop/long_2.png");
              margin-left: 4px;
            }

            .num {
              font-weight: bold;
              .n {
                color: #ff0000;
              }
            }
          }

          .lock_text {
            position: absolute;
            left: 0;
            top: 94px;
            width: 100%;
            height: 20px;
            line-height: 20px;
            color: #fee22a;
            font-weight: bold;
            text-align: center;
          }
        }
      }
    }

    .long_rank_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background-color: pink;
      padding: 10px 24px 0 24px;
      display: flex;
      flex-direction: column;
      .long_rank_title_tr {
        width: 100%;
        height: 28px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        font-weight: bold;
        .rank_num {
          width: 50px;
        }
        .user_info {
          width: 160px;
        }

        .user_score {
          width: 60px;
        }
        .rank_reward {
          width: 60px;
        }
      }
      .long_rank {
        width: 100%;
        flex: 1;
        overflow: auto;
        .long_rank_item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          height: 50px;
          background-color: #e0cc94;
          border-radius: 6px;
          margin-bottom: 6px;
          color: #724026;
          &:nth-child(2n) {
            background-color: #fff2cf;
          }

          .rank_num {
            width: 50px;
            text-align: center;

            line-height: 50px;
            font-weight: bold;
            color: #724026;
          }
          .user_info {
            width: 160px;
            padding-top: 6px;
            display: flex;
            .user_head {
              width: 36px;
              height: 36px;
            }
            .info_wrap {
              flex: 1;
              padding-left: 8px;

              .user_name {
                font-size: 12px;
              }
            }
          }

          .user_score {
            position: relative;
            width: 60px;

            .score_block {
              position: absolute;
              width: 30px;
              height: 30px;

              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

              background-color: #ca3edd;
              box-shadow: inset 0px 0px 5px 4px #bc1ecb;
              border-radius: 6px;
              border: 1px solid #ff50f4;
              background-image: url("@/assets/images/gemShop/long_2.png");
              background-size: 22px 22px;
              background-position: center center;
              background-repeat: no-repeat;

              .reward_num {
                position: absolute;
                right: 2px;
                bottom: 0px;
                font-size: 10px;
                color: #fff;
              }
            }
          }
          .rank_reward {
            width: 60px;
            display: flex;
            align-items: center;
            .gold_icon {
              width: 20px;
              height: 20px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .gold_num {
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .tiao_zhan_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 10px 24px 0 24px;
      display: flex;
      flex-direction: column;

      .tz_task_list_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .tz_lun_num_wrapper {
          padding: 0 20px;
          display: flex;
          height: 27px;
          background-color: #e0cc94;
          border-radius: 6px;
          .lun_num {
            flex: 1;
            text-align: center;
            line-height: 27px;
            font-size: 14px;
            color: #724026;
          }
          margin-bottom: 8px;
        }

        .tz_list {
          width: 100%;
          flex: 1;
          overflow: auto;

          .tz_item {
            // height: 42px;
            background-color: #fff2cf;
            border-radius: 6px;
            margin-bottom: 6px;
            padding: 6px 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tz_info {
              width: 150px;
              height: 100%;
              .tz_name {
                height: 18px;
                color: #724026;
              }
              .tz_progress_wrap {
                width: 100%;
                // height: 12px;
                display: flex;
                .tz_progress {
                  flex: 1;
                  padding-top: 6px;
                  // display: flex;
                  // align-items: center;
                }

                .tz_progress_num {
                  margin-left: 5px;
                  // line-height: 20px;
                  color: #724026;
                  font-size: 10px;
                }
              }
            }
            .tz_reward {
              width: 70px;
              display: flex;
              justify-content: space-between;
              .tz_reward_item {
                position: relative;
                width: 30px;
                height: 30px;
                background-color: #ca3edd;
                box-shadow: inset 0px 0px 5px 4px #bc1ecb;
                border-radius: 6px;
                // margin-right: 10px;
                border: 1px solid #ff50f4;

                .reward_cover {
                  position: absolute;
                  width: 22px;
                  height: 22px;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }

                .reward_num {
                  position: absolute;
                  right: 2px;
                  bottom: 0px;
                  font-size: 10px;
                  color: #fff;
                }
              }
            }
            .tz_get_btn {
              width: 56px;
              height: 20px;
              line-height: 20px;
              color: #724026;
              text-align: center;
              border-radius: 10px;
              background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
            }
          }
        }
      }
    }

    .buy_long_zhu_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // padding: 10px 24px 0 24px;
      // display: flex;
      // flex-direction: column;

      .long_gem_cover {
        position: absolute;
        top: 68px;
        left: 34px;
        width: 138px;
        height: 138px;

        background-image: url("@/assets/images/gemShop/long_2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .buy_info_wrapper {
        position: absolute;
        left: 198px;
        top: 76px;
        width: 190px;
        height: 112px;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .row_block {
          width: 100%;
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          display: flex;
          .text_label {
            width: 40px;
          }

          .row_content {
            flex: 1;
            display: flex;
            justify-content: space-between;

            .stepper_cotent {
              width: 80px;
              background-color: #fff;
              text-align: center;
              color: #724026;
              font-size: 14px;
            }

            .sub_count_btn {
              width: 24px;
              height: 100%;
              background-image: url("@/assets/images/longshop/count_sub.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
            .add_count_btn {
              @extend .sub_count_btn;
              background-image: url("@/assets/images/longshop/count_add.png");
            }

            .price_block {
              width: 100%;
              height: 100%;
              flex: 1;
              text-align: center;
              background-color: #fff;
              color: #724026;
            }

            .gold_block {
              display: flex;
              justify-content: center;

              .gold_num {
                padding-left: 18px;
                padding-right: 6px;
              }
              .gold_icon {
                width: 24px;
                height: 24px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
              }
            }
          }
        }

        // .buy_num_change_wrap{

        // }
      }

      .pay_text_img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 143px;
        height: 54px;
        background-image: url("@/assets/images/longshop/pay_text.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .buy_btn {
        position: absolute;
        width: 174px;
        height: 66px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url("@/assets/images/longshop/pay_btn.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

.reward_block {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .reward_item {
    width: 48px;
    margin: 6px;
    .reward_type {
      width: 48px;
      height: 48px;
      background-color: #9369e1;
      border-radius: 6px;
    }

    .reward_num {
      margin-top: 4px;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      color: #724026;
      font-size: 14px;
      padding: 4px 0;
    }
  }
}

.win_item_block {
  animation-name: win_item_sequence;
  animation-duration: 3s;
  animation-timing-function: step-start;
  // animation-delay: 1s;
  animation-iteration-count: infinite;
}

@keyframes win_item_sequence {
  0% {
    background-position: 0 0;
  }
  6.66% {
    background-position: 0 -42px;
  }
  13.33% {
    background-position: 0 -84px;
  }
  20% {
    background-position: 0 -126px;
  }
  26.66% {
    background-position: 0 -168px;
  }
  33.33% {
    background-position: 0 -210px;
  }
  40% {
    background-position: 0 -252px;
  }
  46.66% {
    background-position: 0 -294px;
  }
  54% {
    background-position: 0 -336px;
  }
  60% {
    background-position: 0 -378px;
  }
  66.66% {
    background-position: 0 -420px;
  }
  73.26% {
    background-position: 0 -462px;
  }
  80% {
    background-position: 0 -504px;
  }
  86.66% {
    background-position: 0 -546px;
  }
  93.3% {
    background-position: 0 -588px;
  }
  100% {
    background-position: 0 -630px;
  }
}

@-webkit-keyframes win_item_sequence {
  0% {
    background-position: 0 0;
  }
  6.66% {
    background-position: 0 -42px;
  }
  13.33% {
    background-position: 0 -84px;
  }
  20% {
    background-position: 0 -126px;
  }
  26.66% {
    background-position: 0 -168px;
  }
  33.33% {
    background-position: 0 -210px;
  }
  40% {
    background-position: 0 -252px;
  }
  46.66% {
    background-position: 0 -294px;
  }
  54% {
    background-position: 0 -336px;
  }
  60% {
    background-position: 0 -378px;
  }
  66.66% {
    background-position: 0 -420px;
  }
  73.26% {
    background-position: 0 -462px;
  }
  80% {
    background-position: 0 -504px;
  }
  86.66% {
    background-position: 0 -546px;
  }
  93.3% {
    background-position: 0 -588px;
  }
  100% {
    background-position: 0 -630px;
  }
}
</style>
