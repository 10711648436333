<template>
    <div class="replyTool">
        <van-popup v-model:show="props.input_tool_show" position="bottom" :style="{ height: handleBoardHeight }"
            duration="0" @close="handleBlur">
            <div class="comment_main">
                <div class="input">
                    <van-field v-model.trim="props.replyItem.content" ref="_input" rows="1"
                        :autosize="{ maxHeight: 40 }" type="textarea"
                        :placeholder="props.replyItem.username ? `回复 ${props.replyItem.username}` : placeholder"
                        @blur="handleBlur" />

                    <!-- <div class="tool_bar">
                        <div :class="[props.replyItem.content ? 'sent_btn_active' : 'sent_btn']" @click="handleSend">发送
                        </div>
                    </div> -->
                </div>

                <div class="tool_bar">
                    <div :class="[props.replyItem.content ? 'sent_btn_active' : 'sent_btn']" @click="handleSend">
                        发送
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script setup>
import { useStore } from '@/store/store';
import { onMounted, ref, nextTick, reactive, watch, computed, onUnmounted } from 'vue';
import { addForumReplies } from '@/api';
import { showToast } from "vant";
import { getCurrentDevice } from '@/utils/utils';

const store = useStore();
const props = defineProps({
    replyItem: {
        type: Object,
        default: {
            reply_id: 0,//（被回复人uid） 文章直接评论则为0
            content: "",//内容
            username: "",
        }
    },
    input_tool_show: {
        type: Boolean,
        default: false
    }
});
const emit = defineEmits(['handle-close-input', 'handle-send-success']);

//自动聚焦
const _input = ref();
const handleShowInput = () => {
    nextTick(() => {
        _input.value.focus();
    });
};

const handleBlur = () => {
    setTimeout(() => {
        emit('handle-close-input', false);
    }, 100);
};

watch(
    () => props.input_tool_show,
    (newV) => {
        if (newV) {
            handleShowInput();
        }
    }
);

const placeholder = '文明上网，理性发言，共建和谐文明交谈环境！';
const handleSend = async () => {
    if (!props.replyItem.content) {
        showToast('评论不能为空');
        nextTick(() => {
            _input.value.focus();
        });
        return;
    }
    try {
        const params = {
            post_id: props.replyItem.post_id,
            user_id: store.userInfo.uid,
            reply_id: props.replyItem.reply_id,
            content: props.replyItem.content
        };
        const res = await addForumReplies(params);
        if (res.code == 200) {
            showToast('评论成功,等待审核');
            emit('handle-send-success', false);
        }

    } catch (error) {
        console.log(error);
    }

};

const handleBoardHeight = computed(() => {
    return getCurrentDevice() == "IOS" ? "2.1rem" : "48%";
});

</script>

<style lang='scss' scoped>
:deep(.van-field__control) {
    height: 48px !important;
}

.replyTool {
    :deep(.van-overlay) {
        background: none !important;
    }

    .comment_main {
        display: flex;
        align-items: center;
        // bottom: 0;
        z-index: 10;

        padding: 10px 0;

        .input {
            flex: 1;
            // height: 30px;
            font-size: 20px;
        }

        .tool_bar {
            display: flex;
            background-color: #fff;
            height: 30px;
            justify-content: end;
            align-items: center;
            padding: 0 15px;

            .sent_btn {
                padding: 5px 10px;
                background-color: #f2f2f2;
                color: #ccc;
                font-size: 12px;
                border-radius: 5px;
            }

            .sent_btn_active {
                @extend .sent_btn;
                background-color: #ee4444;
                color: #fff;
            }
        }
    }
}
</style>