<template>
  <div class="zww_view" @click="golbalClickEvent($event)">
    <GameLoding v-if="!isReady" game_name="zww" />
    <Player ref="_Player" :landscape="false" @ready-change="readyChange" />

    <MenuLc
      ref="menud"
      @menuSoundClick="menuSoundClick"
      @handleExitEvent="handleExitEvent"
      @handle_update="handle_update_player"
    />

    <ServerTime :right="40" :top="80" />
    <UserWallet
      style="position: absolute; top: 20.9895vh; left: 15px; z-index: 2"
    />
    <UserWallet
      style="position: absolute; top: 25.4879vh; left: 15px; z-index: 2"
      type="lottery"
    />

    <!-- <UserGold /> -->
    <GameRoom />

    <!-- 占位者 -->
    <div
      class="occupy_info_wrapper"
      v-if="store.gameIsWatch && store.current_machine.occupy.length > 0"
    >
      <div class="user_portrait">
        <HeadPortrait
          width="100%"
          height="100%"
          :src="
            store.current_machine.occupy[0].head ||
            String(store.current_machine.occupy[0].head_frame)
          "
          :level="Number(store.userInfo.recharge_level)"
          :ae_id="store.userInfo.select_title"
          ae
          fe
        />
      </div>
      <div class="user_name">
        {{ decodeBase64String(store.current_machine.occupy[0].player_name) }}
      </div>
    </div>

    <!-- 坐下-- -->
    <Seats
      v-if="
        store.gameIsWatch && isReady && store.current_machine.occupy.length == 0
      "
    />

    <!-- 操控者 -->
    <TopInfo v-if="!store.gameIsWatch && isReady" />

    <!-- 控制台 -->
    <div class="zww_control" v-if="!store.gameIsWatch">
      <div class="direction_btns_wrapper">
        <div
          v-for="keys in direction_btns"
          :key="keys.btn_type"
          class="direction-keys"
          @touchstart="handleDirectionBtnsEvent($event, keys)"
          @touchend="handleDirectionBtnsEvent($event, keys)"
        >
          <van-image
            style="position: absolute; left: 0; top: 0"
            width="100%"
            height="auto"
            fit="contain"
            position="center"
            :src="keys.deactive"
            v-show="!keys.statu"
          />
          <van-image
            style="position: absolute; left: 0; top: 0"
            width="100%"
            height="auto"
            fit="contain"
            position="center"
            :src="keys.active"
            v-show="keys.statu"
          />
        </div>
      </div>

      <div class="start_btn_wrapper" @click="handle_start_btn_event()">
        <van-image
          width="100%"
          height="auto"
          fit="contain"
          position="center"
          :src="zww_start_btn_active"
          v-show="start_btn_statu"
        />
        <van-image
          width="100%"
          height="auto"
          fit="contain"
          position="center"
          :src="zww_start_btn"
          v-show="!start_btn_statu"
        />
      </div>
    </div>

    <!-- 中奖和未中奖 -->
    <van-overlay :show="resultShow" z-index="20">
      <div class="zww_result">
        <div class="result_succ" v-if="result">
          <van-image
            width="100%"
            height="auto"
            fit="contain"
            position="center"
            src="https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/return_lotter_bg.png"
          />
          <p class="lottery_value">
            {{ store.settlementResult.lottery }}
            <span class="f-18"> 彩券</span>
          </p>
        </div>

        <van-image
          v-else
          width="100%"
          height="auto"
          fit="contain"
          position="center"
          src="https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/zww/zww_fail_result.png"
        />

        <div class="zww_result_btns">
          <div class="exit_room" @click="handleExitEvent">
            退出房间({{ endTimer.current.value.seconds }}s)
          </div>
          <div class="again" @click="againBtnEvent">再来一局</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script setup>
import {
  ref,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  watch,
  computed,
  onMounted,
} from "vue";
import { useStore } from "@/store/store";
import GameLoding from "@/components/GameLoding.vue";
import Player from "@/components/Player.vue";
import MenuLc from "@/components/MenuLc.vue";
import WatchTime from "@/components/WatchTime.vue";
import { useCountDown } from "@vant/use";
import GameRoom from "@/components/GameRoom.vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Seats from "@/components/Seats.vue";
import TopInfo from "@/components/TopInfo.vue";
import UserWallet from "@/components/UserWallet.vue";
import ServerTime from "@/components/ServerTime.vue";
import { sendInstructions, pollingGameinfo } from "@/api/sendData";
import { decodeBase64String } from "@/utils/utils";
import { controlRequest } from "@/api";
import { useRouter } from "vue-router";
import { showToast } from "vant";
const store = useStore();
const router = useRouter();
//#region 播放器
const _Player = ref(null);
const isReady = ref(false);
let Onlookers = false;
const readyChange = (statu) => {
  isReady.value = statu;
  if (statu && !Onlookers) {
    Onlookers = true;
    sendInstructions(
      {
        msg_type: "enter_machine",
        game_name: store.current_machine.product_name,
        machine_index: store.current_machine.machine_index,
      },
      100
    );
  }
};

const handle_update_player = () => {
  _Player.value?.handlePlayUpdata();
};

onMounted(() => {});
//#endregion

//#region 控制台
const zww_start_btn = store.imgBaseUrl + "/zww/zww_start_btn.png";
const zww_start_btn_active = store.imgBaseUrl + "/zww/zww_start_btn_active.png";

const top_btn = store.imgBaseUrl + "/zww/top_btn.png";
const top_btn_active = store.imgBaseUrl + "/zww/top_btn_active.png";
const right_btn = store.imgBaseUrl + "/zww/right_btn.png";
const right_btn_active = store.imgBaseUrl + "/zww/right_btn_active.png";
const bottom_btn = store.imgBaseUrl + "/zww/bottom_btn.png";
const bottom_btn_active = store.imgBaseUrl + "/zww/bottom_btn_active.png";
const left_btn = store.imgBaseUrl + "/zww/left_btn.png";
const left_btn_active = store.imgBaseUrl + "/zww/left_btn_active.png";
// const emits = defineEmits(["btnsound"]);
const direction_btns = reactive([
  {
    btn_type: "top",
    deactive: top_btn,
    active: top_btn_active,
    statu: false,
    channel: 3,
  },
  {
    btn_type: "right",
    statu: false,
    channel: 2,
    deactive: right_btn,
    active: right_btn_active,
  },
  {
    btn_type: "down",
    statu: false,
    channel: 4,
    deactive: bottom_btn,
    active: bottom_btn_active,
  },
  {
    btn_type: "left",
    statu: false,
    channel: 1,
    deactive: left_btn,
    active: left_btn_active,
  },
]);

// 处理方向控制键 新
const handleDirectionBtnsEvent = (evt, btnData) => {
  const evtType = evt.type;
  evt && evt.preventDefault();
  const params = {
    product_id: store.current_machine.product_id,
    device: store.current_device.device_name,
    channel: btnData.channel,
    type: 1,
    uid: parseInt(store.userInfo.uid),
  };

  direction_btns.some((key) => {
    if (key.btn_type === btnData.btn_type) {
      key.statu = evtType === "touchstart" ? true : false;
      return true;
    } else return false;
  });

  if (evtType === "touchstart") {
    params.type = 1;
  }

  if (evtType === "touchend") {
    params.type = 0;
    // emits("btnsound");
  }
  setTimeout(() => {
    controlRequest(params);
  }, 100);
};

// 下抓按钮
const start_btn_statu = ref(false);
const handle_start_btn_event = () => {
  if (start_btn_statu.value) return;
  start_btn_statu.value = true;

  startTimer.reset();
  startTimer.start();

  const params = {
    type: 0,
    device: store.current_device.device_name,
    product_id: store.current_machine.product_id,
    channel: 5,
    type: 2,
    times: 1,
    width: 200,
    interval: 30,
    uid: parseInt(store.userInfo.uid),
  };

  controlRequest(params);
};
//#endregion

//#region 菜单
const menud = ref();
// 声音
const menuSoundClick = (statu) => {
  _Player.value && _Player.value.videoMuted(!statu);
};
// 退出

// 退分/退出观战 协议
const exitGameRequest = (type = "visit") => {
  const backType = type;

  // 退出观战
  if (backType == "visit") {
    const leave_params = {
      msg_type: "leave_machine",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
    };
    sendInstructions(leave_params, 0);
    if (store.channelType == "ios") {
      sendInstructions(leave_params, 50);
      sendInstructions(leave_params, 100);
    }
  }
  // 退分
  if (backType == "exit") {
    // 退分 params
    const params = {
      msg_type: "return_lottery",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
      device_name: store.current_device.device_name,
    };

    // 第一次
    sendInstructions(params, 0);
    // 第二次
    sendInstructions(params, 1000);
    // 第三次
    sendInstructions(params, 1800);
  }
};

// 退出事件
const handleExitEvent = () => {
  // 观战直接退
  if (store.gameIsWatch) {
    isReady.value && exitGameRequest();
    router.replace("/home");
    return;
  }

  store.settlementResult.result_show = false;

  // 结算退出
  exitGameRequest("exit");
  router.replace({
    name: "home",
  });
};

//#endregion

// 抓取结果
const resultShow = ref(false);
const result = ref(false);
const startTimer = useCountDown({
  // 倒计时
  time: 9000,
  onFinish() {
    endTimer.start();
    resultShow.value = true;
  },
  onChange(current) {
    if (current.seconds == 4) {
      showToast({
        type: "loading",
        message: "请稍后...",
        duration: 5000,
        forbidClick: true,
      });
    }
  },
});
const endTimer = useCountDown({
  // 倒计时
  time: 6000,
  onFinish() {
    handleExitEvent();
  },
});

const gameTimer = useCountDown({
  // 倒计时
  time: 30000,
  onFinish() {
    handle_start_btn_event();
  },
});

watch(
  () => store.gameIsWatch,
  (newV) => {
    gameTimer.start();
  }
);

watch(
  () => store.settlementResult.result_show,
  (newV) => {
    if (newV) {
      gameTimer.reset();
      gameTimer.pause();
      startTimer.reset();
      startTimer.pause();
      endTimer.start();
      result.value = true;
      resultShow.value = true;
    }
  }
);

// 再来一局按钮
const againBtnEvent = () => {
  result.value && (result.value = false);

  if (store.settlementResult.result_show) {
    store.settlementResult.result_show = false;
  }

  if (Number(store.userInfo.gold) >= Number(store.current_machine.up_coin)) {
    // 上分
    resultShow.value = false;
    start_btn_statu.value = false;
    endTimer.reset();
    endTimer.pause();
    gameTimer.reset();
    gameTimer.start();
    sendInstructions(
      {
        msg_type: "up_coin",
        machine_index: store.current_machine.machine_index,
        game_name: store.current_machine.product_name,
        device_name: store.current_device.device_name,
        num: 1,
      },
      50
    );
  } else {
    showToast({
      type: "fail",
      message: "币量不足请充值",
    });
  }
};

// 全局点击
const golbalClickEvent = (evt) => {
  evt.stopPropagation && evt.stopPropagation();
  evt.preventDefault && evt.preventDefault();

  menud.value && menud.value.menuOpenStatu && menud.value.menuStatuToggle(evt);
  // taskPupShow.value && taskPupShowChange(false);
};

onMounted(() => {
  setTimeout(() => {
    pollingGameinfo({
      game_name: "zww",
      statu: "start",
    });
  }, 1500);
});

onBeforeUnmount(() => {
  pollingGameinfo({
    game_name: "zww",
    statu: "close",
  });
});
</script>

<style lang="scss" scoped>
.zww_view {
  position: relative;
  width: 100vw;
  height: 100vh;
}

// 抓取结果
.zww_result {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 334px;

  .result_succ {
    position: relative;
    width: 100%;
    .lottery_value {
      width: 100%;
      font-size: 22px;
      position: absolute;
      bottom: 34px;
      text-align: center;
    }
  }

  .zww_result_btns {
    margin-top: 20px;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    .exit_room {
      width: 90px;
      line-height: 30px;
      text-align: center;
      background: linear-gradient(90deg, #766f61 0%, #fff 100%);
      border-radius: 15px;
      margin-right: 10px;
      color: #171f23;
    }
    .again {
      width: 90px;
      color: #171f23;
      line-height: 30px;
      text-align: center;
      background: linear-gradient(90deg, #b798fa 0%, #e6dbff 100%);
      border-radius: 15px;
    }
  }
}

// 控制台
.zww_control {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 195px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/zww/zww_control_bg.png");
  background-size: 100% 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  .direction_btns_wrapper {
    width: 150px;
    height: 150px;
    position: relative;
    .direction-keys {
      position: absolute;
      width: 50px;
      height: 50px;

      &:nth-child(1) {
        left: 50%;
        top: 0;
        transform: translate(-50%, 20%);
      }
      &:nth-child(2) {
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
      &:nth-child(3) {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -20%);
      }
      &:nth-child(4) {
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  .start_btn_wrapper {
    width: 104px;
    height: 95px;
  }
}
</style>
