<template>
  <div
    class="wheel_icon_wrapper"
    :style="{
      left: _left,
      top: _top,
      right: _right,
      bottom: _bottom,
    }"
    @click="store.storeShowToggle('turntable_show', true)"
  >
    <div class="icon_wrap">
      <div
        :class="{
          wheel_small_wrap: true,
          heartbeat_class: store.player_turntable_data.progress == 100,
        }"
      ></div>

      <div class="p_i"></div>
    </div>
    <div class="icon_text"></div>
    <div class="wheel_progress_wraper">
      <div class="progress_value">
        {{ store.player_turntable_data.progress }} %
      </div>
      <div
        class="wheel_progress"
        :style="{
          transform: `translateX(-${
            100 - store.player_turntable_data.progress
          }%)`,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { computed } from "vue";

const store = useStore();
const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },
});

const _top = computed(() => {
  if (props.top == "auto") return "auto";
  const rem = parseInt(document.documentElement.style.fontSize);
  return (props.top / rem).toFixed(5) + "rem";
});

const _right = computed(() => {
  if (props.right == "auto") return "auto";
  const rem = parseInt(document.documentElement.style.fontSize);
  return (props.right / rem).toFixed(5) + "rem";
});
const _bottom = computed(() => {
  if (props.bottom == "auto") return "auto";
  const rem = parseInt(document.documentElement.style.fontSize);
  return (props.bottom / rem).toFixed(5) + "rem";
});

const _left = computed(() => {
  if (props.left == "auto") return "auto";
  const rem = parseInt(document.documentElement.style.fontSize);
  return (props.left / rem).toFixed(5) + "rem";
});
</script>

<style lang="scss" scoped>
.wheel_icon_wrapper {
  position: absolute;
  width: 52px;
  height: 52px;
  // background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/entrance_ico2.png");
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  z-index: 5;

  .icon_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/entrance_icon2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform-origin: center center;

    .wheel_small_wrap {
      position: absolute;
      left: 50%;
      // top: 50%;
      top: 8px;
      width: 32px;
      height: 32px;
      transform: translateX(-50%);
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/wheel_small_1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform-origin: center center;
    }

    .p_i {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 24px;
      height: 24px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/pointer_small_icon.png");
      transform: translate(-50%, -62%);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform-origin: center center;
    }
  }

  .wheel_progress_wraper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px;
    height: 8px;
    width: 36px;
    line-height: 8px;
    font-size: 8px;
    color: #fff;
    border-radius: 6px;
    background-color: #4c3545;
    text-align: center;
    overflow: hidden;
    .progress_value {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      z-index: 3;
    }

    .wheel_progress {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #5dfc5a 0%, #09af03 100%);
      z-index: 1;

      border-radius: 4px;
    }
  }

  .icon_text {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 40px;
    height: 14px;
    transform: translateX(-50%);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/entrance_text2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.heartbeat_class {
  -webkit-animation: rotate-center 3s;
  animation: rotate-center 3s;
  // animation-iteration-count: 1;
  animation-iteration-count: infinite;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: translateX(-50%) rotate(0);
    transform: translateX(-50%) rotate(0);
  }

  50% {
    -webkit-transform: translateX(-50%) rotate(720deg);
    transform: translateX(-50%) rotate(720deg);
  }

  100% {
    -webkit-transform: translateX(-50%) rotate(720deg);
    transform: translateX(-50%) rotate(720deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: translateX(-50%) rotate(0);
    transform: translateX(-50%) rotate(0);
  }
  50% {
    -webkit-transform: translateX(-50%) rotate(720deg);
    transform: translateX(-50%) rotate(720deg);
  }
  100% {
    -webkit-transform: translateX(-50%) rotate(720deg);
    transform: translateX(-50%) rotate(720deg);
  }
}
</style>
