<template>
  <van-overlay :show="store.signShow" z-index="1998">
    <div class="sign_in_box scale_in_center_class">
      <div class="close_icon" @click="siginInBoxShowToggle(false)"></div>
      <div class="sign_type_wrapper">
        <div class="img_w">
          <van-image
            :show-loading="false"
            width="100%"
            height="100%"
            :src="sign_type_1"
            v-show="cur_sign_type == 1"
          />
          <van-image
            :show-loading="false"
            width="100%"
            height="100%"
            :src="sign_type_2"
            v-show="cur_sign_type == 2"
          />
          <div class="type_wrap">
            <div class="type_1" @click="cur_sign_type_toggle(1)"></div>
            <div class="type_2" @click="cur_sign_type_toggle(2)"></div>
          </div>
        </div>
      </div>
      <div class="sign_list" v-show="cur_sign_type == 1">
        <div
          class="sign_item"
          v-for="(signItem, signDay) in store.signInData.sign_list"
          @click="onHandleSignInEvent(signItem.is_sign)"
        >
          <div class="sign_mask" v-if="signItem.is_sign"></div>

          <div
            class="cur_sign_day_block"
            v-if="
              store.signInData.isToday &&
              signDay + 1 == store.signInData.index &&
              store.signInData.index < 7
            "
          ></div>
          <div
            class="cur_sign_day_block2"
            v-if="store.signInData.isToday && store.signInData.index == 7"
          ></div>

          <div class="sign_cover">
            <van-image
              :show-loading="false"
              width="100%"
              height="auto"
              :src="signItem.url"
            />
          </div>
        </div>
      </div>
      <div class="vip_wrapper" v-show="cur_sign_type == 2">
        <div class="level_wrapper">
          <div class="level_progress_outer_wrapper">
            <div class="cur_level">
              <div class="level_info">
                <div class="level_icon">
                  <van-image
                    :show-loading="false"
                    width="100%"
                    height="auto"
                    :src="level_info_data.cur_level_info.iconUrl"
                  />
                </div>

                <span>{{ level_info_data.cur_level_info.level_text }}</span>
              </div>
            </div>
            <div class="progress_wrapper">
              <div class="vip_exp_max">
                {{ level_info_data.cur_exp }}/{{
                  level_info_data.next_level_info.total_recharge
                }}
              </div>
              <div class="progress">
                <van-progress
                  :percentage="level_info_data.percentage"
                  stroke-width="10"
                  :show-pivot="false"
                  color="#F2BC25"
                  track-color="#8C56F4"
                />
              </div>
              <div class="vip_exp_max2">
                还需要{{
                  level_info_data.next_level_info.total_recharge -
                  level_info_data.cur_exp
                }}经验达到{{ level_info_data.next_level_info.level_text }}
              </div>
            </div>

            <div class="next_level">
              <div class="level_info">
                <div class="level_icon">
                  <van-image
                    :show-loading="false"
                    width="100%"
                    height="auto"
                    :src="level_info_data.next_level_info.iconUrl"
                  />
                </div>

                <span>{{ level_info_data.next_level_info.level_text }}</span>
              </div>
            </div>
          </div>

          <div class="r_btn_wrapper">
            <div class="r_btn" @click="goToPay">立即充值</div>
          </div>
        </div>
        <div class="info_wrapper">
          <div
            class="active_block"
            :style="{
              left: 0.9067 + level_info_data.dan * 1.0933 + 'rem',
            }"
          ></div>
          <div class="info_msg"></div>
        </div>
      </div>
    </div>

    <transition name="van-fade">
      <div class="sign_reward_pup" v-if="sign_reward_show">
        <div class="sign_reward_container">
          <div class="sign_reward_list">
            <div class="reward_item" v-for="rItem in sign_reward_data">
              <div class="reward_type_cover">
                <van-image
                  :show-loading="false"
                  width="100%"
                  height="auto"
                  :src="rItem.icon"
                />
              </div>
              <div class="reward_num">{{ rItem.num }}</div>
              <div class="reward_mul">x{{ rItem.mul }}</div>
            </div>
          </div>

          <div class="vip_text">
            当前段位为{{ level_info_data.cur_level_info.level_text }} 所有奖励x
            {{ sign_reward_data[0].mul }}
          </div>

          <div class="get_sign_reward_btn_wrapper">
            <div
              class="get_sign_reward_btn"
              @click="sign_reward_show_toggle(false)"
            >
              领取
            </div>
          </div>
        </div>
      </div>
    </transition>
  </van-overlay>
</template>

<script setup>
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";

import { useStore } from "@/store/store";
const sign_type_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/sign_type_1.png";
const sign_type_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/sign_type_2.png";

const vip_exp_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/vip_exp_icon.png";
const gold_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png";
const icon_card =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/icon_card.png";

import { gradLevelIcon } from "@/utils/levelData";
import { Howl, Howler } from "howler";
const store = useStore();
import { ref, reactive, computed, onBeforeMount, onMounted } from "vue";
import { showToast } from "vant";
import openBoxHint from "@/assets/audios/sign_hint.mp3";

const openBoxSound = new Howl({
  src: [openBoxHint],
  autoplay: false,
  loop: false,
});

//#region 登录签到

const cur_sign_type = ref(1);

const cur_sign_type_toggle = (index) => {
  cur_sign_type.value = index;

  if (index == 2 && store.initPayPupData.base.length == 0) {
    sendInstructions({
      msg_type: "get_player_recharge_status",
    });
  }
};

const siginInBoxShowToggle = (statu = true) => {
  store.$patch((state) => {
    state.signShow = statu;
  });
};

const sign_reward_data = ref([
  {
    type: "gold",
    num: 348,
    mul: 3,
    icon: gold_icon,
  },
  {
    type: "vip_exp",
    num: 3,
    mul: 3,
    icon: vip_exp_icon,
  },
]);

const sign_reward_type_icons = {
  gold: gold_icon,
  vip_exp: vip_exp_icon,
  tiaozhan_ticket: icon_card,
};

const onHandleSignInEvent = async (is_get) => {
  if (
    store.channelType == "ios" &&
    store.signInData.index == 3 &&
    !store.signInData.sign_list[0].is_sign
  ) {
    sendInstructions({
      msg_type: "get_login_reward",
    });
    return;
  }

  if (is_get || !store.signInData.isToday) return;
  // if (is_get) return;

  try {
    const res = await asyncSendInstructions({
      msg_type: "exchange_login_reward",
    });

    // const res = {
    //   error: "ok",
    //   reward_data: [
    //     {
    //       type: "gold",
    //       num: 264,
    //       mul: 3,
    //     },
    //     {
    //       type: "tiaozhan_ticket",
    //       num: 24,
    //       mul: 3,
    //     },
    //   ],
    // };

    let _gold = 0;
    let _vip_exp = 0;
    let _tz_ = 0;

    if (res.error == "ok") {
      const rlist = [];
      res.reward_data.forEach((item) => {
        const _r_i = {
          type: item.type,
          num: item.num / item.mul,
          mul: item.mul,
          icon: sign_reward_type_icons[item.type] ?? vip_exp_icon,
        };

        if (item.type == "gold") {
          _gold += item.num;
        }

        if (item.type == "vip_exp") {
          _vip_exp += item.num;
        }

        if (item.type == "tiaozhan_ticket") {
          _tz_ += item.num;
        }
        rlist.push(_r_i);
      });

      sign_reward_data.value = rlist;
      store.$patch((state) => {
        state.userInfo.gold = state.userInfo.gold + _gold;
        state.userInfo.tiaozhan_ticket = state.userInfo.tiaozhan_ticket + _tz_;

        state.initPayPupData.userPayInfo.vip_exp =
          state.initPayPupData.userPayInfo.vip_exp + _vip_exp;
      });

      sign_reward_show_toggle(true);
    } else {
      const msgss = {
        "time error": "当前不可签到",
      };

      showToast({
        type: "fail",
        message: msgss[res.error] ?? "请稍后重试",
      });
    }
  } catch (error) {
    showToast({
      type: "fail",
      message: " 请稍后重试2",
    });
  }

  //   sendInstructions();
};

const sign_reward_show = ref(false);

const sign_reward_show_toggle = (statu) => {
  sign_reward_show.value = statu;

  if (!statu) {
    siginInBoxShowToggle(false);
    openBoxSound.play();
    sendInstructions(
      {
        msg_type: "get_login_reward",
      },
      100
    );
  }
};

//#endregion

//#region 充值等级
const level_info_data = computed(() => {
  const _index = store.userInfo.recharge_level;
  const cur_level_info = gradLevelIcon[_index];
  const next_level_info = gradLevelIcon[_index + 1];

  const cur_exp =
    store.initPayPupData.userPayInfo.total_recharge * 10 +
    store.initPayPupData.userPayInfo.vip_exp;

  const percentage = parseInt((cur_exp / next_level_info.total_recharge) * 100);

  const dan_list = [
    [1, 3],
    [4, 6],
    [7, 10],
    [11, 14],
    [15, 18],
    [19, 21],
  ];

  const user_l = store.userInfo.recharge_level;
  const dan = dan_list.findIndex((item) => {
    return user_l >= item[0] && user_l <= item[1];
  });

  return {
    cur_level_info,
    next_level_info,
    cur_exp,
    percentage,
    dan: dan < 0 ? 0 : dan,
  };
});

const goToPay = () => {
  siginInBoxShowToggle(false);

  store.payPupToggle(true);
};

onMounted(() => {
  if (store.signInData.index == 3 && !store.signInData.sign_list[0].is_sign) {
    sendInstructions({
      msg_type: "get_login_reward",
    });
    store.channelType == "ios" &&
      sendInstructions(
        {
          msg_type: "get_login_reward",
        },
        500
      );
    return;
  }
});

//#endregion
</script>

<style lang="scss" scoped>
.sign_in_box {
  position: absolute;
  width: 312px;
  height: 480px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 136px 15px 0 15px;

  z-index: 1;
  .close_icon {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 40px);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .sign_type_wrapper {
    position: absolute;
    left: -24px;
    top: 120px;
    width: 38px;
    height: 122px;
    .type_wrap {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      .type_1,
      .type_2 {
        width: 100%;
        height: 50%;
      }
    }

    .img_w {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .sign_list {
    width: 100%;
    height: 322px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .sign_item {
      position: relative;
      width: 90px;
      height: 110px;

      .cur_sign_day_block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //   border-radius: 10px;
        z-index: 33;

        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/cur_sign_day_block.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .cur_sign_day_block2 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //   border-radius: 10px;
        z-index: 33;

        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/cur_sign_day_block2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .sign_mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.4);
        border-radius: 10px;
        z-index: 33;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 38px;
          height: 38px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/signed.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .sign_cover {
        width: 100%;
        height: 100%;
      }

      &:nth-child(n + 4) {
        margin-top: 6px;
      }

      &:last-child {
        width: 100%;
        height: 90px;
      }
    }
  }

  .vip_wrapper {
    width: 100%;
    height: 322px;
    // background-color: salmon;

    .level_wrapper {
      height: 150px;
      background-color: #501abc;
      border-radius: 10px;
      padding: 24px 18px 0 18px;

      .level_progress_outer_wrapper {
        width: 100%;
        height: 72px;

        display: flex;

        .progress_wrapper {
          flex: 1;

          text-align: center;

          .progress {
            padding: 0 10px;
          }

          .vip_exp_max {
            margin-top: 4px;
            font-size: 10px;
            margin-bottom: 8px;
          }
          .vip_exp_max2 {
            margin-top: 10px;
            font-size: 8px;
          }
        }

        .cur_level,
        .next_level {
          position: relative;
          width: 62px;
          height: 72px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/level_wrap.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .level_info {
            position: absolute;
            left: 50%;
            top: 4px;
            width: 36px;
            top: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            .level_icon {
              width: 36px;
              height: auto;
            }
          }
        }
      }

      .r_btn_wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        .r_btn {
          color: #693015;
          font-size: 14px;
          line-height: 28px;
          width: 100px;
          height: 28px;
          background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
          border-radius: 14px;
          text-align: center;
        }
      }
    }
    .info_wrapper {
      position: relative;
      margin-top: 8px;
      height: 155px;
      background-color: #501abc;
      border-radius: 10px;

      .active_block {
        position: absolute;
        top: 0;
        height: 100%;
        width: 36px;
        left: 34px;
        background: linear-gradient(180deg, #e8209a 60%, #a31073 100%);
        opacity: 0.6;
      }

      .info_msg {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 265px;
        height: 130px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/vip_info.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.sign_reward_pup {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  //   top:
  background-color: rgba($color: #000000, $alpha: 0.6);

  .sign_reward_container {
    position: absolute;
    width: 100%;
    // top: 260px;
    height: 400px;

    top: 40%;
    transform: translate(0, -50%);

    .sign_reward_list {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      padding-top: 220px;
      // background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/sign_reward_bg.png");
      // background-size: 100% 100%;
      // background-repeat: no-repeat;

      width: 350px;
      height: 346px;
      // background-color: skyblue;
      // transform: translate(-50%, -50%);

      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/win_pup_bg.png");
      background-size: 100% auto;
      background-repeat: no-repeat;

      display: flex;
      justify-content: center;
      .reward_item {
        position: relative;
        width: 44px;
        height: 44px;
        background-color: #9369e1;
        border-radius: 5px;
        margin: 0 5px;

        .reward_type_cover {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 34px;
          height: 34px;
          z-index: 1;
        }

        .reward_num {
          position: absolute;
          bottom: 0;
          width: 100%;
          transform: translateY(110%);
          font-size: 14px;
          z-index: 2;
          text-align: center;
          font-weight: bold;
          color: #724026;
        }

        .reward_mul {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          z-index: 3;
          background-color: #cd0003;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          font-size: 12px;
          color: #fff;
          font-weight: bold;
        }
      }
    }

    .vip_text {
      position: absolute;
      top: 328px;
      // position: absolute;
      // margin-top: 15px;
      color: #fbf3aa;
      font-size: 10px;
      width: 100%;
      text-align: center;
    }

    .get_sign_reward_btn_wrapper {
      position: absolute;
      bottom: 0;
      // margin-top: 22px;
      width: 100%;
      display: flex;
      justify-content: center;
      .get_sign_reward_btn {
        width: 120px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        color: #693015;
        font-size: 16px;
        background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
        border-radius: 16px;
      }
    }
  }
}

@-webkit-keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}
@keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}
</style>
